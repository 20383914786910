import { getCenterAuthorization, getCenterDetailAuthorization } from "apis/centerCertification";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useEmitter } from "./useEmitter";

export const useCenterAuthorization = () => {
  const emitter = useEmitter();
  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("type") ?? "";
  const value = searchParams.get("value") ?? "";

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["@AuthorizationPost", "getAuthorization", type, value],
    () => getCenterAuthorization()
  );

  useEffect(() => {
    emitter.on("RefetchList", refetch);
    return () => {
      emitter.off("RefetchList");
    };
  }, []);

  const onSearch = (params: any) => {
    const searchQuery = `type=${params.searchTypeCode}&value=${params.searchName}`;
    setSearchParams(searchQuery, { replace: !!value });
  };
  return {
    data,
    loading: isLoading || isFetching,
    refetch: refetch,
    search: {
      isSearched: !!value,
      onSearch,
    },
  };
};

export const useCenterDetail = (id: number) => {
  const { data } = useQuery(["@AuthorizationPosts", "getAuthorizationDetail"], () =>
    getCenterDetailAuthorization(id)
  );

  return {
    data,
  };
};
