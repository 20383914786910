import { Button, ButtonProps } from "@mui/material";

interface Props extends ButtonProps {
  label: string;
}

const FooterButton = ({ label, ...props }: Props) => (
  <Button
    {...props}
    fullWidth
    size="large"
    variant="contained"
    sx={{ maxWidth: 400 }}
  >
    {label}
  </Button>
);

export default FooterButton;
