import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { useCareer } from "apis/career";
import { ICareerOnOff } from "apis/career.type";
import { useEmitter } from "hooks";
import { useNavigate } from "react-router-dom";

import { colors, textStyles } from "styles";

const CareerCard = ({ data }: any) => {
  const navigate = useNavigate();
  const onOpenPreview = () => {
    return window.open(data.targetUrl);
  };

  const NewYMD = new Date(data.sourceDate);

  const emitter = useEmitter();
  const params = { id: data.id };
  const onDeletePost = () => emitter.emit("DeleteCareer", params);

  const onClickUpdate = (id: number) => {
    navigate(`/career/${id}`);
  };
  // 공개여부 클릭 or 취소
  const onClickUse = (data: ICareerOnOff) => {
    useCareer(data);
  };
  return (
    <Card sx={{ borderRadius: "16px" }}>
      <Box mx={3} my={3}>
        <Stack>
          <FormControlLabel
            label="공개 여부"
            control={<Switch size="small" defaultChecked={data.isUse} />}
            onClick={() => onClickUse({ id: data.id, isUse: !data.isUse })}
          />
        </Stack>
        <CardContent
          style={{
            padding: "0px",
            cursor: "pointer",
            marginTop: "10px",
            opacity: data.isUse ? "1" : "0.5",
          }}
          onClick={onOpenPreview}
        >
          <Stack>
            <CardMedia height="204" component="img" image={data.imageUrl} />
          </Stack>
          <Typography display={"block"} color="text.secondary" {...textStyles.web.body3} mt={1.25}>
            {data.sourceName} |{" "}
            {NewYMD.getFullYear() + "." + (NewYMD.getMonth() + 1) + "." + NewYMD.getDate()}
          </Typography>
          <Typography
            flexWrap="nowrap"
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
            mt={1}
            {...textStyles.web.subhead3}
          >
            {/* <StrongTitle /> */}
            {data.title}
          </Typography>
          <Typography
            color={colors.bluegray500}
            flexWrap="nowrap"
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
            mt={1}
            {...textStyles.web.body1}
          >
            {data.description}
          </Typography>
          <Typography
            color={colors.blue500}
            flexWrap="nowrap"
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
            mt={1}
            {...textStyles.web.body2}
          >
            {data.feedTypeName} #{data.hashTag}
          </Typography>
          <Box
            p={0.75}
            borderRadius={1.5}
            justifyContent={"center"}
            border={`solid 1px ${colors.bluegray50}`}
            margin={"10px 0"}
            padding={"6px 24px"}
            display={"flex"}
            gap={"6px"}
          >
            <Typography
              sx={{
                ...textStyles.web.callout2,
                color: colors.bluegray500,
              }}
            >
              조회수
            </Typography>
            <Typography
              sx={{
                ...textStyles.web.callout2,
                color: colors.bluegray500,
              }}
            >
              {data.displayClickCount}
            </Typography>
          </Box>
        </CardContent>
        <Stack direction="row" gap={1} mt={1.25}>
          <Button
            fullWidth
            size="small"
            // variant="contained"
            sx={{ backgroundColor: colors.bluegray50, color: colors.red600 }}
            onClick={onDeletePost}
          >
            삭제
          </Button>
          <Button
            fullWidth
            size="small"
            // variant="contained"
            sx={{
              backgroundColor: colors.bluegray50,
              color: colors.bluegray700,
            }}
            onClick={() => onClickUpdate(data.id)}
          >
            수정
          </Button>
        </Stack>
      </Box>
    </Card>
  );
};

export default CareerCard;
