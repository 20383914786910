import { useEffect, useState } from "react";
import { debounce, filter, find, pick } from "lodash";
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Stack,
  Typography,
} from "@mui/material";

import { textStyles } from "styles";
import { CustomDialog } from "components";
import { useGetWorkshopList } from "hooks";
import { IThemaResponseOf } from "apis/banner.type";
import { IRecruitPostSummary } from "apis/recruitPost.type";
import WorkshopPost from "pages/Workshop/List/WorkshopPost";

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  workshopPosts: IThemaResponseOf[];
  setWorkshopPosts: React.Dispatch<React.SetStateAction<IThemaResponseOf[]>>;
}

const WorkshopListDialog = ({
  isOpen = false,
  onClose,
  workshopPosts,
  setWorkshopPosts,
}: Props) => {
  const [selectedPosts, setSelectedPosts] = useState<IThemaResponseOf[]>([]);
  useEffect(() => {
    setSelectedPosts(workshopPosts);
  }, [isOpen]);

  const { data, loading, fetchNextPage } = useGetWorkshopList();

  const count = data.count;
  const hasNext = data.hasNext;
  const postList = data.posts;

  const fetchMoreObserver = new IntersectionObserver(
    ([{ target, isIntersecting }]) => {
      if (!hasNext) {
        fetchMoreObserver.unobserve(target);
      }
      if (isIntersecting) {
        debounce(fetchNextPage, 300)();
      }
    }
  );

  const onCloseDialog = () => {
    onClose();
    setSelectedPosts([]);
  };

  // 워크샵 카드 클릭 시 선택 여부
  const onClickCheck = (post: IRecruitPostSummary, checked: boolean) => {
    const workshopPost: IThemaResponseOf = {
      ...pick(post, [
        "address",
        "createdAt",
        "detailAddress",
        "due",
        "id",
        "pay",
        "postId",
        "startAt",
        "title",
        "workshopUrl",
      ]),
      imageUrl: post.centerMainImage,
      logoImageUrl: "",
      specialCode: post.specialLectureTypeCode,
      specialCodeName: post.specialLectureTypeCodeName,
      themaTypeCode: "003",
      themaTypeCodeName: "워크샵/특강 캐러셀",
    };

    if (!checked) return setSelectedPosts((posts) => [workshopPost, ...posts]);
    return setSelectedPosts((posts) =>
      filter(posts, ({ postId }) => postId !== Number(post.postId))
    );
  };

  const actionButtons: ButtonProps[] = [
    {
      id: "success",
      fullWidth: true,
      children: "선택 완료",
      onClick: () => {
        setWorkshopPosts(selectedPosts);
        onCloseDialog();
      },
    },
  ];

  return (
    <CustomDialog
      open={isOpen}
      onClose={onCloseDialog}
      isLoading={loading}
      actionButtons={actionButtons}
      PaperProps={{ onScroll: console.log }}
      sx={{
        ".MuiDialogContent-root": {
          p: 2.5,
          pr: 1,
          overflowX: "hidden",
        },
      }}
    >
      <Stack direction={"row"} justifyContent="space-between" mt={1.75}>
        <Typography sx={{ ...textStyles.web.header2 }}>
          게시 중 워크샵/특강 {count?.toLocaleString()}
        </Typography>

        <Typography color={"text.secondary"} sx={{ ...textStyles.web.header2 }}>
          {selectedPosts.length.toLocaleString()}개 선택
        </Typography>
      </Stack>

      <Stack mt={2.5} gap={1.5}>
        {postList?.map((post) => {
          const checked = !!find(selectedPosts, { postId: post.postId });
          return (
            <Button
              fullWidth
              disableRipple
              key={post.id}
              variant="outlined"
              startIcon={<Checkbox readOnly checked={!!checked} />}
              onClick={() => onClickCheck(post, checked)}
              sx={{ textAlign: "left", justifyContent: "flex-start" }}
            >
              <WorkshopPost isShorts key={post.id} post={post} />
            </Button>
          );
        })}

        <Box
          height={0}
          src="/logo192"
          component={"img"}
          onError={(e) => fetchMoreObserver.observe(e.currentTarget)}
          sx={{ visibility: "hidden" }}
        />
      </Stack>
    </CustomDialog>
  );
};

export default WorkshopListDialog;
