import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ButtonProps } from "@mui/material";

import { CustomDialog } from "components";
import { useEmitter, useCustomSnackbar } from "hooks";
import { setReviewRecruitPost } from "apis/recruitPost";

const ReviewPostDialog = () => {
  const emitter = useEmitter();
  const snackbar = useCustomSnackbar();

  const [params, setParams] = useState<{ postId: string } | null>(null);
  const onClose = () => setParams(null);

  useEffect(() => {
    emitter.on("ReviewPost", setParams);
    return () => {
      emitter.off("ReviewPost");
    };
  }, []);

  const { isLoading, mutate } = useMutation(
    ["@posts", "setReviewRecruitPost"],
    setReviewRecruitPost,
    {
      onSuccess: () => {
        setParams(null);
        emitter.emit("RefetchList");
        snackbar({ severity: "success", message: "공고를 게시했어요." });
      },
    }
  );

  const isOpen = Boolean(params?.postId);
  const actionButtons: ButtonProps[] = [
    { id: "cancel", children: "취소", color: "secondary", onClick: onClose },
    {
      id: "review",
      children: "확인",
      onClick: () => params && mutate(params.postId),
    },
  ];

  return (
    <CustomDialog
      isLoading={isLoading}
      title="검토 완료 하시겠어요?"
      open={isOpen}
      onClose={onClose}
      actionButtons={actionButtons}
    />
  );
};

export default ReviewPostDialog;
