type columnsIds = "tel" | "name" | "status" | "etc";

interface ColumnsItems {
  id: columnsIds;
  label: string;
}

export const columns: ColumnsItems[] = [
  {
    id: "tel",
    label: "전화 번호",
  },
  {
    id: "name",
    label: "센터명",
  },
  {
    id: "status",
    label: "상태",
  },
  {
    id: "etc",
    label: "비고",
  },
];

export const requiredColumns: ColumnsItems[] = [columns[0], columns[2]];
