import axios from "axios";

export const apiGateway = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

apiGateway.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("ACCESS_TOKEN");
  if (token) config.headers.Authorization = token;
  return config;
});
