import { useRef } from "react";
import { keys, pick } from "lodash";
import { useNavigate } from "react-router-dom";

import { GROUP_CODE } from "apis/typeCodes";
import {
  Layout,
  PostDialogs,
  PostFormFooterButton,
  PostFormHeader,
} from "components";
import { useEmitter, useGroupCode, usePostDefaultValues } from "hooks";

import { WorkshopFormContent } from "../components";
import { createDefaultValues } from "../type";

interface Props {
  isCreate?: boolean;
}

const WorkshopForm = ({ isCreate }: Props) => {
  const emitter = useEmitter();
  const navigate = useNavigate();
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const groupCode = useGroupCode([GROUP_CODE.SPECIAL_LECTURE]);
  const postDefaultValues = usePostDefaultValues(createDefaultValues);

  const isLoading = groupCode.isLoading || postDefaultValues.isLoading;
  const onSubmit = () => submitButtonRef.current?.click();

  const title = isCreate ? "공고 작성" : "공고 수정";
  const footer = isCreate ? "작성 완료" : "수정 완료";
  const onBack = () => navigate("/workshop");

  const PostForm = () => {
    if (isLoading) return null;

    const defaultValues = pick(
      postDefaultValues.data,
      keys(createDefaultValues)
    );

    const onSuccessSubmit = () =>
      isCreate ? emitter.emit("CreatePost") : navigate(-1);

    return (
      <WorkshopFormContent
        ref={submitButtonRef}
        defaultValues={defaultValues}
        groupCodes={groupCode.data}
        onSuccessSubmit={onSuccessSubmit}
      />
    );
  };

  return (
    <Layout
      isLoading={isLoading}
      footer={<PostFormFooterButton label={footer} onClick={onSubmit} />}
    >
      <PostDialogs.Create onBack={onBack} />
      <PostFormHeader title={title} onBack={onBack} />
      <PostForm />
    </Layout>
  );
};

export default WorkshopForm;
