import { createTheme } from "@mui/material";

import { colors } from "./colors";
import { textStyles } from "./textStyles";

const theme = createTheme({
  palette: {
    primary: {
      light: colors.bluegray1000,
      main: colors.bluegray1000,
      dark: colors.bluegray1000,
    },
    secondary: {
      light: colors.neonpink500,
      main: colors.neonpink500,
      dark: colors.neonpink500,
    },
    text: {
      primary: colors.bluegray1000,
      secondary: colors.bluegray300,
    },
    divider: colors.bluegray100,
    action: {
      hover: colors.bluegray200,
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: colors.bluegray600,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 700,
          "&.MuiButton-sizeSmall": { borderRadius: "6px" },
          "&.MuiButton-sizeMedium": { borderRadius: "10px" },
          "&.MuiButton-sizeLarge": { borderRadius: "12px" },
        },
        contained: {
          "&.MuiButton-containedSecondary": {
            backgroundColor: colors.bluegray50,
            color: colors.bluegray700,
          },
          "&.MuiButton-containedError": {
            backgroundColor: colors.bluegray50,
            color: colors.red600,
          },
          "&.MuiButton-textPrimary": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: "4px",
          "&.MuiChip-colorSuccess": {
            color: colors.neongreen1000,
            backgroundColor: colors.neongreen500,
          },
          "&.MuiChip-colorInfo": {
            color: colors.white,
            backgroundColor: colors.lightblue500,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          ...textStyles.web.header2,
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          gap: "12px",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.bluegray700,
          ...textStyles.web.subhead3,
          "&.Mui-focused": {
            color: colors.bluegray700,
          },
          ".MuiFormLabel-asterisk": {
            color: colors.red600,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: "12px 24px",
          color: "text.primary",
          "&.Mui-selected": {
            color: colors.white,
            backgroundColor: colors.bluegray1000,
          },
          "&.Mui-selected:hover": {
            backgroundColor: colors.bluegray800,
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          ".MuiPagination-ul": { justifyContent: "center" },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px !important",
            borderColor: "#0000003b !important",
          },
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: "secondary",
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...textStyles.web.header3,
          padding: "8px 10px",
          paddingBottom: "12px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          gap: 0,
          borderRadius: "8px",
          fieldset: {
            "&.MuiOutlinedInput-notchedOutline": {
              borderColor: colors.bluegray50,
            },
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
          height: `67px !important`,
        },
      },
    },
  },
});

export default theme;
