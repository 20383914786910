import {
  Box,
  Card,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Layout } from "components";
import AgreementCenterHeader from "components/AgreeCenter/AgreementCenterHeader";
import Chip from "components/Chip";
import { format } from "date-fns";

import { useAgreeCenter } from "hooks/useAgreeCenter";
import { useNavigate } from "react-router-dom";
import { colors, textStyles } from "styles";
import { stateStyles } from "./AgreementChipStyle";

interface AgreeCenterProps {
  agreementStatus: "AGREE" | "DISAGREE" | "FOLLOW_UP" | "MISSED" | "CLAIM" | "ORGANIC" | "ETC";
  description: string;
  id: number;
  modifiedAt?: string;
  phoneNumber: string;
  shopName: string;
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: colors.bluegray100,
    ...textStyles.web.header4,
  },
  [`&.${tableCellClasses.body}`]: {
    ...textStyles.web.body1,
    borderBottom: colors.bluegray100,
  },
}));

export const AgreementCenter = () => {
  const { data, search, refetch } = useAgreeCenter();
  const navigate = useNavigate();
  console.log(data);
  const stateTexts = {
    AGREE: "동의",
    DISAGREE: "비동의",
    CLAIM: "클레임",
    MISSED: "부재중",
    FOLLOW_UP: "팔로업",
    ORGANIC: "기타",
    ETC: "기타",
  };

  return (
    <Layout>
      <AgreementCenterHeader serviceTitle="동의 센터" {...search} refetch={refetch} />
      <Card
        sx={{
          display: "flex",
          borderRadius: "16px",
          width: "100%",
          height: "100%",
          flexWrap: "wrap",
          background: "#F6F7F8",
        }}
      >
        <Grid
          container
          sx={{
            gap: "12px",
            padding: "32px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            {...textStyles.web.header2}
            sx={{ padding: "8px 0", color: `${colors.bluegray700}` }}
          >
            전체보기
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead sx={{ borderBottom: `1px solid ${colors.bluegray100}` }}>
                <TableRow>
                  <StyledTableCell sx={{ width: "20%" }}>센터명</StyledTableCell>
                  <StyledTableCell sx={{ width: "15%" }}>연락처</StyledTableCell>
                  <StyledTableCell sx={{ width: "10%" }}>수정일</StyledTableCell>

                  <StyledTableCell sx={{ width: "7%" }}>상태</StyledTableCell>
                  <StyledTableCell sx={{ width: "48%" }}>최근 비고</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.results?.map((data: AgreeCenterProps) => (
                    <TableRow
                      key={data.id}
                      sx={{ borderBottom: `1px solid ${colors.bluegray100}`, cursor: "pointer" }}
                      onClick={() => navigate(`/agreementCenter/detail/${data.id}`)}
                    >
                      <StyledTableCell component="th" scope="row">
                        {data.shopName}
                      </StyledTableCell>
                      <StyledTableCell>
                        {data.phoneNumber.replace(
                          /(02)(\d{3,4})(\d{4})|(\d{3})(\d{4})(\d{4})|(\d{3})(\d{3})(\d{4})/,
                          (match, p1, p2, p3, p4, p5, p6, p7, p8, p9) => {
                            return p1
                              ? p1 + "-" + p2 + "-" + p3
                              : p4
                              ? p4 + "-" + p5 + "-" + p6
                              : p7
                              ? p7 + "-" + p8 + "-" + p9
                              : "";
                          }
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        {data.modifiedAt != undefined &&
                          format(new Date(data.modifiedAt), "yyyy.MM.dd")}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Chip
                          state={data.agreementStatus}
                          content={stateTexts[data.agreementStatus]}
                          textStyle={textStyles.web.subhead5}
                          stateStyle={stateStyles[data.agreementStatus]}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{data.description}</StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Card>
    </Layout>
  );
};
