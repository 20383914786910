import {
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  TextField,
  Stack,
} from "@mui/material";
import { IRecruitPostUseForm } from "apis/recruitPost.type";
import { useEffect } from "react";

interface Props extends IRecruitPostUseForm {
  type?: string;
}

const TalentFormAccount = ({
  type,
  register,
  setValue,
  formState: { errors },
}: Props) => {
  const isCreate = type === "create";
  const title = isCreate ? "아이디/비밀번호" : "아이디";

  useEffect(() => {
    if (!isCreate) {
      setValue("companyUserEmail", "contact@boundcrew.com");
    }
  }, []);

  return (
    <Paper
      sx={{
        px: "20px",
        py: "24px",
        borderRadius: "24px",
      }}
    >
      <Stack spacing="32px">
        <FormControl fullWidth>
          <FormLabel required>{title}</FormLabel>
          <TextField
            fullWidth
            placeholder="이메일 주소 입력"
            autoComplete="user-email"
            error={!!errors.companyUserEmail}
            helperText={errors.companyUserEmail?.message}
            {...register("companyUserEmail", {
              required: { value: true, message: "이메일 주소를 입력해주세요" },
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                message: "유효한 이베일 주소를 입력해주세요",
              },
            })}
          />
          <TextField
            fullWidth
            type="password"
            placeholder="비밀번호 입력"
            autoComplete="current-password"
            error={!!errors.companyUserPassword}
            helperText={errors.companyUserPassword?.message}
            {...register("companyUserPassword", {
              required: {
                value: isCreate,
                message: "비밀번호를 입력해주세요",
              },
            })}
            sx={{ display: isCreate ? "inline-flex" : "none" }}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>계정유형</FormLabel>
          <FormControlLabel label="센터/기관" control={<Radio checked />} />
        </FormControl>
      </Stack>
    </Paper>
  );
};

export default TalentFormAccount;
