import { Control, Controller, UseFormRegister } from "react-hook-form";
import {
  Card,
  CardContent,
  CardMedia,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Close, Dehaze } from "@mui/icons-material";

import { textStyles } from "styles";
import { formatDateKo } from "utils/formatter";
import { IBannerResponse, IGetBannerListResp } from "apis/banner.type";
import { useState } from "react";
import { viewData } from "pages/NewDate";

interface Props extends IBannerResponse {
  index?: number;
  listName: "companyBannerList" | "workshopBannerList" | "recruitBannerList";
  onDelete: (id: string) => void;
  register: UseFormRegister<IGetBannerListResp>;
  control: Control<IGetBannerListResp, any>;
}

const BannerListItem = (props: Props) => {
  const {
    index = 0,
    imageId,
    imageUrl,
    isDisplay,
    createdAt,
    register,
    control,
    listName,
    onDelete,
    displayStartAt,
    displayEndAt,
    clickRate, // 클릭률
    displayCount, // 조회수
    displayClickCount, // 클릭수
    isDel,
  } = props;
  const [stAt, setStAt] = useState(
    displayStartAt != "" ? viewData(displayStartAt) : ""
  );
  const [etAt, setEtAt] = useState(
    displayStartAt != "" ? viewData(displayEndAt) : ""
  );
  const createdAtLabel = formatDateKo({
    date: createdAt,
    format: "yyyy-MM-dd(eee) HH:mm",
  });

  const onClickDelete = () => onDelete(`${index}`);

  return (
    <Card sx={{ borderRadius: 4, display: isDel ? "none" : "block" }}>
      <CardContent
        sx={{
          p: 2.5,
          pl: 7.5,
          pt: 0.5,
          position: "relative",
        }}
      >
        <Dehaze
          id="sort-icon"
          sx={{
            left: 21.25,
            top: "50%",
            position: "absolute",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: "action.hover",
          }}
        />
        <Stack gap={1}>
          <Stack direction={"row"} justifyContent="space-between">
            <FormControlLabel
              label="앱 노출"
              labelPlacement="start"
              control={<Switch size="small" defaultChecked={isDisplay} />}
              {...register(`${listName}.${index}.isDisplay`)}
              sx={{ ml: 0 }}
            />

            <IconButton onClick={onClickDelete}>
              <Close />
            </IconButton>
          </Stack>

          <CardMedia
            height="207"
            component="img"
            alt={imageId}
            image={imageUrl}
          />

          <Controller
            control={control}
            name={`${listName}.${index}.targetUrl`}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="URL 주소 입력"
                {...field}
              />
            )}
            rules={{
              pattern: {
                value:
                  // eslint-disable-next-line
                  /[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/i,
                message: "유효한 URL 주소를 입력해주세요",
              },
            }}
          />
          <Controller
            control={control}
            name={`${listName}.${index}.displayStartAt`}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="배너 예약 시작 시간 입력"
                {...field}
                value={stAt}
                onChange={(e) => {
                  setStAt(e.target.value);
                  field.onChange(e.target.value);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={`${listName}.${index}.displayEndAt`}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="배너 예약 종료 시간 입력"
                {...field}
                value={etAt}
                onChange={(e) => {
                  setEtAt(e.target.value);
                  field.onChange(e.target.value);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={`${listName}.${index}.priority`}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="배너 순서를 입력해 주세요"
                {...field}
              />
            )}
          />

          <Typography color="text.secondary" {...textStyles.web.callout1}>
            추가일: {createdAtLabel}
          </Typography>
          <Typography color="text.secondary" {...textStyles.web.callout1}>
            조회수: {displayCount} 클릭수 : {displayClickCount}
          </Typography>
          <Typography color="text.secondary" {...textStyles.web.callout1}>
            클릭률 : {clickRate != null ? clickRate?.toFixed(2) : "0"}%
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default BannerListItem;
