import { useMutation } from "react-query";

import { deleteFilesImage, setFilesImage } from "apis/file";

import { useCustomSnackbar } from "./useCustomSnackbar";

export const useImageUploader = () => {
  const snackbar = useCustomSnackbar();

  const { mutateAsync: fetchUploadImage, isLoading } = useMutation(
    ["@common/setFilesImage"],
    setFilesImage,
    {
      onSuccess: () => {
        snackbar({ severity: "info", message: "사진을 추가했어요." });
      },
    }
  );

  const { mutateAsync: fetchDeleteImage } = useMutation(
    ["@common/deleteFilesImage"],
    deleteFilesImage,
    {
      onSuccess: () => {
        snackbar({ severity: "info", message: "사진을 삭제했어요." });
      },
    }
  );

  return { isLoading, fetchUploadImage, fetchDeleteImage };
};
