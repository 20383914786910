import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useQueryClient } from "react-query";
import { AppBar, Button, Toolbar, Typography } from "@mui/material";

import Assets from "assets/Assets";
import { accessTokenSelector } from "store";
import { colors, textStyles } from "styles";

import LogoutDialog from "./LogoutDialog";

interface LayoutHeaderProps {
  isLogin?: boolean;
}

const LayoutHeader = ({ isLogin }: LayoutHeaderProps) => {
  const queryClient = useQueryClient();
  const setAccessToken = useSetRecoilState(accessTokenSelector);

  const userEmail = sessionStorage.getItem("USER_EMAIL");
  const displayLogout = isLogin ? "inline-flex" : "none";

  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const onLogout = () => {
    setAccessToken("");
    queryClient.invalidateQueries();
  };

  return (
    <>
      <LogoutDialog isOpen={isOpen} onClose={onClose} onLogout={onLogout} />
      <AppBar
        elevation={0}
        variant="outlined"
        position="fixed"
        sx={{
          bgcolor: colors.white,
          borderColor: colors.bluegray50,
          color: colors.bluegray1000,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Assets assetName="logo-dark" />
          <Typography ml={"16px"} mr="auto" sx={{ ...textStyles.web.header4 }}>
            관리자 센터
          </Typography>

          <Typography
            mr={"16px"}
            sx={{ ...textStyles.web.subhead3, display: displayLogout }}
          >
            {userEmail}
          </Typography>
          <Button
            variant="outlined"
            onClick={onOpen}
            sx={{ display: displayLogout }}
          >
            로그아웃
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default LayoutHeader;
