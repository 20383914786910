import { find } from "lodash";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import {
  Card,
  CardContent,
  CardMedia,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { textStyles } from "styles";
import { formatDateKo } from "utils/formatter";
import { IBannerResponse, IGetPopupListResp } from "apis/banner.type";
import { IGroupCodes } from "apis/commonCode.type";
import { GROUP_CODE } from "apis/typeCodes";
import { useState } from "react";
import { viewData } from "pages/NewDate";

interface Props extends IBannerResponse {
  index?: number;
  groupCodes?: IGroupCodes[];
  onDelete: (id: string) => void;
  register: UseFormRegister<IGetPopupListResp>;
  control: Control<IGetPopupListResp, any>;
}

const PopupListItem = (props: Props) => {
  const {
    index = 0,
    groupCodes = [],
    imageId,
    imageUrl,
    isDisplay,
    createdAt,
    register,
    control,
    onDelete,
    displayStartAt,
    displayEndAt,
  } = props;

  const createdAtLabel = formatDateKo({
    date: createdAt,
    format: "yyyy-MM-dd(eee) HH:mm",
  });

  const onClickDelete = () => onDelete(`${index}`);
  const displayTargetCodes = find(groupCodes, {
    code: GROUP_CODE.DISPLAY_TARGET,
  });
  const displayPositionCodes = find(groupCodes, {
    code: GROUP_CODE.DISPLAY_POSITION,
  });

  const [stAt, setStAt] = useState(
    displayStartAt != "" ? viewData(displayStartAt) : ""
  );
  const [etAt, setEtAt] = useState(
    displayStartAt != "" ? viewData(displayEndAt) : ""
  );

  return (
    <Card sx={{ borderRadius: 4 }}>
      <CardContent
        sx={{
          p: 2.5,
          pt: 0.5,
          position: "relative",
        }}
      >
        <Stack gap={1}>
          <Stack direction={"row"} justifyContent="space-between">
            <FormControlLabel
              label="앱 노출"
              labelPlacement="start"
              control={<Switch size="small" defaultChecked={isDisplay} />}
              {...register(`popupList.${index}.isDisplay`)}
              sx={{ ml: 0 }}
            />

            <IconButton onClick={onClickDelete}>
              <Close />
            </IconButton>
          </Stack>

          <CardMedia
            height="304"
            component="img"
            alt={imageId}
            image={imageUrl}
            sx={{ borderRadius: 3 }}
          />

          <Controller
            control={control}
            name={`popupList.${index}.targetUrl`}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="URL 주소 입력"
                {...field}
              />
            )}
            rules={{
              pattern: {
                value:
                  // eslint-disable-next-line
                  /[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/i,
                message: "유효한 URL 주소를 입력해주세요",
              },
            }}
          />

          <Stack gap={2} mt={2}>
            <FormControl fullWidth>
              <FormLabel>노출 대상</FormLabel>
              <Controller
                name={`popupList.${index}.exposureTargetTypeCode`}
                control={control}
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    {displayTargetCodes?.groupCodeList?.map(
                      ({ code, codeName }) => (
                        <FormControlLabel
                          key={code}
                          value={code}
                          control={<Radio />}
                          label={codeName}
                        />
                      )
                    )}
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <FormLabel>노출 위치</FormLabel>
              <Controller
                name={`popupList.${index}.displayPositionTypeCode`}
                control={control}
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    {displayPositionCodes?.groupCodeList?.map(
                      ({ code, codeName }) => (
                        <FormControlLabel
                          key={code}
                          value={code}
                          control={<Radio />}
                          label={codeName}
                        />
                      )
                    )}
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Stack>
          <Controller
            control={control}
            name={`popupList.${index}.displayStartAt`}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="팝업 예약 시작 시간 입력"
                {...field}
                value={stAt}
                onChange={(e) => {
                  setStAt(e.target.value);
                  field.onChange(e.target.value);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={`popupList.${index}.displayEndAt`}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="팝업 예약 종료 시간 입력"
                {...field}
                value={etAt}
                onChange={(e) => {
                  setEtAt(e.target.value);
                  field.onChange(e.target.value);
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={`popupList.${index}.priority`}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                error={!!error}
                helperText={error?.message}
                placeholder="배너 순서를 입력해 주세요"
                {...field}
              />
            )}
          />
          <Typography color="text.secondary" {...textStyles.web.callout1}>
            추가일: {createdAtLabel}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PopupListItem;
