import { useState } from "react";
import { AxiosResponse } from "axios";
import { useFieldArray } from "react-hook-form";
import { Box, Grid, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";

import { IGetPopupListRespForm } from "apis/banner.type";
import { ISetFilesImageResp } from "apis/files.type";
import { CustomAlert } from "components";
import { useImageUploader } from "hooks";

import { createBannerDefaultValue } from "../type";
import DeletePopupDialog from "./DeletePopupDialog";
import PopupListItem from "./PopupListItem";

const PopupList = ({
  groupCodes,
  control,
  register,
}: IGetPopupListRespForm) => {
  const [selectedIndex, setSelectedIndex] = useState("");
  const { fields, prepend, update } = useFieldArray({
    control,
    name: "popupList",
  });

  const inputId = `popupList-input`;
  const { isLoading, fetchUploadImage } = useImageUploader();

  const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    fetchUploadImage(file).then(
      ({ data }: AxiosResponse<ISetFilesImageResp>) => {
        const createdAt = String(new Date().getTime());
        prepend({ ...createBannerDefaultValue, ...data, createdAt });
      }
    );
  };

  return (
    <Stack gap={1.5}>
      <DeletePopupDialog
        isOpen={!!selectedIndex}
        onClose={() => setSelectedIndex("")}
        onDelete={() => {
          const targetPopup = fields[Number(selectedIndex)];
          update(Number(selectedIndex), { ...targetPopup, isDel: true });
          setSelectedIndex("");
        }}
      />

      <CustomAlert
        descriptions={[
          "권장 이미지 사이즈: 1000x900 (5:4.5 비율)",
          "유저 당 24시간 기준 한번 노출",
          "팝업 노출 위계: 시스템 팝업 > 추가한 팝업 > 기타 팝업 (앱 설치 넛지, 리뷰 넛지, 알림 수신 넛지 등)",
          "URL 미입력시 클릭 불가",
        ]}
      />

      <Box
        id={inputId}
        type="file"
        accept="image/*"
        component="input"
        onChange={onUploadImage}
        sx={{ display: "none" }}
      />

      <LoadingButton
        fullWidth
        component="label"
        variant="outlined"
        htmlFor={inputId}
        loading={isLoading}
        startIcon={<Add />}
      >
        이미지 가져오기
      </LoadingButton>

      <Grid container spacing={1.5}>
        {fields.map((item, index) => (
          <Grid
            item
            xs={6}
            key={item.id}
            sx={{ display: item.isDel ? "none" : "block" }}
          >
            <PopupListItem
              {...item}
              index={index}
              register={register}
              control={control}
              onDelete={setSelectedIndex}
              groupCodes={groupCodes}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default PopupList;
