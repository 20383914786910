import { Add, InfoOutlined } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { find } from "lodash";
import React, { useEffect, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { Controller } from "react-hook-form";

import { ISetFilesImageResp } from "apis/files.type";
import { IRecruitPostUseForm } from "apis/recruitPost.type";
import { GROUP_CODE, PAY_TYPE_CODE } from "apis/typeCodes";
import { CustomDialog, ImageUploader } from "components";
import { colors, textStyles } from "styles";

const TalentFormPresenter = ({
  groupCodes,
  watch,
  control,
  register,
  setError,
  setValue,
  getValues,
  formState: { errors },
}: IRecruitPostUseForm) => {
  const [isOpenPostCode, setIsOpenPostCode] = useState(false);
  const [payNumber, setPayNumber] = useState<string>("");
  // const [code, setCode] = useState("");
  const { MONTH } = PAY_TYPE_CODE;
  const [detailImg, setDetailImg] = useState<ISetFilesImageResp[]>([]);
  const [centerImg, setCenterImg] = useState<ISetFilesImageResp[]>([]);
  // codes
  const dutyCodes = find(groupCodes, { code: GROUP_CODE.DUTY });
  const employCodes = find(groupCodes, { code: GROUP_CODE.EMPLOY });
  const payCodes = find(groupCodes, { code: GROUP_CODE.PAY });
  const weekCodes = find(groupCodes, { code: GROUP_CODE.WEEK });
  const timeCodes = find(groupCodes, { code: GROUP_CODE.TIME });

  // states
  const watchAddress = watch("address");
  const centerDetailImages = Array.from({ length: 6 });
  const watchCenterDetailImages = watch("centerDetailImages");
  const centerImages = Array.from({ length: 6 });
  const watchCenterImages = watch("centerImages");

  const [checkState, setCheckState] = useState(getValues("negotiationAvailable"));
  const [timeCheckState, setTimeCheckState] = useState(getValues("isTimeNegotiation"));

  const payValue = (e: any) => {
    let { value } = e.target;
    const onlyNumber = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "");
    value = onlyNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setPayNumber(value);
  };

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    return setValue("title", value);
  };

  useEffect(() => {
    if (payNumber == "") {
      setValue(
        "pay",
        getValues()
          .pay.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    } else {
      setValue("pay", payNumber);
    }
  }, [payNumber]);

  useEffect(() => {
    if (detailImg.length > 0) {
      setValue("centerDetailImages", detailImg);
    }
    if (centerImg.length > 0) {
      setValue("centerImages", centerImg);
    }
  }, [detailImg, centerImg]);

  // 7일 추가 로직
  const watchDueYear = watch("dueYear", new Date().getFullYear());
  const watchDueMonth = watch("dueMonth", new Date().getMonth() + 1);
  const watchDueDay = watch("dueDay", new Date().getDate());

  const addSevenDays = () => {
    const newDueDate = new Date(watchDueYear, watchDueMonth - 1, watchDueDay + 7);
    setValue("dueYear", newDueDate.getFullYear());
    setValue("dueMonth", newDueDate.getMonth() + 1);
    setValue("dueDay", newDueDate.getDate());
  };

  useEffect(() => {
    // 현재 날짜 가져오기
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();

    // 현재 날짜에 7일을 더한 날짜 계산
    const newDueDate = new Date(currentYear, currentMonth - 1, currentDay + 7);

    // 값을 업데이트
    setValue("dueYear", newDueDate.getFullYear());
    setValue("dueMonth", newDueDate.getMonth() + 1);
    setValue("dueDay", newDueDate.getDate());
  }, []);

  if (!dutyCodes || !employCodes || !payCodes || !weekCodes || !timeCodes) {
    return null;
  }
  return (
    <Paper
      sx={{
        py: 3,
        px: 2.5,
        borderRadius: 6,
      }}
    >
      <CustomDialog
        scroll="body"
        title="주소 입력"
        open={isOpenPostCode}
        onClose={() => setIsOpenPostCode(false)}
        sx={{ ".MuiDialogContent-root": { padding: 0 } }}
      >
        <DaumPostcode
          onClose={() => setIsOpenPostCode(false)}
          onComplete={(data) => {
            if (data.userSelectedType == "J") {
              setValue("address", data.jibunAddress);
              setError("address", { message: "" });
            } else {
              if (data.buildingName != "") {
                setValue("address", data.roadAddress + " " + "(" + data.buildingName + ")");
                setError("address", { message: "" });
              } else {
                setValue("address", data.roadAddress);
                setError("address", { message: "" });
              }
            }
          }}
        />
      </CustomDialog>

      <Stack spacing={4}>
        {/* <Divider /> */}

        <FormControl fullWidth>
          <FormLabel required>공고 제목</FormLabel>
          <TextField
            fullWidth
            placeholder="[월,수] 20:00,21:00"
            error={!!errors.title}
            helperText={errors.title?.message}
            {...register("title", { required: "공고 제목을 입력해주세요" })}
            onChange={onChangeTitle}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel required>구하는 강사</FormLabel>
          <Controller
            name="dutyTypeCode"
            control={control}
            render={({ field }) => (
              <RadioGroup row {...field}>
                {dutyCodes?.groupCodeList?.map(({ code, codeName }) => (
                  <FormControlLabel
                    key={code}
                    value={code}
                    control={<Radio />}
                    label={codeName}
                    onClick={() => {
                      if (code == "003") {
                        setValue("title", "[월-금] 시간협의");
                        setCheckState(true);
                        setValue("negotiationAvailable", true);
                        setValue("payTypeCode", MONTH);
                        setValue("weeks", ["001", "002", "003", "004", "005"]);
                        setTimeCheckState(true);
                        setValue("isTimeNegotiation", true);
                      }
                      // setCode(code);
                    }}
                    // rules={{ require: "구하는 강사" }}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>근무 유형</FormLabel>
          <Controller
            name="employTypeCode"
            control={control}
            render={({ field }) => (
              <RadioGroup row {...field}>
                {employCodes?.groupCodeList?.map(({ code, codeName }) => (
                  <FormControlLabel key={code} value={code} control={<Radio />} label={codeName} />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel required>근무지명</FormLabel>
          <TextField
            fullWidth
            placeholder="예. 바운드 신촌점 또는 강남역 4번 출구 센터"
            error={!!errors.shopName}
            helperText={errors.shopName?.message}
            {...register("shopName", { required: "근무지명을 입력해주세요" })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>이력서 받을 연락처</FormLabel>
          <TextField
            fullWidth
            type="tel"
            placeholder="휴대폰 번호 입력"
            error={!!errors.contact1}
            helperText={errors.contact1?.message}
            {...register("contact1", {
              required: "휴대폰 번호를 입력해주세요",
              pattern: {
                value: /^\d{2,4}-\d{3,4}-\d{4}$/,
                message: "유효한 핸드폰 번호가 아닙니다.('-'을 포함해서 작성해주세요)",
              },
            })}
          />
          <TextField
            fullWidth
            type="email"
            placeholder="이메일 주소 입력 (필수)"
            {...register("contact2", {
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                message: "유효한 이베일 주소를 입력해주세요",
              },
            })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>근무지 주소</FormLabel>
          <TextField
            fullWidth
            placeholder="주소"
            inputProps={{ readOnly: true }}
            error={!!errors.address?.message}
            helperText={errors.address?.message}
            {...register("address", { required: "주소를 입력해주세요" })}
            onClick={() => setIsOpenPostCode(true)}
          />
          <TextField
            fullWidth
            disabled={!watchAddress}
            placeholder="상세 주소 입력"
            {...register("detailAddress")}
          />
          <Button
            fullWidth
            size="small"
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setIsOpenPostCode(true)}
          >
            주소 {watchAddress ? "변경하기" : "입력하기"}
          </Button>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>급여</FormLabel>
          <Stack direction={"row"} spacing={1}>
            <Controller
              name="payTypeCode"
              control={control}
              render={({ field }) => (
                <Select size="small" {...field} sx={{ width: 100, height: 40 }}>
                  {payCodes?.groupCodeList?.map(({ code, codeName }) => (
                    <MenuItem key={code} value={code}>
                      {codeName}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <TextField
              fullWidth
              type="text"
              placeholder="급여액 입력"
              InputProps={{ endAdornment: "원" }}
              error={!!errors.pay}
              helperText={errors.pay?.message}
              {...register("pay", {
                required: "급여액를 입력해주세요",
                onChange: payValue,
              })}
            />
          </Stack>
          <FormControl sx={{ display: "flex", flexDirection: "row" }}>
            <Button variant="outlined" onClick={() => setValue("pay", "25,000")}>
              25,000
            </Button>
            <Button variant="outlined" onClick={() => setValue("pay", "30,000")}>
              30,000
            </Button>
            <Button variant="outlined" onClick={() => setValue("pay", "35,000")}>
              35,000
            </Button>
          </FormControl>
          <FormControlLabel
            label="협의 가능"
            control={
              <Checkbox
                checked={checkState}
                onChange={(e) => {
                  setCheckState(e.target.checked);
                  setValue("negotiationAvailable", checkState);
                }}
              />
            }
            {...register("negotiationAvailable")}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>마감 일자 (년/월/일)</FormLabel>
          <Stack direction={"row"} gap={1}>
            <TextField
              fullWidth
              type="number"
              placeholder="년 (4자)"
              error={!!errors.dueYear}
              helperText={errors.dueYear?.message}
              {...register("dueYear", {
                required: "마감년도를 입력해주세요",
                min: { value: 0, message: "유효한 마감년도를 입력해주세요" },
              })}
            />
            <TextField
              fullWidth
              type="number"
              placeholder="월"
              error={!!errors.dueMonth}
              helperText={errors.dueMonth?.message}
              {...register("dueMonth", {
                required: "마감월을 입력해주세요",
                min: { value: 1, message: "*유효한 마감월을 입력해주세요" },
                max: { value: 12, message: "*유효한 마감월을 입력해주세요" },
              })}
            />
            <TextField
              fullWidth
              type="number"
              placeholder="일"
              error={!!errors.dueDay}
              helperText={errors.dueDay?.message}
              {...register("dueDay", {
                required: "마감일을 입력해주세요",
                min: { value: 1, message: "유효한 마감일을 입력해주세요" },
                max: { value: 31, message: "유효한 마감일을 입력해주세요" },
              })}
            />
          </Stack>
          <Button variant="outlined" sx={{ width: "100px" }} onClick={addSevenDays}>
            7일 추가
          </Button>
          <Stack direction={"row"} alignItems="center" spacing={0.75}>
            <InfoOutlined sx={{ fontSize: 14, color: colors.bluegray300 }} />
            <Typography color={colors.bluegray500} sx={{ ...textStyles.web.caption2 }}>
              최대 1개월까지 설정할 수 있어요.
            </Typography>
          </Stack>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>상세 정보</FormLabel>
          <TextField
            fullWidth
            multiline
            minRows={3}
            error={!!errors.detail}
            helperText={errors.detail?.message}
            placeholder="상세 정보 입력"
            {...register("detail", { required: "상세 정보를 입력해주세요" })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel>이미지로 상세 정보 채우기 (선택)</FormLabel>
          <Grid container spacing={1}>
            {/* 추가 */}
            {centerDetailImages.map((_, i) => {
              const targetImg = watchCenterDetailImages?.[i];
              const id = targetImg?.imageId ?? `center-detail-image-${i}`;

              const onChange = (value: ISetFilesImageResp) => {
                setDetailImg((prev: any) => [...prev, value]);
                if (watchCenterDetailImages)
                  setValue("centerDetailImages", [...watchCenterDetailImages, value]);
              };

              const onDelete = () => {
                setValue(
                  "centerDetailImages",
                  watchCenterDetailImages?.filter(({ imageId }) => targetImg?.imageId !== imageId)
                );
                setDetailImg(detailImg.filter(({ imageId }) => targetImg?.imageId !== imageId));
              };

              return (
                <Grid item xs={6} key={id}>
                  <ImageUploader
                    id={id}
                    height={110}
                    value={watchCenterDetailImages?.[i]}
                    onChange={onChange}
                    onDelete={onDelete}
                    isDetailImg
                  />
                </Grid>
              );
            })}
          </Grid>

          <Stack direction={"row"} spacing={1}>
            <FormControlLabel
              label="초보가능"
              control={<Checkbox defaultChecked={getValues("beginnerOk")} />}
              {...register("beginnerOk")}
            />
            <FormControlLabel
              label="남자강사 가능"
              control={<Checkbox defaultChecked={getValues("isMan")} />}
              {...register("isMan")}
            />
            <FormControlLabel
              label="바운드 ONLY"
              control={<Checkbox defaultChecked={getValues("isBound")} />}
              {...register("isBound")}
            />
          </Stack>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel>센터/기관 사진</FormLabel>
          <Grid container spacing={1}>
            {centerImages.map((_, i) => {
              const targetImg = watchCenterImages?.[i];
              const id = targetImg?.imageId ?? `center-image-${i}`;

              const onChange = (value: ISetFilesImageResp) => {
                setCenterImg((prev: any) => [...prev, value]);
                if (watchCenterImages) setValue("centerImages", [...watchCenterImages, value]);
              };

              const onDelete = () => {
                setValue(
                  "centerImages",
                  watchCenterImages?.filter(({ imageId }) => targetImg?.imageId !== imageId)
                );
                setCenterImg(centerImg.filter(({ imageId }) => targetImg?.imageId !== imageId));
              };

              return (
                <Grid item xs={6} key={id}>
                  <ImageUploader
                    id={id}
                    height={110}
                    value={watchCenterImages?.[i]}
                    onChange={onChange}
                    onDelete={onDelete}
                  />
                </Grid>
              );
            })}
          </Grid>
        </FormControl>

        <FormControl sx={{ p: 2, backgroundColor: colors.bluegray25, borderRadius: 4 }}>
          <FormLabel>공고 추천 설정</FormLabel>
          <Controller
            name="weeks"
            control={control}
            render={({ field }) => (
              <ToggleButtonGroup
                {...field}
                size="small"
                onChange={(e, newValue) => {
                  const result = { ...e, target: { ...e, value: newValue } };
                  field.onChange(result);
                }}
              >
                {weekCodes?.groupCodeList?.map(({ code, codeName }) => (
                  <ToggleButton key={code} value={code}>
                    {codeName}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
          />
          <Controller
            name="times"
            control={control}
            render={({ field }) => (
              <ToggleButtonGroup
                {...field}
                color="primary"
                onChange={(e, newValue) => {
                  const result = { ...e, target: { ...e, value: newValue } };
                  field.onChange(result);
                }}
              >
                {timeCodes?.groupCodeList?.map(({ code, codeName }) => (
                  <ToggleButton key={code} value={code}>
                    {codeName}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            )}
          />

          <Stack direction={"row"}>
            <FormControlLabel
              label="요일 무관"
              control={<Checkbox defaultChecked={getValues("isWeekNegotiation")} />}
              {...register("isWeekNegotiation")}
            />
            <FormControlLabel
              label="시간 무관"
              control={
                <Checkbox
                  checked={timeCheckState}
                  onChange={(e) => {
                    setTimeCheckState(e.target.checked);
                    setValue("negotiationAvailable", timeCheckState);
                  }}
                />
              }
              {...register("isTimeNegotiation")}
            />
          </Stack>
        </FormControl>
      </Stack>
    </Paper>
  );
};

export default TalentFormPresenter;
