import { format } from "date-fns";

import {
  DUTY_TYPE_CODE,
  EMPLOYEE_TYPE_CODE,
  PAY_TYPE_CODE,
  RECRUIT_CHANNEL_TYPE_CODE,
  SPECIAL_LECTURE_TYPE_CODE,
} from "apis/typeCodes";

export const createDefaultValues = {
  additionalInformation1: "",
  additionalInformation2: "",
  address: "",
  beginnerOk: false,
  centerDetailImages: [],
  centerImages: [],
  companyImages: [],
  companyUserEmail: "",
  companyUserPassword: "",
  contact1: "",
  contact2: "",
  detail:
    "👉 강의 위치\n-\n\n👉 강의 기간\n-\n\n👉 강의 내용\n-\n\n👉 등록비\n-\n\n**본 내용은 업체 사정으로 변경될 수 있습니다.\n**바운드는 정보제공업체로서 교육업체의 당사자가 아니며, 상품의 결제, 수강 및 환불 등과 관련한 의무와 책임은 각 업체에게 있습니다.",
  detailAddress: "",
  dueYear: Number(format(new Date(), "yyyy")),
  dueMonth: Number(format(new Date(), "MM")),
  dueDay: Number(format(new Date(), "dd")),
  startYear: Number(format(new Date(), "yyyy")),
  startMonth: Number(format(new Date(), "MM")),
  startDay: Number(format(new Date(), "dd")),
  dutyTypeCode: DUTY_TYPE_CODE.PILATES,
  employTypeCode: EMPLOYEE_TYPE_CODE.REGULAR,
  specialLectureTypeCode: SPECIAL_LECTURE_TYPE_CODE.WORKSHOP,
  isMan: false,
  isTimeNegotiation: false,
  isWeekNegotiation: false,
  negotiationAvailable: false,
  pay: 0,
  payTypeCode: PAY_TYPE_CODE.TIME,
  recruitChannelTypeCode: RECRUIT_CHANNEL_TYPE_CODE.WORKSHOP,
  shopName: "",
  times: [],
  title: "",
  weeks: [],
  workshopUrl: "",
};
