import React from "react";
import { SpreadsheetProps } from "./type";
import * as S from "./styles";

const Spreadsheet = ({
  $height = 420,
  className = "",
  columnLabels,
  ...rest
}: SpreadsheetProps) => {
  return (
    <S.Wrap className={className} $height={$height}>
      <S.Spreadsheet {...rest} columnLabels={columnLabels} />
    </S.Wrap>
  );
};

export default Spreadsheet;
