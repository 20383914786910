import Emitter from "eventemitter3";

const emitter = new Emitter();

export const useEmitter = () => {
  return {
    on: (key: TEmitterKey, func: (params?: any) => void) => emitter.on(key, func),
    off: (key: TEmitterKey) => emitter.off(key),
    emit: (key: TEmitterKey, params?: any) => emitter.emit(key, params),
  };
};

type TEmitterKey =
  | `RefetchList`
  | "DeletePost"
  | "CreatePost"
  | "ReviewPost"
  | "ResumesPost"
  | "FinishPost"
  | "DeleteCareer"
  | "RejectPost"
  | "AllAgreeList";
