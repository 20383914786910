export enum RECRUIT_CHANNEL_TYPE_CODE {
  ALL = "001",
  CORPORATE_LECTURE = "002",
  WORKSHOP = "003",
  CAREER = "004",
}

export enum SEARCH_TYPE_CODE {
  INSTITUTION_NAME = "001",
  TITLE = "002",
  PHONE_NUMBER = "003",
}

export enum CENTER_SEARCH_TYPE_CODE {
  EMAIL = "EMAIL",
  NAME = "NAME",
  PHONE_NUMBER = "PHONE_NUMBER",
}

export enum DUTY_TYPE_CODE {
  YOGA = "001",
  PILATES = "002",
  HEALTH = "003",
  ETC = "004",
}

export enum EMPLOYEE_TYPE_CODE {
  REGULAR = "001",
  OUTLINE = "002",
}

export enum PAY_TYPE_CODE {
  TIME = "001",
  DAY = "002",
  MONTH = "003",
  PER_PIECE = "004",
}

export enum SPECIAL_LECTURE_TYPE_CODE {
  WORKSHOP = "001",
  CERTIFICATION = "002",
  FREE = "003",
  ETC = "004",
}

export enum GROUP_CODE {
  LOGIN_CHANNEL = "001",
  DISPLAY_POSITION = "004",
  EMPLOY = "012",
  DUTY = "013",
  WEEK = "014",
  TIME = "015",
  PAY = "017",
  DISPLAY_TARGET = "021",
  SPECIAL_LECTURE = "022",
}

export enum TARGET_TYPE_CODE {
  INNER = "001",
  OUTER = "002",
  NONE = "003",
}
