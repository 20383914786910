import { forwardRef, useState } from "react";
import { Add, Close } from "@mui/icons-material";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";

import { colors } from "styles";
import { useImageUploader } from "hooks";
import { ISetFilesImageResp } from "apis/files.type";

import {
  StyledImagePreview,
  StyledImageUploaderBox,
  StyledImageUploaderInput,
  StyledImageUploaderLabel,
} from "./style";
import { compressionImage } from "utils/compressionImage";
import { Options } from "browser-image-compression";

interface Props {
  id: string;
  height?: number | string;
  iconSize?: "small" | "medium" | "large";
  value?: ISetFilesImageResp;
  onChange: (value: ISetFilesImageResp) => void;
  onDelete: () => void;
  isDetailImg?: boolean;
}

const detailImgOptions: Options = {
  alwaysKeepResolution: true,
  maxSizeMB: 2,
};

const ImageUploader = (props: Props, ref: React.Ref<HTMLInputElement>) => {
  const {
    id,
    height,
    iconSize = "large",
    value,
    onChange,
    onDelete,
    isDetailImg = false,
  } = props;
  const { fetchDeleteImage, fetchUploadImage, isLoading } = useImageUploader();

  const fetchCompressionImage = async (files: FileList) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const compressionFile = await compressionImage(
        file,
        isDetailImg ? detailImgOptions : {}
      );

      if (!compressionFile) continue;

      await fetchUploadImage(compressionFile).then(({ data }) =>
        onChange(data)
      );
    }
  };

  const onChangeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files == undefined) return;

    await fetchCompressionImage(files);
  };

  const onDeleteImage = () => {
    if (value?.imageId) fetchDeleteImage(value?.imageId).then(onDelete);
  };

  const isPreview = value ? true : false;

  const [isPreviewOpen, setPreviewOpen] = useState(false);

  const openPreview = () => {
    setPreviewOpen(true);
  };

  const closePreview = () => {
    setPreviewOpen(false);
  };

  return (
    <StyledImageUploaderBox sx={{ height }}>
      <IconButton
        size="small"
        onClick={onDeleteImage}
        sx={{
          top: 8,
          right: 8,
          position: "absolute",
          backgroundColor: colors.bluegray300,
          display: isPreview ? "inline-flex" : "none",
        }}
      >
        <Close fontSize="small" sx={{ color: colors.white }} />
      </IconButton>

      <StyledImagePreview
        src={value?.imageUrl}
        sx={{ display: isPreview ? "block" : "none" }}
        onClick={openPreview}
      />

      <StyledImageUploaderLabel
        htmlFor={id}
        sx={{ display: !isPreview ? "inline-flex" : "none" }}
      >
        <StyledImageUploaderInput
          id={id}
          ref={ref}
          type="file"
          accept="image/*"
          multiple
          onChange={onChangeImage}
        />

        {isLoading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Add fontSize={iconSize} color="secondary" />
        )}
      </StyledImageUploaderLabel>
      <Dialog open={isPreviewOpen} onClose={closePreview}>
        <DialogContent>
          <img
            src={value?.imageUrl}
            alt="Image Preview"
            style={{ maxWidth: "100%" }}
          />
        </DialogContent>
      </Dialog>
    </StyledImageUploaderBox>
  );
};

export default forwardRef(ImageUploader);
