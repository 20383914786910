import { FormControlLabel, Stack, Switch } from "@mui/material";

import { GridTitle } from "components";
import { IGetBannerListRespForm } from "apis/banner.type";

interface Props extends IGetBannerListRespForm {
  name:
    | "isRecruitBanner"
    | "isCompanyBanner"
    | "isWorkshopBanner"
    | "isWorkshopCarousl";
  title: string;
  description?: string;
  defaultChecked?: boolean;
}

const BannerGridTitle = ({
  name,
  title,
  description,
  defaultChecked = false,
  register,
}: Props) => (
  <Stack direction={"row"} alignItems="center" mb={2.5}>
    <GridTitle
      title={title}
      titleProps={{ mb: 0, mr: "auto" }}
      description={description}
    />
    <FormControlLabel
      label="서비스 노출"
      labelPlacement="start"
      control={<Switch size="small" defaultChecked={defaultChecked} />}
      {...register(name)}
    />
  </Stack>
);

export default BannerGridTitle;
