import { atom, selector } from "recoil";

const accessTokenAtom = atom({
  key: "account/accessToken",
  default: sessionStorage.getItem("ACCESS_TOKEN"),
});

export const accessTokenSelector = selector({
  key: "account/setAccessToken",
  get: ({ get }) => get(accessTokenAtom),
  set: ({ set }, value) => {
    if (value) sessionStorage.setItem("ACCESS_TOKEN", String(value));
    else sessionStorage.removeItem("ACCESS_TOKEN");

    return set(accessTokenAtom, value);
  },
});
