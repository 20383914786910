export const viewData = (time: any) => {
  const times = new Date(time);

  const year = times.getFullYear();
  let month: string | number = times.getMonth() + 1;
  let day: string | number = times.getDate();
  let hour: string | number = times.getHours();
  let minute: string | number = times.getMinutes();

  month = month >= 10 ? month : "0" + month;
  day = day >= 10 ? day : "0" + day;
  hour = hour >= 10 ? hour : "0" + hour;
  minute = minute >= 10 ? minute : "0" + minute;

  const total = year + "-" + month + "-" + day + "-" + hour + ":" + minute;
  return total;
};

export const serverSentData = (time: any) => {
  const date = time.split("-");
  const year = date[0];
  const month = date[1];
  const day = date[2];
  const hourAndMinute = date[3];

  const total =
    year + "-" + month + "-" + day + "T" + hourAndMinute + ":00.000Z";
  return total;
};

export const newData = (time: any) => {
  const times = new Date(time);
  const date = new Date(times);
  let month: string | number = date.getMonth() + 1;
  let day: string | number = date.getDate();
  let hour: string | number = date.getHours();
  let minute: string | number = date.getMinutes();
  let second: string | number = date.getSeconds();

  month = month >= 10 ? month : "0" + month;
  day = day >= 10 ? day : "0" + day;
  hour = hour >= 10 ? hour : "0" + hour;
  minute = minute >= 10 ? minute : "0" + minute;
  second = second >= 10 ? second : "0" + second;

  const total =
    date.getFullYear() +
    "-" +
    month +
    "-" +
    day +
    "T" +
    hour +
    ":" +
    minute +
    ":" +
    second +
    ".000Z";
  return total;
};

export default newData;
