import { format } from "date-fns";

import {
  DUTY_TYPE_CODE,
  EMPLOYEE_TYPE_CODE,
  PAY_TYPE_CODE,
  RECRUIT_CHANNEL_TYPE_CODE,
} from "apis/typeCodes";

export const createDefaultValues = {
  additionalInformation1: "",
  additionalInformation2: "",
  address: "",
  beginnerOk: false,
  centerDetailImages: [],
  centerImages: [],
  companyImages: [],
  companyUserEmail: "",
  companyUserPassword: "",
  contact1: "",
  contact2: "",
  detail: `👉 근무 형태\n- 근무 시간 입력 및 수업 내용 입력\n\n👉 급여 및 복리후생\n- 급여 및 복리후생 입력 (선택)\n\n👉 기타사항\n- 기타 구인 관련 내용 입력 (선택)\n\n👉 지원 자격 및 우대사항\n- 관련 자격증 소지자\n- 우대사항 입력\n- 기타 자격 입력 (선택)`,
  detailAddress: "",
  dueYear: Number(format(new Date(), "yyyy")),
  dueMonth: Number(format(new Date(), "MM")),
  dueDay: Number(format(new Date(), "dd")),
  startYear: Number(format(new Date(), "yyyy")),
  startMonth: Number(format(new Date(), "MM")),
  startDay: Number(format(new Date(), "dd")),
  dutyTypeCode: DUTY_TYPE_CODE.PILATES,
  employTypeCode: EMPLOYEE_TYPE_CODE.REGULAR,
  isMan: false,
  isBound: false,
  isTimeNegotiation: false,
  isWeekNegotiation: false,
  negotiationAvailable: false,
  pay: 0,
  payTypeCode: PAY_TYPE_CODE.TIME,
  recruitChannelTypeCode: RECRUIT_CHANNEL_TYPE_CODE.ALL,
  shopName: "",
  times: [],
  title: "",
  weeks: [],
  workshopUrl: "",
};
