import { AccessTime, LocationOn, Paid } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  ChipProps,
  Divider,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { filter } from "lodash";
import { Fragment } from "react";

import GridTitle from "components/GridTitle";
import { useRecoilState } from "recoil";
import { recruitTotalState } from "store/recruitState";
import { colors, textStyles } from "styles";

interface Props {
  chips?: ChipProps[];
  title: string;
  strongTitle?: string;
  summaries?: string;
  pay?: string;
  location?: string;
  datetime?: string;
  shopName?: string;
  centerMainImage?: string;
  counts?: { label: string; count?: number }[];
  actionButtons?: JSX.Element;
  onOpenPreview?: () => void;
  sx?: SxProps;
  inReview?: boolean;
  id?: string | undefined;
}

const PostCard = (props: Props) => {
  const {
    chips,
    title,
    strongTitle,
    summaries,
    pay,
    location,
    datetime,
    shopName,
    centerMainImage,
    counts,
    actionButtons,
    onOpenPreview,
    inReview,
    id,
  } = props;

  const ChipList = () => {
    if (!chips) return null;

    const visibleChips = filter(chips, "defaultChecked");
    return (
      <Stack gap={0.75} direction={"row"}>
        {visibleChips.map((chip) => (
          <Chip key={`${chip.label}`} size="small" {...chip} />
        ))}
      </Stack>
    );
  };

  const [checkState, setCheckState] = useRecoilState<string[]>(recruitTotalState);

  const TitleLabel = () => {
    const StrongTitle = () => {
      if (!strongTitle) return null;
      return (
        <Typography
          mr={0.5}
          component={"span"}
          color={colors.blue500}
          {...textStyles.web.subhead3}
          whiteSpace="nowrap"
        >
          {strongTitle}
        </Typography>
      );
    };

    const tooltipTitle = strongTitle ? `${strongTitle} ${title}` : title;

    return (
      <Box component={"span"} maxWidth="100%">
        <Tooltip arrow title={tooltipTitle} placement={"top-start"} sx={{ width: "90%" }}>
          <Typography
            gap={1}
            flexWrap="nowrap"
            overflow={"hidden"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
            width="calc(100%-70px)"
            {...textStyles.web.subhead3}
          >
            <StrongTitle />
            {title}
          </Typography>
        </Tooltip>
        <Typography display={"block"} color="text.secondary" {...textStyles.web.body3}>
          {summaries}
        </Typography>
      </Box>
    );
  };

  const onClickCheckBox = () => {
    if (checkState == undefined || id == undefined) return;
    const IsCheck = checkState?.includes(id);
    if (IsCheck) {
      const checkStateFilter = checkState?.filter((item) => item !== id);
      return setCheckState && setCheckState(checkStateFilter);
    }
    return setCheckState && setCheckState([...checkState, id]);
  };

  const IconLabel = [
    { icon: <Paid fontSize="inherit" sx={{ mt: 0.375 }} />, label: pay },
    {
      icon: <LocationOn fontSize="inherit" sx={{ mt: 0.375 }} />,
      label: location,
    },
    {
      icon: <AccessTime fontSize="inherit" sx={{ mt: 0.375 }} />,
      label: datetime,
    },
  ];

  return (
    <Card sx={{ position: "relative" }}>
      <CardContent
        sx={{
          p: 2.5,
          pb: 0,
          cursor: onOpenPreview ? "pointer" : "default",
        }}
        onClick={onOpenPreview}
      >
        <Stack gap={1}>
          <ChipList />
          <TitleLabel />

          <Stack direction="row" justifyContent={"space-between"}>
            <Stack flex="auto">
              {IconLabel.map(({ icon, label }, index) => (
                <Typography
                  gap={1}
                  key={`${label}-${index}`}
                  display={"inline-flex"}
                  color={colors.bluegray400}
                  maxWidth={"calc(100% - 15px)"}
                  {...textStyles.web.body3}
                >
                  {icon}
                  {label}
                </Typography>
              ))}
            </Stack>

            <Box
              width={60}
              height={60}
              component={"img"}
              src={centerMainImage}
              alt={shopName}
              sx={{
                borderRadius: 1.5,
                objectFit: "cover",
                objectPosition: "center",
                display: centerMainImage ? "block" : "none",
              }}
            />
          </Stack>

          <Box
            p={0.75}
            borderRadius={1.5}
            alignItems="center"
            border={`solid 1px ${colors.bluegray50}`}
            display={!counts?.length ? "none" : "flex"}
          >
            {counts?.map(({ count, label }, index) => (
              <Fragment key={label}>
                {index ? <Divider orientation="vertical" flexItem /> : null}
                <GridTitle
                  title={label}
                  titleProps={{
                    mb: 0,
                    flex: 1,
                    textAlign: "center",
                    color: colors.bluegray500,
                  }}
                  description={count?.toLocaleString()}
                  descriptionProps={{ color: colors.bluegray700 }}
                  textStyle={textStyles.web.callout2}
                />
              </Fragment>
            ))}
          </Box>
        </Stack>
      </CardContent>
      <CardActions sx={{ p: 2.5, pt: 1, display: actionButtons ? "flex" : "none" }}>
        {actionButtons}
      </CardActions>
      {inReview && (
        <Checkbox
          sx={{
            height: "24px",
            position: "absolute",
            zIndex: "10",
            top: 25,
            right: 15,
          }}
          onChange={onClickCheckBox}
          checked={id !== undefined && checkState.includes(id)}
        />
      )}
    </Card>
  );
};

export default PostCard;
