import { useState } from "react";
import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";

import { IThemaResponseOf } from "apis/banner.type";
import { SortableList } from "components";

import CarouselItem from "./CarouselItem";
import DeleteBannerDialog from "./DeleteBannerDialog";

type TCarouselSortableList = IThemaResponseOf & { index?: number };
const CarouselSortableList = SortableList<TCarouselSortableList>;

interface Props {
  workshopPosts: IThemaResponseOf[];
  setWorkshopPosts: React.Dispatch<React.SetStateAction<IThemaResponseOf[]>>;
  onOpenWorkshopDialog: () => void;
}

const CarouselList = ({
  workshopPosts,
  setWorkshopPosts,
  onOpenWorkshopDialog,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState("");

  // 이전 데이터 선택한 부분과 위치 바꾸는 로직 -> 기존 데이터에서 중간에 들어가는 로직 예 123456 -> 124536 느낌
  const onSort = (oldIndex: number, newIndex: number) => {
    const update = workshopPosts.slice();
    const movePost = update.splice(oldIndex, 1)[0];
    update.splice(newIndex, 0, movePost);
    setWorkshopPosts(update);
  };

  return (
    <Stack gap={1.5}>
      <DeleteBannerDialog
        isOpen={!!selectedIndex}
        onClose={() => setSelectedIndex("")}
        onDelete={() => {
          setWorkshopPosts((posts) =>
            posts.filter((_, index) => index !== Number(selectedIndex))
          );
          setSelectedIndex("");
        }}
      />

      <LoadingButton
        fullWidth
        variant="outlined"
        startIcon={<Add />}
        onClick={onOpenWorkshopDialog}
      >
        공고 가져오기
      </LoadingButton>

      <CarouselSortableList
        isSortIcon
        gap={1.5}
        list={workshopPosts}
        onSort={onSort}
        getSortId={(item) => item.id}
        getRenderItem={(item) => {
          const onDeleteIndex = () => setSelectedIndex(`${item.index}`);
          return (
            <CarouselItem post={{ ...item }} onDeleteIndex={onDeleteIndex} />
          );
        }}
      />
    </Stack>
  );
};

export default CarouselList;
