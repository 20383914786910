import { find } from "lodash";
// import { useState } from "react";
// import DaumPostcode from "react-daum-postcode";
import { Controller } from "react-hook-form";
// import { Add } from "@mui/icons-material";
import {
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  TextField,
  Stack,
  RadioGroup,
  Divider,
  // Button,
  Grid,
  Typography,
} from "@mui/material";

import { GROUP_CODE } from "apis/typeCodes";
import { ISetFilesImageResp } from "apis/files.type";
import { ImageUploader } from "components"; // CustomDialog 주석 처리
import { IRecruitPostUseForm } from "apis/recruitPost.type";
import { useEffect, useState } from "react";

const WorkshopFormPresenter = ({
  groupCodes,
  watch,
  control,
  register,
  // setError,
  setValue,
  formState: { errors },
}: IRecruitPostUseForm) => {
  // const [isOpenPostCode, setIsOpenPostCode] = useState(false);

  // codes
  const specialLectureCodes = find(groupCodes, {
    code: GROUP_CODE.SPECIAL_LECTURE,
  });

  const [detailImg, setDetailImg] = useState<ISetFilesImageResp[]>([]);
  const [centerImg, setCenterImg] = useState<ISetFilesImageResp[]>([]);

  // states
  // const watchAddress = watch("address");
  const centerDetailImages = Array.from({ length: 6 });
  const watchCenterDetailImages = watch("centerDetailImages");
  const centerImages = Array.from({ length: 6 });
  const watchCenterImages = watch("centerImages");

  useEffect(() => {
    if (detailImg.length > 0) {
      setValue("centerDetailImages", detailImg);
    }
    if (centerImg.length > 0) {
      setValue("centerImages", centerImg);
    }
  }, [detailImg, centerImg]);

  if (!specialLectureCodes) return null;

  return (
    <Paper
      sx={{
        py: 3,
        px: 2.5,
        borderRadius: 6,
      }}
    >
      {/* <CustomDialog
        scroll="body"
        title="주소 입력"
        open={isOpenPostCode}
        onClose={() => setIsOpenPostCode(false)}
        sx={{ ".MuiDialogContent-root": { padding: 0 } }}
      >
        <DaumPostcode
          onClose={() => setIsOpenPostCode(false)}
          onComplete={({ address }) => {
            setValue("address", address);
            setError("address", { message: "" });
          }}
        />
      </CustomDialog> */}

      <Stack spacing="32px">
        <FormControl fullWidth>
          <FormLabel required>워크샵/특강 종류</FormLabel>
          <Controller
            name="specialLectureTypeCode"
            control={control}
            render={({ field }) => (
              <RadioGroup row {...field}>
                {specialLectureCodes?.groupCodeList?.map(({ code, codeName }) => (
                  <FormControlLabel key={code} value={code} control={<Radio />} label={codeName} />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>

        <Divider />

        <FormControl fullWidth>
          <FormLabel required>워크샵/특강 제목</FormLabel>
          <TextField
            fullWidth
            placeholder="예. 월,수 - 11시,12시,13시"
            error={!!errors.title}
            helperText={errors.title?.message}
            {...register("title", {
              required: "워크샵/특강 제목을 입력해주세요",
            })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>위치</FormLabel>
          <TextField
            fullWidth
            placeholder="주소"
            inputProps={{ readOnly: false }}
            error={!!errors.address?.message}
            helperText={errors.address?.message}
            {...register("address", { required: "주소를 입력해주세요" })}
            // onClick={() => setIsOpenPostCode(true)}
          />
          {/* <TextField
            fullWidth
            disabled={!watchAddress}
            placeholder="상세 주소 입력"
            {...register("detailAddress")}
          />
          <Button
            fullWidth
            size="small"
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setIsOpenPostCode(true)}
          >
            주소 {watchAddress ? "변경하기" : "입력하기"}
          </Button> */}
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>참가비</FormLabel>
          <TextField
            fullWidth
            type="number"
            placeholder="참가비 입력"
            InputProps={{
              endAdornment: <Typography whiteSpace={"nowrap"}>원 부터</Typography>,
            }}
            error={!!errors.pay}
            helperText={errors.pay?.message}
            {...register("pay", {
              required: "참가비를 입력해주세요",
              min: { value: 0, message: "유효한 참가비을 입력해주세요" },
            })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>시작 일자 (년/월/일)</FormLabel>
          <Stack direction={"row"} gap={1}>
            <TextField
              fullWidth
              type="number"
              placeholder="년 (4자)"
              error={!!errors.startYear}
              helperText={errors.startYear?.message}
              {...register("startYear", {
                required: "시작년도를 입력해주세요",
                min: {
                  value: 0,
                  message: "유효한 시작년도를 입력해주세요",
                },
              })}
            />
            <TextField
              fullWidth
              type="number"
              placeholder="월"
              error={!!errors.startMonth}
              helperText={errors.startMonth?.message}
              {...register("startMonth", {
                required: "시작월을 입력해주세요",
                min: {
                  value: 1,
                  message: "*유효한 시작월을 입력해주세요",
                },
                max: {
                  value: 12,
                  message: "*유효한 시작월을 입력해주세요",
                },
              })}
            />
            <TextField
              fullWidth
              type="number"
              placeholder="일"
              error={!!errors.startDay}
              helperText={errors.startDay?.message}
              {...register("startDay", {
                required: "시작일을 입력해주세요",
                min: { value: 1, message: "유효한 시작일을 입력해주세요" },
                max: {
                  value: 31,
                  message: "유효한 시작일을 입력해주세요",
                },
              })}
            />
          </Stack>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>상세 정보</FormLabel>
          <TextField
            fullWidth
            multiline
            minRows={3}
            error={!!errors.detail}
            helperText={errors.detail?.message}
            placeholder="상세 정보 입력"
            {...register("detail", { required: "상세 정보를 입력해주세요" })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel>이미지로 상세 정보 채우기 (선택)</FormLabel>
          <Grid container spacing={1}>
            {centerDetailImages.map((_, i) => {
              const targetImg = watchCenterDetailImages?.[i];
              const id = targetImg?.imageId ?? `center-detail-image-${i}`;

              const onChange = (value: ISetFilesImageResp) => {
                setDetailImg((prev: any) => [...prev, value]);
                if (watchCenterDetailImages)
                  setValue("centerDetailImages", [...watchCenterDetailImages, value]);
              };

              const onDelete = () => {
                setValue(
                  "centerDetailImages",
                  watchCenterDetailImages?.filter(({ imageId }) => targetImg?.imageId !== imageId)
                );
                setDetailImg(detailImg.filter(({ imageId }) => targetImg?.imageId !== imageId));
              };

              return (
                <Grid item xs={6} key={id}>
                  <ImageUploader
                    id={id}
                    height={110}
                    value={watchCenterDetailImages?.[i]}
                    onChange={onChange}
                    onDelete={onDelete}
                    isDetailImg
                  />
                </Grid>
              );
            })}
          </Grid>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel>워크샵/특강 사진</FormLabel>
          <Grid container spacing={1}>
            {/* {centerImages.map((_, i) => {
              const targetImg = watchCenterImages?.[i];
              const id = targetImg?.imageId ?? `center-image-${i}`;

              const onChange = (value: ISetFilesImageResp) =>
                watchCenterImages
                  ? setValue("centerImages", [...watchCenterImages, value])
                  : setValue("centerImages", [value]);

              const onDelete = () => {
                setValue(
                  "centerImages",
                  watchCenterImages?.filter(
                    ({ imageId }) => targetImg?.imageId !== imageId
                  )
                );
              };

              return (
                <Grid item xs={6} key={id}>
                  <ImageUploader
                    id={id}
                    height={110}
                    value={watchCenterImages?.[i]}
                    onChange={onChange}
                    onDelete={onDelete}
                  />
                </Grid>
              );
            })} */}
            {centerImages.map((_, i) => {
              const targetImg = watchCenterImages?.[i];
              const id = targetImg?.imageId ?? `center-image-${i}`;

              const onChange = (value: ISetFilesImageResp) => {
                setCenterImg((prev: any) => [...prev, value]);
                if (watchCenterImages) setValue("centerImages", [...watchCenterImages, value]);
              };

              const onDelete = () => {
                setValue(
                  "centerImages",
                  watchCenterImages?.filter(({ imageId }) => targetImg?.imageId !== imageId)
                );
                setCenterImg(centerImg.filter(({ imageId }) => targetImg?.imageId !== imageId));
              };

              return (
                <Grid item xs={6} key={id}>
                  <ImageUploader
                    id={id}
                    height={110}
                    value={watchCenterImages?.[i]}
                    onChange={onChange}
                    onDelete={onDelete}
                  />
                </Grid>
              );
            })}
          </Grid>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>신청하기 버튼 URL</FormLabel>
          <TextField
            fullWidth
            error={!!errors.workshopUrl}
            helperText={errors.workshopUrl?.message}
            placeholder="URL 주소 입력"
            {...register("workshopUrl", {
              required: "URL 주소를 입력해주세요",
              pattern: {
                value:
                  // eslint-disable-next-line
                  /[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/i,
                message: "유효한 URL 주소를 입력해주세요",
              },
            })}
          />
        </FormControl>
      </Stack>
    </Paper>
  );
};

export default WorkshopFormPresenter;
