import { ReactComponent as LogoDark } from "assets/svg/logo-dark.svg";

interface AssetsProps {
  assetName: "logo-dark";
  width?: number;
  height?: number;
}

const Assets = (props: AssetsProps) => {
  const { assetName, width, height } = props;
  switch (assetName) {
    case "logo-dark":
      return <LogoDark style={{ width: width, height: height }} />;
    default:
      return null;
  }
};

export default Assets;
