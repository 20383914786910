import { Box, Grid, Stack } from "@mui/material";

import { RECRUIT_CHANNEL_TYPE_CODE, SEARCH_TYPE_CODE } from "apis/typeCodes";
import {
  Layout,
  GridTitle,
  ListPagination,
  PostDialogs,
  PostListHeader,
} from "components";
import { useRecruitPostList } from "hooks";

import LecturePost from "./LecturePost";

const LectureList = () => {
  const searchDefaultValues = {
    searchTypeCode: SEARCH_TYPE_CODE.TITLE,
    recruitChannelTypeCode: RECRUIT_CHANNEL_TYPE_CODE.CORPORATE_LECTURE,
  };

  const { data, loading, search, pagination } =
    useRecruitPostList(searchDefaultValues);

  const postList = [
    { title: "모집 예정", ...data?.reviewPosts },
    { title: "모집 중", ...data?.posts },
    { title: "마감", ...data?.finishPosts },
  ];

  return (
    <Layout
      isLoading={loading}
      footer={<ListPagination loading={loading} {...pagination} />}
    >
      <PostDialogs.Delete />
      <PostDialogs.Finish />
      <PostDialogs.Resume />

      <PostListHeader
        {...search}
        isOnlyTitle
        serviceTitle="기업 강의"
        searchDefaultValues={searchDefaultValues}
        createPostLink="/lecture/create"
      />

      <Box my={5} mx={6}>
        <Grid container spacing={6}>
          {postList.map(({ title, count, posts }) => (
            <Grid item xs={4} key={title}>
              <GridTitle title={title} description={count?.toLocaleString()} />

              <Stack mt={2.5} gap={1.5}>
                {posts?.map((post) => (
                  <LecturePost key={post.id} post={post} />
                ))}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Layout>
  );
};

export default LectureList;
