import { RecoilRoot } from "recoil";
import { SnackbarProvider } from "notistack";
import { GlobalStyles, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";

import PageContainer from "pages";

import { globalStyle } from "./styles";
import theme from "./styles/theme";

const queryClient = new QueryClient();

function App() {
  console.log(process.env.NODE_ENV);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={globalStyle} />
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <PageContainer />
          </RecoilRoot>
        </QueryClientProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
