import { AllAgreeCenterList } from "./agreeCenter.type";
import { apiGateway } from "./apiGateway";

// 동의 센터 리스트
export const getAllAgreeCenter = async ({ type, value }: AllAgreeCenterList) => {
  const { data } = await apiGateway.get(`/agreement?type=${type}&value=${value}`);
  return data;
};

// 동의 센타 작성
export const postAgreementCenter = async (request: any) => {
  const { data } = await apiGateway.post("/agreement", { requests: request });
  return data;
};

// 동의 센터 상세
export const getDetailAgreeCenter = async (id: string) => {
  const { data } = await apiGateway.get(`/agreement/${id}`);
  return data;
};
