import styled from "@emotion/styled";
import SpreadsheetComp from "react-spreadsheet";
import { textStyles, colors } from "styles";
import { SpreadsheetProps } from "./type";

export const Wrap = styled.div<Pick<SpreadsheetProps, "$height">>`
  height: ${({ $height }) => $height}px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const THead = styled.thead`
  width: 100%;
`;

export const Spreadsheet = styled(SpreadsheetComp)`
  .Spreadsheet {
    &__table {
      width: 100%;
    }

    &__header {
      ${textStyles.web.subhead3};
      color: ${colors.black};
      background-color: ${colors.bluegray50};

      &--selected {
        background-color: ${colors.bluegray200};
      }
    }
  }

  th,
  td {
    padding: 7px 0;
  }
`;
