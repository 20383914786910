import { Add, ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { postAgreementCenter } from "apis/agreeCenter";
import Spreadsheet from "components/Spreadsheet";
import { useCustomSnackbar } from "hooks/useCustomSnackbar";
import { useSpreadsheet } from "hooks/useSpreadsheet";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { colors, textStyles } from "styles";
import CustomDialog from "../CustomDialog";
import * as data from "./data";

interface Props {
  isOnlyTitle?: boolean;
  isSearched?: boolean;
  serviceTitle?: string;
  searchDefaultValues?: string;
  onSearch: (searchParams: SearchParams) => void;
  detail?: boolean;
  refetch?: () => void;
}

interface SearchParams {
  searchTypeCode: string;
  searchName: string;
}

const { columns, requiredColumns } = data;

const AgreementCenterHeader = ({
  isSearched,
  serviceTitle,
  searchDefaultValues,
  refetch,
  onSearch,
  detail = false,
}: Props) => {
  const navigate = useNavigate();
  const title = isSearched ? "검색 결과" : serviceTitle;
  const [isShowDialog, setIsShowDialog] = useState(false);
  const onCloseDialog = () => setIsShowDialog(false);
  const snackbar = useCustomSnackbar();
  const { control, register, reset, handleSubmit } = useForm<any>({
    defaultValues: searchDefaultValues,
  });

  const BackButton = () => {
    const onClick = () => {
      navigate(-1);
      reset(searchDefaultValues);
    };
    if ((detail && !isSearched) || (!detail && isSearched)) {
      // 버튼을 표시할 조건을 수정합니다.
      return (
        <IconButton color="primary" onClick={onClick}>
          <ArrowBack />
        </IconButton>
      );
    } else {
      return null;
    }
  };

  const {
    spreadSheetData,
    setSpreadSheetData,
    onConvert,
    onRequiredValidate,
    resetSpreadsheetData,
  } = useSpreadsheet({
    columns,
    requiredColumns,
  });

  const onValidate = () => {
    const onStatusValidate = () => {
      const validStatusValues = ["동의", "비동의", "클레임", "부재중", "팔로업", "기타"];

      const status = spreadSheetData.map((state) => state[2]?.value);
      const isValid = status.every((value) => validStatusValues.includes(value));
      return isValid;
    };

    return onRequiredValidate() && onStatusValidate();
  };

  const { mutate } = useMutation(["@postAgreeCenter", "postAgreeCenter"], postAgreementCenter, {
    onSuccess: () => {
      refetch && refetch();
      snackbar({
        severity: "success",
        message: "응답이 등록되었습니다.",
      });
    },
    onError: () => {
      snackbar({
        severity: "error",
        message: "응답 요청이 실패 하였습니다.",
      });
    },
  });

  const statusCheck: { [key: string]: string } = {
    동의: "AGREE",
    비동의: "DISAGREE",
    클레임: "CLAIM",
    부재중: "MISSED",
    팔로업: "FOLLOW_UP",
    기타: "ETC",
  };

  const onSubmit = () => {
    if (!onValidate()) {
      snackbar({
        severity: "error",
        message: "상태 값이 잘못되었습니다.",
      });
      return;
    }

    const convertData = onConvert();
    const requestData = convertData.map((item) => {
      const status = item.status || "";
      return {
        description: item.etc,
        phoneNumber: item.tel,
        shopName: item.name,
        status: statusCheck[status],
      };
    });
    mutate(requestData);

    onCloseDialog();
    resetSpreadsheetData();
  };

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit(onSearch)}
      sx={{
        px: 3,
        py: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <CustomDialog
        open={isShowDialog}
        onClose={onCloseDialog}
        fullWidth
        maxWidth={1280}
        minWidth={900}
        closeBtn
        borderRadius="16px"
        actionButtons={[
          {
            id: "agree",
            fullWidth: true,
            size: "large",
            children: "응답 등록",
            onClick: onSubmit,
          },
        ]}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography {...textStyles.web.header2} component={"h3"} color={colors.bluegray700}>
            응답 등록
          </Typography>
          <Spreadsheet
            onChange={setSpreadSheetData}
            data={spreadSheetData}
            columnLabels={columns.map((item) => item.label)}
          />
        </Box>
      </CustomDialog>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <BackButton />
        <Typography {...textStyles.web.title1}>{title}</Typography>
      </Box>
      {!detail && (
        <>
          <Box sx={{ display: "flex" }}>
            <Controller
              control={control}
              name="searchTypeCode"
              defaultValue={"PHONE_NUMBER"}
              render={({ field }) => (
                <RadioGroup row {...field} sx={{ ml: "auto" }}>
                  <FormControlLabel label="연락처" control={<Radio />} value={"PHONE_NUMBER"} />
                </RadioGroup>
              )}
            />
            <TextField
              type="search"
              placeholder="검색어 입력"
              {...register("searchName", { required: true })}
            />
            <Button variant="contained" type="submit" sx={{ ml: 1.25, mr: "auto" }}>
              검색
            </Button>
          </Box>
          <Button
            startIcon={<Add />}
            variant="contained"
            sx={{ visibility: isSearched ? "hidden" : "visible" }}
            onClick={() => setIsShowDialog(true)}
          >
            응답 등록
          </Button>
        </>
      )}
    </Paper>
  );
};

export default AgreementCenterHeader;
