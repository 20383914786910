import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

interface Props extends Omit<DialogProps, "maxWidth"> {
  isLoading?: boolean;
  actionButtons?: ButtonProps[];
  closeBtn?: boolean;
  borderRadius?: string;
  maxWidth?: DialogProps["maxWidth"] | number;
  minWidth?: number;
}

const CustomDialog = ({
  open,
  onClose,
  closeBtn,
  title,
  isLoading,
  children,
  actionButtons,
  borderRadius = "4px",
  maxWidth = "xs",
  minWidth,
  ...props
}: Props) => {
  return (
    <Dialog
      {...props}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={typeof maxWidth !== "number" && maxWidth}
      sx={{
        ".MuiDialog-container": {
          "& > .MuiPaper-root": {
            borderRadius,
            ...(maxWidth && typeof maxWidth === "number" && { maxWidth }),
            ...(minWidth && { minWidth }),
          },
        },
      }}
    >
      {closeBtn && (
        <Box
          sx={{
            width: "100%",
            padding: "24px 24px 0 24px",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <button
            style={{ backgroundColor: "white", cursor: "pointer" }}
            onClick={() => onClose && onClose({}, "backdropClick")}
          >
            <CloseIcon sx={{ width: "25px", height: "25px", background: "white" }} />
          </button>
        </Box>
      )}

      {/* <Fade in={isLoading}>
        <LinearProgress />
      </Fade> */}
      <DialogTitle sx={{ display: title ? "block" : "none" }}>{title}</DialogTitle>
      <DialogContent sx={{ display: children ? "block" : "none" }}>{children}</DialogContent>
      <DialogActions
        sx={{
          gap: 1,
          justifyContent: "flex-end",
          display: actionButtons?.length ? "flex" : "none",
          padding: "0 24px 24px",
        }}
      >
        {actionButtons?.map((button) => (
          <Button
            key={button.id}
            fullWidth
            size={button?.size || "small"}
            variant="contained"
            disabled={isLoading}
            {...button}
          >
            {button.children}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
