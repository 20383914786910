import { Add, ArrowBack } from "@mui/icons-material";
import {
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { SEARCH_TYPE_CODE } from "apis/typeCodes";
import { textStyles } from "styles";

interface Props {
  createPostLink: string;
  isOnlyTitle?: boolean;
  isSearched: boolean;
  serviceTitle: string;
  searchDefaultValues: any;
  onSearch: (searchParams: any) => void;
}

const CareerListHeader = ({
  createPostLink,
  isOnlyTitle,
  isSearched,
  serviceTitle,
  searchDefaultValues,
  onSearch,
}: Props) => {
  const navigate = useNavigate();
  const title = isSearched ? "검색 결과" : serviceTitle;

  const { control, register, reset, handleSubmit } = useForm<any>({
    defaultValues: searchDefaultValues,
  });

  const BackButton = () => {
    const onClick = () => {
      navigate(-1);
      reset(searchDefaultValues);
    };

    if (!isSearched) return null;
    return (
      <IconButton color="primary" onClick={onClick}>
        <ArrowBack />
      </IconButton>
    );
  };

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit(onSearch)}
      sx={{ px: 3, py: 2, display: "flex", alignItems: "center" }}
    >
      <BackButton />
      <Typography {...textStyles.web.title1}>{title}</Typography>
      <Controller
        control={control}
        name="searchTypeCode"
        render={({ field }) => (
          <RadioGroup row {...field} sx={{ ml: "auto" }}>
            <FormControlLabel
              label="센터/기관명"
              control={<Radio />}
              value={SEARCH_TYPE_CODE.INSTITUTION_NAME}
              sx={{ display: isOnlyTitle ? "none" : "inline-flex" }}
            />
            <FormControlLabel label="제목" control={<Radio />} value={SEARCH_TYPE_CODE.TITLE} />
          </RadioGroup>
        )}
      />
      <TextField
        type="search"
        placeholder="검색어 입력"
        {...register("searchName", { required: true })}
      />
      <Button variant="contained" type="submit" sx={{ ml: 1.25, mr: "auto" }}>
        검색
      </Button>
      <Button
        startIcon={<Add />}
        variant="contained"
        onClick={() => navigate(createPostLink)}
        sx={{ visibility: isSearched ? "hidden" : "visible" }}
      >
        게시글 작성
      </Button>
    </Paper>
  );
};

export default CareerListHeader;
