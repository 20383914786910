import cookie from "react-cookies";
import { useCustomSnackbar } from "hooks";
import { useMutation, useQueryClient } from "react-query";

import { setRefreshToken } from "apis/account";
import { useSetRecoilState } from "recoil";
import { accessTokenSelector } from "store";

const RefreshToken = () => {
  const snackbar = useCustomSnackbar();
  const clientQuery = useQueryClient();
  const refreshToken = cookie.load("REFRESH_TOKEN");
  const setAccessToken = useSetRecoilState(accessTokenSelector);

  const { mutateAsync } = useMutation(
    ["@common/refreshToken"],
    setRefreshToken,
    {
      onSuccess: ({ data }) => {
        clientQuery.invalidateQueries({ queryKey: ["@posts", "@banner"] });
        sessionStorage.setItem("ACCESS_TOKEN", data?.accessToken);
        snackbar({
          severity: "info",
          title: "사용자 토큰 정보를 갱신했습니다.",
          message: "잠시 후 다시 시도해주세요",
        });
      },
      onError: () => {
        setAccessToken("");
        clientQuery.invalidateQueries();
        sessionStorage.removeItem("ACCESS_TOKEN");
        snackbar({
          severity: "error",
          message: "사용자 토큰이 만료되었습니다.",
        });
      },
    }
  );

  const onClientQueryError = async (error: any) => {
    const severity = "error";
    const message = error?.response?.data?.message || error?.message;
    const isEqualMessage = (errorMessage: string) => errorMessage === message;

    switch (error.status) {
      case 401: {
        if (isEqualMessage("exist email")) {
          return snackbar({ severity, message: "가입된 이메일 주소입니다." });
        }

        await mutateAsync(refreshToken);
        break;
      }
      default: {
        return snackbar({ severity, message });
      }
    }
  };

  clientQuery.setDefaultOptions({
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      onError: onClientQueryError,
    },
    mutations: {
      onError: onClientQueryError,
    },
  });

  return null;
};

export default RefreshToken;
