import { AxiosResponse } from "axios";
import { flatten, max, some } from "lodash";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

import {
  getBannerList,
  getPopupHistoryList,
  getPastAllBanner,
  getPastLectureBanner,
  getPastWorkShopBanner,
  getPopupList,
  getWorkshopList,
  setPatchBanner,
  setPatchPopup,
} from "apis/banner";
import {
  IGetBannerListResp,
  IGetPopupHistoryResp,
  IGetPopupListResp,
} from "apis/banner.type";

import { useCustomSnackbar } from "./useCustomSnackbar";

export const useGetBannerList = () => {
  return useQuery(["@banner", "getBannerList"], getBannerList, {
    select: ({ data }: AxiosResponse<IGetBannerListResp>) => data,
  });
};

export const useGetBannerPastAll = () => {
  return useQuery(["@banner", "getPastBannerAll"], getPastAllBanner, {
    select: ({ data }: AxiosResponse<IGetPopupHistoryResp>) => data,
  });
};
export const useGetBannerPastLecture = () => {
  return useQuery(["@banner", "getPastLectureBanner"], getPastLectureBanner, {
    select: ({ data }: AxiosResponse<IGetPopupHistoryResp>) => data,
  });
};
export const useGetBannerPastWorkShop = () => {
  return useQuery(["@banner", "getPastWorkShopBanner"], getPastWorkShopBanner, {
    select: ({ data }: AxiosResponse<IGetPopupHistoryResp>) => data,
  });
};

export const usePatchBanner = () => {
  const queryClient = useQueryClient();
  const snackbar = useCustomSnackbar();

  return useMutation(["@banner", "setPatchBanner"], setPatchBanner, {
    onSuccess: () => {
      snackbar({ severity: "success", message: "적용 완료되었어요." });
      queryClient.invalidateQueries(["@banner", "getBannerList"]);
    },
  });
};

export const useGetWorkshopList = () => {
  const { data, isLoading, isFetching, fetchNextPage } = useInfiniteQuery(
    ["@banner", "getWorkshopList"],
    ({ pageParam = 1 }) => getWorkshopList(pageParam),
    {
      getNextPageParam: (lastPage) => {
        const page = lastPage.config.params?.page + 1;
        return page;
      },
    }
  );

  const totalPosts = data?.pages.map((page) => page.data?.posts);
  const count = max(totalPosts?.map(({ count }) => count));
  const hasNext = some(totalPosts, "hasNext");
  const posts = flatten(totalPosts?.map(({ posts }) => posts));

  return {
    data: {
      count,
      hasNext,
      posts,
    },
    loading: isLoading || isFetching,
    fetchNextPage,
  };
};

export const useGetPopupList = () => {
  return useQuery(["@popup", "getPopupList"], getPopupList, {
    select: ({ data }: AxiosResponse<IGetPopupListResp>) => data,
  });
};

export const useGetPopupHistory = () => {
  return useQuery(["@popup", "getPopupHistory"], getPopupHistoryList, {
    select: ({ data }: AxiosResponse<IGetPopupHistoryResp>) => data,
  });
};

export const usePatchPopup = () => {
  const queryClient = useQueryClient();
  const snackbar = useCustomSnackbar();

  return useMutation(["@popup", "setPatchPopup"], setPatchPopup, {
    onSuccess: () => {
      snackbar({ severity: "success", message: "적용 완료되었어요." });
      queryClient.invalidateQueries(["@popup"]);
    },
  });
};
