import { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";

import {
  IBannerResponse,
  IGetBannerListResp,
  ISetPatchBannerReq,
} from "apis/banner.type";
import { CircleLoading, GridTitle } from "components";
import {
  useGetBannerPastAll,
  useGetBannerPastLecture,
  useGetBannerPastWorkShop,
  usePatchBanner,
} from "hooks";
import { formatLinkToTargetTypeCode } from "utils/formatter";

import {
  BannerGridTitle,
  BannerHeader,
  BannerList,
  CarouselList,
  PatchBannerDialog,
  WorkshopListDialog,
} from "../components";
import newData, { serverSentData } from "pages/NewDate";
import { PopupHistoryCard } from "pages/Popup/components";

interface Props {
  refetch: () => void;
  defaultValues: IGetBannerListResp;
}

const BannerForm = ({ refetch, defaultValues }: Props) => {
  const { isLoading, mutateAsync } = usePatchBanner();
  const { handleSubmit, ...formProps } = useForm({ defaultValues });

  const All = useGetBannerPastAll();
  const Lecture = useGetBannerPastLecture();
  const WorkShop = useGetBannerPastWorkShop();

  // 전체공고 필터 적용
  const AllNotDel = All.data?.popupList.filter((A) => A.isDel == false);

  const AllDel = All.data?.popupList.filter((A) => A.isDel == true);
  // 기업강의 필터 적용
  const LectureNotDel = Lecture.data?.popupList.filter((W) => W.isDel == false);

  const LectureDel = Lecture.data?.popupList.filter((W) => W.isDel == true);
  // 워크샵 필터 적용
  const WorkShopNotDel = WorkShop.data?.popupList.filter(
    (W) => W.isDel == false
  );

  const WorkShopDel = WorkShop.data?.popupList.filter((W) => W.isDel == true);

  const [isWorkshopDialog, setIsWorkshopDialog] = useState(false);
  const [request, setRequest] = useState<ISetPatchBannerReq | null>(null);
  const [workshopPosts, setWorkshopPosts] = useState(
    defaultValues?.workshopCarouselList
  );
  const recruitBannerList = defaultValues?.recruitBannerList ?? [];
  const companyBannerList = defaultValues?.companyBannerList ?? [];
  const workshopBannerList = defaultValues?.workshopBannerList ?? [];
  const workshopCarouselList = defaultValues?.workshopCarouselList ?? [];

  const description = (list: any[]) => `${list.length.toLocaleString()}개 노출`;

  const convertBannerList = (
    isParentDisplay: boolean,
    list?: IBannerResponse[]
  ) => {
    if (!list) return [];
    return list?.map(
      ({
        imageId,
        targetUrl,
        isDisplay,
        displayStartAt,
        displayEndAt,
        isDel,
        bannerDetailId,
        priority,
      }) => ({
        images: { imageId },
        targetTypeCode: formatLinkToTargetTypeCode(targetUrl),
        isDisplay: !isParentDisplay ? isParentDisplay : isDisplay,
        targetUrl,
        isDel,
        bannerDetailId,
        displayStartAt:
          typeof displayStartAt == "number"
            ? newData(displayStartAt)
            : serverSentData(displayStartAt),
        displayEndAt:
          typeof displayEndAt == "number"
            ? newData(displayEndAt)
            : serverSentData(displayEndAt),
        priority,
      })
    );
  };

  const onSubmit = (data: IGetBannerListResp) => {
    setRequest({
      isRecruitBanner: data.isRecruitBanner,
      recruitBannerList: convertBannerList(
        data.isRecruitBanner, // true/false 값
        data.recruitBannerList // 각 데이터의 배열 값
      ),
      isCompanyBanner: data.isCompanyBanner,
      companyBannerList: convertBannerList(
        data.isCompanyBanner,
        data.companyBannerList
      ),
      isWorkshopBanner: data.isWorkshopBanner,
      workshopBannerList: convertBannerList(
        data.isWorkshopBanner,
        data.workshopBannerList
      ),
      isWorkshopCarousl: data.isWorkshopCarousl,
      workshopCarouslRecruitIdList:
        workshopPosts.map(({ postId }) => Number(postId)) ?? [],
    });
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
      {/* loading */}
      <CircleLoading open={isLoading} />

      {/* dialogs */}
      <PatchBannerDialog
        isOpen={!!request}
        onClose={() => refetch()}
        onPatch={() => request && mutateAsync(request)}
      />

      <WorkshopListDialog
        isOpen={isWorkshopDialog}
        onClose={() => setIsWorkshopDialog(false)}
        workshopPosts={workshopPosts}
        setWorkshopPosts={setWorkshopPosts}
      />

      {/* renders */}
      <BannerHeader lastUpdateDate={defaultValues?.lastUpdateDate} />

      <Box my={5} mx={6}>
        <Grid container gap={6}>
          <Grid item xs>
            <BannerGridTitle
              {...formProps}
              title={"홈 배너"}
              name="isRecruitBanner"
              description={description(recruitBannerList)}
              defaultChecked={defaultValues?.isRecruitBanner}
            />

            <BannerList listName="recruitBannerList" {...formProps} />
          </Grid>
          <Grid item xs>
            <BannerGridTitle
              {...formProps}
              title={"기업 강의 배너"}
              name="isCompanyBanner"
              description={description(companyBannerList)}
              defaultChecked={defaultValues?.isCompanyBanner}
            />

            <BannerList listName="companyBannerList" {...formProps} />
          </Grid>
          <Grid item xs>
            <BannerGridTitle
              {...formProps}
              title={"교육 배너"}
              name="isWorkshopBanner"
              description={description(workshopBannerList)}
              defaultChecked={defaultValues?.isWorkshopBanner}
            />

            <BannerList listName="workshopBannerList" {...formProps} />
          </Grid>{" "}
          <Grid item xs={3}>
            <BannerGridTitle
              {...formProps}
              title={"워크샵/특강 캐러셀"}
              name="isWorkshopCarousl"
              description={description(workshopCarouselList)}
              defaultChecked={defaultValues?.isWorkshopCarousl}
            />

            <CarouselList
              workshopPosts={workshopPosts}
              setWorkshopPosts={setWorkshopPosts}
              onOpenWorkshopDialog={() => setIsWorkshopDialog(true)}
            />
          </Grid>
        </Grid>
        <div style={{ height: "30px" }} />
        <Grid container>
          <Grid item>
            <GridTitle title="배너 현황" textStyle={{ fontSize: "25px" }} />
            <Grid container gap={8}>
              <Grid item xs>
                <GridTitle title="전체 공고" />
                {AllNotDel?.map((data) => (
                  <PopupHistoryCard key={data.bannerDetailId} {...data} />
                ))}
                {AllDel?.map((data) => (
                  <PopupHistoryCard key={data.bannerDetailId} {...data} />
                ))}
              </Grid>
              <Grid item xs>
                <GridTitle title="기업 강의" />
                {LectureNotDel?.map((data) => (
                  <PopupHistoryCard key={data.bannerDetailId} {...data} />
                ))}
                {LectureDel?.map((data) => (
                  <PopupHistoryCard key={data.bannerDetailId} {...data} />
                ))}
              </Grid>
              <Grid item xs>
                <GridTitle title="워크샵/특강 상단" />
                {WorkShopNotDel?.map((data) => (
                  <PopupHistoryCard key={data.bannerDetailId} {...data} />
                ))}
                {WorkShopDel?.map((data) => (
                  <PopupHistoryCard key={data.bannerDetailId} {...data} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BannerForm;
