import { format as dateFnsFormat } from "date-fns";
import { ko } from "date-fns/locale";

import { TARGET_TYPE_CODE } from "apis/typeCodes";

export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3,4})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return null;
}

interface formatDateKoProps {
  date?: string;
  prevFormat?: string;
  format?: string;
  nextFormat?: string;
}
export function formatDateKo({
  date,
  prevFormat = "",
  format = "M/d(eee) HH:mm",
  nextFormat = "",
}: formatDateKoProps) {
  const dateNum = Number(date);
  if (!dateNum || isNaN(dateNum)) return "Invalid Date";
  return dateFnsFormat(dateNum, `${prevFormat}${format}${nextFormat}`, {
    locale: ko,
  });
}

export function formatLinkToTargetTypeCode(link: string) {
  if (!link) return TARGET_TYPE_CODE.NONE;
  if (
    process.env.REACT_APP_BOUND_HOST_NAME &&
    link.includes(process.env.REACT_APP_BOUND_HOST_NAME)
  ) {
    return TARGET_TYPE_CODE.INNER;
  }
  return TARGET_TYPE_CODE.OUTER;
}
