import { apiGateway } from "./apiGateway";
import { EscalationProps } from "./ping.type";

// 센터 공고 이전
export const patchChangeCenter = async (payload: EscalationProps) => {
  const data = await apiGateway.patch(
    `/post/change/${payload.postId}?email=${payload.email}&adminKey=${payload.adminKey}`
  );
  return data;
};

// 센터/강사 계정 전환
export const putChangeCenter = async (payload: any) => {
  const data = await apiGateway.put(
    `/account/join/channel/${payload.channelType}?email=${payload.email}&adminKey=${payload.adminKey}`
  );
  return data;
};
