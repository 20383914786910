import {
  ButtonProps,
  Divider,
  FormLabel,
  Stack,
  TextField,
} from "@mui/material";
import { setRejectRecruitPost } from "apis/recruitPost";
import { CustomDialog } from "components";
import { useCustomSnackbar, useEmitter } from "hooks";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";

const RejectPostDialog = () => {
  const [params, setParams] = useState<{ postId: string } | null>(null);
  const [description, setDescription] = useState("");
  const emitter = useEmitter();
  const snackbar = useCustomSnackbar();
  const postId = params?.postId;

  const isOpen = Boolean(params?.postId);
  const onClose = () => setParams(null);

  useEffect(() => {
    emitter.on("RejectPost", setParams);
    return () => {
      emitter.off("RejectPost");
    };
  }, []);

  const onRejectText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDescription(value);
  };

  const { isLoading, mutate } = useMutation(
    ["@posts", "setRejectRecruitPost"],
    setRejectRecruitPost,
    {
      onSuccess: () => {
        setParams(null);
        emitter.emit("RefetchList");
        snackbar({ severity: "info", message: "반려 처리가 완료되었어요." });
      },
      onError: () => {
        snackbar({
          severity: "error",
          message: "통신 에러 입니다.",
        });
      },
    }
  );

  const actionButtons: ButtonProps[] = [
    { id: "cancel", children: "취소", color: "secondary", onClick: onClose },
    {
      id: "finish",
      children: "반려",
      color: "info",
      onClick: () =>
        params && description != ""
          ? mutate({ postId, description })
          : snackbar({
              severity: "error",
              message: "반려 사유가 입력되지 않았습니다.",
            }),
      style: {
        backgroundColor: "red",
      },
    },
  ];

  return (
    <CustomDialog
      isLoading={isLoading}
      title="공고 반려하시겠어요?"
      open={isOpen}
      onClose={onClose}
      closeBtn={true}
      actionButtons={actionButtons}
    >
      <Divider />
      <Stack sx={{ marginTop: "24px", gap: "12px" }}>
        <FormLabel required>반려 사유</FormLabel>
        <TextField
          placeholder="센터에게 전달할 반려 사유를 적어주세요"
          onChange={onRejectText}
        />
      </Stack>
    </CustomDialog>
  );
};

export default RejectPostDialog;
