import { Box, Paper, SxProps, Toolbar } from "@mui/material";

import { colors } from "styles";
import { drawerWidth } from "./LayoutMenu";

export interface ILayoutMainProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
  mainSx?: SxProps;
}

const LayoutMain = ({ children, footer, mainSx }: ILayoutMainProps) => {
  const displayFooter = footer ? "flex" : "none";
  return (
    <Box
      component="main"
      sx={{
        flex: "auto",
        bgcolor: colors.bluegray25,
        minHeight: `100vh`,
        ...mainSx,
      }}
    >
      <Toolbar sx={{ backgroundColor: "transparent" }} />

      {children}

      <Box height={"64px"} display={displayFooter} />
      <Paper
        sx={{
          bottom: 0,
          padding: "16px",
          position: "fixed",
          width: `calc(100% - ${drawerWidth}px)`,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: displayFooter,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {footer}
      </Paper>
    </Box>
  );
};

export default LayoutMain;
