import { ButtonProps } from "@mui/material";

import { colors } from "styles";
import { CustomDialog } from "components";
import { useCustomSnackbar } from "hooks";

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeletePopupDialog = ({ isOpen = false, onClose, onDelete }: Props) => {
  const snackbar = useCustomSnackbar();

  const onClickDelete = () => {
    onDelete();
    onClose();
    snackbar({ severity: "success", message: "삭제 완료되었어요." });
  };

  const actionButtons: ButtonProps[] = [
    { id: "cancel", children: "취소", color: "secondary", onClick: onClose },
    {
      id: "delete",
      children: "삭제",
      color: "error",
      onClick: onClickDelete,
      sx: {
        "&.MuiButton-containedError": {
          color: colors.white,
          backgroundColor: colors.red800,
        },
      },
    },
  ];

  return (
    <CustomDialog
      open={isOpen}
      onClose={onClose}
      title="삭제하시겠어요?"
      actionButtons={actionButtons}
    />
  );
};

export default DeletePopupDialog;
