import { Button, Paper, Typography } from "@mui/material";

import { colors, textStyles } from "styles";
import { formatDateKo } from "utils/formatter";

interface Props {
  lastUpdateDate?: string;
}

const BannerHeader = ({ lastUpdateDate }: Props) => {
  const lastUpdateLabel = lastUpdateDate
    ? formatDateKo({
        date: lastUpdateDate,
        format: "yyyy-MM-dd HH:mm",
      })
    : "--";

  return (
    <Paper sx={{ px: 3, py: 2, display: "flex", alignItems: "center" }}>
      <Typography {...textStyles.web.title1} mr="auto">
        배너/캐러셀
      </Typography>

      <Typography {...textStyles.web.body1} color={colors.bluegray500} mr={2}>
        마지막 업데이트: {lastUpdateLabel}
      </Typography>

      <Button variant="contained" type="submit">
        전체 적용
      </Button>
    </Paper>
  );
};

export default BannerHeader;
