import { apiGateway } from "./apiGateway";
import { getCenterCertifiListProps } from "./centerCertification.type";

// 센터 인증 리스트 불러오기
export const getCenterCertifiList = async (date: getCenterCertifiListProps) => {
  const { data } = await apiGateway.get(
    `/account/search?type=${date.type}&value=${date.value}`
  );
  return data;
};

// 센터 인증
export const patchAuthorizeCenterCertification = (centerId: number) => {
  return apiGateway.patch("/account/authorize/center", { centerId });
};

// 센터 인증 리스트
export const getCenterAuthorization = async () => {
  const { data } = await apiGateway.get("/authorization/center/list");
  return data;
};

// 센터 인증 리스트 상세
export const getCenterDetailAuthorization = async (centerId: number) => {
  const { data } = await apiGateway.get(`/authorization/center/${centerId}`);
  return data;
};

// 센터 인증 반려
export const patchCenterReturning = async (centerId: number) => {
  await apiGateway.patch("/authorization/center:returning", { centerId });
};
