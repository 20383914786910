import { useRef } from "react";
import { keys, pick } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";

import { GROUP_CODE } from "apis/typeCodes";
import { Layout, PostFormFooterButton } from "components";
import { useEmitter, useGroupCode, usePostDefaultValues } from "hooks";

import {
  TalentFormContent,
  CreatePostDialog,
  TalentFormHeader,
} from "../components";
import { createDefaultValues } from "../type";

interface Props {
  isCreate?: boolean;
}

const TalentForm = ({ isCreate }: Props) => {
  const emitter = useEmitter();
  const navigate = useNavigate();
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const postDefaultValues = usePostDefaultValues(createDefaultValues);
  const groupCode = useGroupCode([
    GROUP_CODE.DUTY,
    GROUP_CODE.EMPLOY,
    GROUP_CODE.PAY,
    GROUP_CODE.WEEK,
    GROUP_CODE.TIME,
  ]);

  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type") || "";
  const onChangeType = (type: string) =>
    setSearchParams({ type }, { replace: true });

  const isLoading = groupCode.isLoading || postDefaultValues.isLoading;
  const onSubmit = () => submitButtonRef.current?.click();

  const title = isCreate ? "공고 작성" : "공고 수정";
  const footer = isCreate ? "작성 완료" : "수정 완료";
  const onBack = () => navigate("/talent");

  const Form = () => {
    if (isLoading) return null;

    const defaultValues = pick(
      postDefaultValues.data,
      keys(createDefaultValues)
    );

    return (
      <TalentFormContent
        type={isCreate ? type : undefined}
        ref={submitButtonRef}
        defaultValues={defaultValues}
        groupCodes={groupCode.data}
        onSuccessSubmit={({ data }, variables) => {
          if (!isCreate) return onBack();
          emitter.emit("CreatePost", {
            ...data,
            email: variables?.companyUserEmail,
          });
        }}
      />
    );
  };

  return (
    <Layout
      isLoading={isLoading}
      footer={<PostFormFooterButton label={footer} onClick={onSubmit} />}
    >
      <CreatePostDialog />

      <TalentFormHeader
        title={title}
        type={type}
        onChangeType={onChangeType}
        onBack={onBack}
      />

      <Form />
    </Layout>
  );
};

export default TalentForm;
