import { Stack } from "@mui/material";
import { RECRUIT_CHANNEL_TYPE_CODE, SEARCH_TYPE_CODE } from "apis/typeCodes";
import { Layout, ListPagination, PostDialogs } from "components";
import CareerListHeader from "components/CareerListHeader";

import { useCareerLists } from "hooks/useCareer";

import CareerCard from "./CareerCard";

const CareerList = () => {
  const searchDefaultValues = {
    searchTypeCode: SEARCH_TYPE_CODE.TITLE,
    recruitChannelTypeCode: RECRUIT_CHANNEL_TYPE_CODE.CAREER,
  };
  const { data, loading, search, pagination } = useCareerLists(searchDefaultValues);

  return (
    <Layout isLoading={loading} footer={<ListPagination loading={loading} {...pagination} />}>
      <PostDialogs.DelCar />
      <CareerListHeader
        {...search}
        isOnlyTitle
        serviceTitle="라이프"
        searchDefaultValues={searchDefaultValues}
        createPostLink="/career/create"
      />
      <Stack mt={4} mx={6} gap={1.5} sx={{ display: "grid", gridTemplateColumns: "repeat(4,1fr)" }}>
        {data?.content?.map((data: any) => (
          <CareerCard key={data.id} data={data} />
        ))}
      </Stack>
    </Layout>
  );
};

export default CareerList;
