import { format } from "date-fns";

import {
  DUTY_TYPE_CODE,
  EMPLOYEE_TYPE_CODE,
  PAY_TYPE_CODE,
  RECRUIT_CHANNEL_TYPE_CODE,
} from "apis/typeCodes";

export const createDefaultValues = {
  additionalInformation1: "",
  additionalInformation2: "",
  address: "",
  beginnerOk: false,
  centerDetailImages: [],
  centerImages: [],
  companyImages: [],
  companyUserEmail: "",
  companyUserPassword: "",
  contact1: "",
  contact2: "",
  detail:
    "👉 강의 위치\n-\n\n👉 일시 및 프로그램\n-\n\n👉 구인 유형 및 담당 업무\n[메인 강사 0명]\n담당 업무:\n\n[서브 강사 0명]\n담당 업무:\n\n** 메인/서브 강사 모두 프로그램과 관련해서 교육 진행 후 출강\n** 프로그램 및 시퀀스를 만들어 오셔야 합니다.\n\n👉 페이\n- 메인 강사 : \n- 서브 강사 : \n(3.3% 원천징수 공제 후 지급)\n\n👉 지원 자격\n-\n\n👉 지원 방법 및 절차\n- 하단 지원하기 선택\n- 1차 합격자 발표 : 0월 0일\n- 2차 심층면접 : 0월 0일\n(반드시 해당 날짜에 면접이 가능한 강사님만 지원)\n- 최종 합격 발표 : 0월 0일\n프로그램 진행 관련 교육 진행 → 출강\n\n**지원하기 누른 후 지원 포지션(서브, 메인) 기재 필수\n**최종 합격한 강사님들 한하여 세부 진행 내용 전달",
  detailAddress: "",
  dueYear: Number(format(new Date(), "yyyy")),
  dueMonth: Number(format(new Date(), "MM")),
  dueDay: Number(format(new Date(), "dd")),
  startYear: Number(format(new Date(), "yyyy")),
  startMonth: Number(format(new Date(), "MM")),
  startDay: Number(format(new Date(), "dd")),
  dutyTypeCode: DUTY_TYPE_CODE.PILATES,
  employTypeCode: EMPLOYEE_TYPE_CODE.REGULAR,
  isMan: false,
  isTimeNegotiation: false,
  isWeekNegotiation: false,
  negotiationAvailable: false,
  pay: 0,
  payTypeCode: PAY_TYPE_CODE.TIME,
  recruitChannelTypeCode: RECRUIT_CHANNEL_TYPE_CODE.CORPORATE_LECTURE,
  shopName: "",
  times: [],
  title: "",
  weeks: [],
  workshopUrl: "",
};
