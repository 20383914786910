import { AxiosResponse } from "axios";
import { useQueries } from "react-query";

import { getGroupCode } from "apis/commonCode";
import { GROUP_CODE } from "apis/typeCodes";
import { IGetGroupCodeResp, IGroupCodes } from "apis/commonCode.type";
import { useMemo } from "react";

export const useGroupCode = (codes: GROUP_CODE[]) => {
  const queries = useQueries(
    codes.map((code) => ({
      staleTime: Infinity,
      queryFn: () => getGroupCode(code),
      queryKey: ["@codes", "getGroupCode", code],
      select: ({ data }: AxiosResponse<IGetGroupCodeResp>) => ({
        code,
        groupCodeList: data.groupCodeList?.filter(({ isUse }) => isUse),
      }),
    }))
  );

  const isLoading = queries.some(({ isLoading }) => isLoading);
  const isFetching = queries.some(({ isFetching }) => isFetching);
  const data = useMemo(
    () => queries.map(({ data }) => data) as IGroupCodes[],
    [queries]
  );
  return { data, isLoading: isLoading || isFetching };
};
