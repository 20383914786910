import { forwardRef } from "react";
import { SnackbarContent } from "notistack";
import { Alert, AlertColor, Typography } from "@mui/material";

import { textStyles } from "styles";
import { Info } from "@mui/icons-material";

export interface CustomSnackbarProps {
  id: string;
  title?: string;
  message: string;
  severity: AlertColor;
}

function CustomSnackbar(
  props: CustomSnackbarProps,
  ref: React.Ref<HTMLInputElement>
) {
  const { title, message, severity, ...other } = props;
  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <Alert
        variant="filled"
        severity={severity}
        icon={<Info fontSize="inherit" />}
        sx={{ width: "100%", borderRadius: "12px" }}
      >
        <Typography {...textStyles.web.header5}>{title}</Typography>
        <Typography {...textStyles.web.caption1}>{message}</Typography>
      </Alert>
    </SnackbarContent>
  );
}

export default forwardRef(CustomSnackbar);
