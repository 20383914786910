import { useCallback, useState } from "react";
import { Matrix, CellBase, createEmptyMatrix } from "react-spreadsheet";
import { useCustomSnackbar } from "./useCustomSnackbar";

type StringCell = CellBase<string | undefined>;

interface ColumnsItems {
  id: string;
  label: string;
  type?: string;
}

interface Args {
  initRowLength?: number;
  columns: ColumnsItems[];
  requiredColumns?: ColumnsItems[];
}

/**
 * - Spreadsheet 컴포넌트와 함께 사용되는 hoook
 */
export const useSpreadsheet = ({ initRowLength = 1, columns = [], requiredColumns = [] }: Args) => {
  const snackbar = useCustomSnackbar();
  const [spreadSheetData, setSpreadSheetData] = useState<Matrix<CellBase<any>>>(
    createEmptyMatrix<StringCell>(initRowLength, columns.length)
  );

  const onConvert = useCallback(() => {
    return spreadSheetData.map((row) => {
      return columns.reduce<Record<string, string | number | null>>(
        (acc, cur, idx) => ({
          ...acc,
          [cur.id]: row[idx]?.value || (cur?.type === "number" ? null : ""),
        }),
        {}
      );
    });
  }, [spreadSheetData, columns]);

  const resetSpreadsheetData = () => {
    setSpreadSheetData(createEmptyMatrix<StringCell>(initRowLength, columns.length));
  };

  const onRequiredValidate = useCallback(() => {
    if (!requiredColumns.length) return;

    const convertData: any = onConvert();

    for (let i = 0; i < requiredColumns.length; i++) {
      const { id, label } = requiredColumns[i];

      for (let j = 0; j < convertData.length; j++) {
        const row = convertData[j];

        if (!row[id]) {
          snackbar({
            severity: "error",
            message: `[${label}] 항목 ${j + 1}번째 줄 항목값이 없습니다.`,
          });

          return false;
        }
      }
    }

    return true;
  }, [onConvert]);

  return {
    spreadSheetData,
    setSpreadSheetData,
    onConvert,
    onRequiredValidate,
    resetSpreadsheetData,
  };
};
