import { apiGateway } from "./apiGateway";

export const setFilesImage = (file: File) => {
  const formData = new FormData();
  formData.append("name", file.name);
  formData.append("image", file);

  return apiGateway.post("/files/image", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteFilesImage = (imageId: string) => {
  return apiGateway.delete(`/files/image/${imageId}`);
};
