import { getAllAgreeCenter, getDetailAgreeCenter } from "apis/agreeCenter";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useEmitter } from "./useEmitter";

export const useAgreeCenter = () => {
  const emitter = useEmitter();
  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("type") ?? "";
  const value = searchParams.get("value") ?? "";

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["@AllAgreeCenter", "getAllAgreeCenter", type, value],
    () => getAllAgreeCenter({ type, value })
  );

  useEffect(() => {
    emitter.on("AllAgreeList", refetch);
    return () => {
      emitter.off("AllAgreeList");
    };
  }, []);

  const onSearch = (params: any) => {
    const searchQuery = `type=${params.searchTypeCode}&value=${params.searchName}`;
    setSearchParams(searchQuery, { replace: !!value });
  };
  return {
    data,
    loading: isLoading || isFetching,
    refetch: refetch,
    search: {
      isSearched: !!value,
      onSearch,
    },
  };
};

export const useDetailAgreeCenter = (id: string) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ["@DetailAgreeCenter", "getDetailAgreeCenter", id],
    () => getDetailAgreeCenter(id)
  );
  return {
    data,
    isLoading,
    isFetching,
    refetch,
  };
};
