import { apiGateway } from "./apiGateway";
import { ISetPatchBannerReq, ISetPatchPopupReq } from "./banner.type";

export const getBannerList = () => {
  return apiGateway.get("/banner");
};

export const setPatchBanner = (req: ISetPatchBannerReq) => {
  return apiGateway.patch("/banner", req);
};

export const getWorkshopList = (page: number) => {
  return apiGateway.get("/recruit/posts/003/002", {
    params: { page },
  });
};

export const getPastAllBanner = () => {
  return apiGateway.get(`/banner/history/004?page=1&size=10`);
};

export const getPastLectureBanner = () => {
  return apiGateway.get(`/banner/history/001?page=1&size=10`);
};

export const getPastWorkShopBanner = () => {
  return apiGateway.get(`/banner/history/002?page=1&size=10`);
};

export const getPopupList = () => {
  return apiGateway.get("/popup");
};

export const getPopupHistoryList = () => {
  return apiGateway.get("/popup/history");
};

export const setPatchPopup = (req: ISetPatchPopupReq) => {
  return apiGateway.patch("/popup", req);
};
