import { UseFormReturn } from "react-hook-form";
import { IGroupCodes } from "./commonCode.type";

import { ISetFilesImageResp } from "./files.type";
import {
  DUTY_TYPE_CODE,
  EMPLOYEE_TYPE_CODE,
  PAY_TYPE_CODE,
  RECRUIT_CHANNEL_TYPE_CODE,
  SEARCH_TYPE_CODE,
  SPECIAL_LECTURE_TYPE_CODE,
} from "./typeCodes";

export interface IGetRecruitResumesResponse {
  resums: IRecruitResumesManage[];
  resumsCount: number;
}

export interface IRecruitResumesManage {
  address: string;
  applicationAt: string;
  applicationId: string;
  applicationTypeCode: string;
  birthDate: string;
  comment: string;
  email: string;
  name: string;
  phone: string;
  profileImageUrl: string;
  resumeId: string;
  viewed: boolean;
  resumeType: string;
  postId?: string;
}

export const recruitPostsPageSize = 50;
export const recruitPostsDefaultReq = {
  page: 1,
  size: recruitPostsPageSize,
  searchName: "",
  searchTypeCode: SEARCH_TYPE_CODE.INSTITUTION_NAME,
  recruitChannelTypeCode: RECRUIT_CHANNEL_TYPE_CODE.ALL,
};

export interface ISetRecruitPostsReq {
  page?: number;
  size?: number;
  searchName?: string;
  searchTypeCode?: SEARCH_TYPE_CODE;
  recruitChannelTypeCode?: RECRUIT_CHANNEL_TYPE_CODE;
}

export interface ISetRecruitPostsResp {
  finishPosts?: IListRecruitPostsResp;
  posts?: IListRecruitPostsResp;
  reviewPosts?: IListRecruitPostsResp;
}

export interface IListRecruitPostsResp {
  count: number;
  hasNext: boolean;
  posts?: IRecruitPostSummary[];
}

export interface IRecruitPostSummary extends ISetCreateRecruitPostReq {
  id: string;
  postId: string;
  postToken: string;
  createdAt: string;
  centerMainImage?: string;
  dutyTypeCodeName?: string;
  employTypeCodeName?: string;
  finished?: boolean;
  inReview?: boolean;
  payTypeCodeName?: string;
  pulldragAt?: string;
  specialLectureTypeCodeName?: string;
  startAt?: string;
  supportCount?: number;
  viewCount?: number;
  workshopUrl?: string;
  reservationAlarmCount?: number;
}

export interface IRecruitPostDetailReq {
  postId: string;
  postToken: string;
}

export interface ISetCreateRecruitPostReq {
  address: string;
  additionalInformation1?: string;
  additionalInformation2?: string;
  beginnerOk?: boolean;
  centerDetailImages?: Pick<ISetFilesImageResp, "imageId">[];
  centerImages?: Pick<ISetFilesImageResp, "imageId">[];
  companyImages?: Pick<ISetFilesImageResp, "imageId">[];
  companyUserEmail: string;
  companyUserPassword?: string;
  contact1: string;
  contact2?: string;
  detail: string;
  detailAddress: string;
  due: string;
  dutyTypeCode: DUTY_TYPE_CODE;
  employTypeCode: EMPLOYEE_TYPE_CODE;
  isMan?: boolean;
  isBound?: boolean;
  isNewCompanyAccount?: boolean;
  isTimeNegotiation?: boolean;
  isWeekNegotiation?: boolean;
  negotiationAvailable?: boolean;
  // pay 타입을 문자열로 지정하고 parseInt나 Number로 데이터 수정
  pay: string;
  payTypeCode: PAY_TYPE_CODE;
  recruitChannelTypeCode: RECRUIT_CHANNEL_TYPE_CODE;
  shopName: string;
  specialLectureTypeCode?: SPECIAL_LECTURE_TYPE_CODE;
  startAt?: string;
  times?: string[];
  title: string;
  weeks?: string[];
  workshopUrl?: string;
}

export interface ISetCreateRecruitPostResp {
  id: string;
  token: string;
  postUrl: string;
  email?: string;
}

export interface ISetPatchRecruitPostReq extends ISetCreateRecruitPostReq {
  postId: string;
}

export interface IRecruitPostForm
  extends Omit<ISetCreateRecruitPostReq, "due" | "startAt"> {
  dueYear: number;
  dueMonth: number;
  dueDay: number;
  startYear: number;
  startMonth: number;
  startDay: number;
  centerDetailImages?: ISetFilesImageResp[];
  centerImages?: ISetFilesImageResp[];
  companyImages?: ISetFilesImageResp[];
}

export interface IRecruitPostUseForm
  extends Omit<UseFormReturn<IRecruitPostForm>, "handleSubmit" | "reset"> {
  groupCodes?: IGroupCodes[];
}

export interface RejectPostProps {
  postId: string | undefined;
  description: string;
}
