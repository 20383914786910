import { Layout } from "components";

import { useGetPopupHistory, useGetPopupList } from "hooks/useBanner";

import PopupForm from "./PopupForm";

const PopupList = () => {
  const fetchGetPopupList = useGetPopupList();
  const fetchGetPopupHistory = useGetPopupHistory();

  const isLoading =
    fetchGetPopupList.isFetching || fetchGetPopupHistory.isFetching;
  const popupList = fetchGetPopupList.data;
  const popupHistoryList = fetchGetPopupHistory.data;

  const DisplayForm = () => {
    if (isLoading || !popupList) return null;
    return (
      <PopupForm
        defaultValues={popupList}
        popupHistoryList={popupHistoryList}
      />
    );
  };

  return (
    <Layout isLoading={isLoading}>
      <DisplayForm />
    </Layout>
  );
};

export default PopupList;
