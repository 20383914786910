import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { CustomAlert } from "components";
import { find } from "lodash";
import { Controller } from "react-hook-form";

import { ICareerUseForm } from "apis/career.type";
import { GROUP_CODE } from "apis/typeCodes";
import { ImageUploader } from "components";
import { useEffect, useState } from "react";

const CareerFormPresenter = ({
  groupCodes,
  watch,
  control,
  register,
  setValue,
  formState: { errors },
}: ICareerUseForm) => {
  // codes
  const dutyCodes = find(groupCodes, { code: GROUP_CODE.DUTY });
  const [A, setA] = useState(false);
  // states
  // const watchAddress = watch("address");
  // const watchCompanyImages = watch("companyImages");
  const watchCenterDetailImages = watch("images");
  // const centerImages = Array.from({ length: 6 });
  // const watchCenterImages = watch("centerImages");

  useEffect(() => {
    if (typeof watchCenterDetailImages == "object") {
      setA(true);
    } else {
      setA(false);
    }
  }, []);

  useEffect(() => {
    if (watchCenterDetailImages == "") {
      setA(false);
    }
  }, [watchCenterDetailImages]);

  if (!dutyCodes) return null;
  return (
    <Paper
      sx={{
        py: 3,
        px: 2.5,
        borderRadius: 6,
      }}
    >
      <Stack spacing="32px">
        <FormControl fullWidth>
          <FormLabel required>피드 종류</FormLabel>
          <Controller
            name="feedTypeCode"
            control={control}
            render={({ field }) => (
              <RadioGroup row {...field}>
                {dutyCodes?.groupCodeList?.map(({ code, codeName }) =>
                  code != "004" ? (
                    <FormControlLabel
                      key={code}
                      value={code}
                      control={<Radio />}
                      label={codeName}
                    />
                  ) : (
                    <></>
                  )
                )}
              </RadioGroup>
            )}
          />
        </FormControl>

        <Divider />
        <FormControl fullWidth>
          <FormLabel required>해시 태그</FormLabel>
          <TextField
            fullWidth
            placeholder="해시태그"
            error={!!errors.hashTag}
            helperText={errors.hashTag?.message}
            {...register("hashTag", { required: "해시태그를 입력해주세요!" })}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel required>피드 URL</FormLabel>
          <TextField
            fullWidth
            placeholder="ex)www.bounders.info"
            error={!!errors.targetUrl}
            helperText={errors.targetUrl?.message}
            {...register("targetUrl", { required: "피드URL을 입력해주세요!" })}
          />
        </FormControl>
        <FormControl fullWidth>
          <FormLabel required>출처(원작자)</FormLabel>
          <TextField
            fullWidth
            placeholder="출처를 입력해주세요"
            error={!!errors.sourceName}
            helperText={errors.sourceName?.message}
            {...register("sourceName", { required: "출처를 입력해주세요!" })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>게시일</FormLabel>
          <Stack direction={"row"} gap={1}>
            <TextField
              fullWidth
              type="number"
              placeholder="년 (4자)"
              error={!!errors.startYear}
              helperText={errors.startYear?.message}
              {...register("startYear", {
                required: "모집 시작년도를 입력해주세요",
                min: {
                  value: 0,
                  message: "유효한 모집 시작년도를 입력해주세요",
                },
              })}
            />
            <TextField
              fullWidth
              type="number"
              placeholder="월"
              error={!!errors.startMonth}
              helperText={errors.startMonth?.message}
              {...register("startMonth", {
                required: "모집 시작월을 입력해주세요",
                min: {
                  value: 1,
                  message: "*유효한 모집 시작월을 입력해주세요",
                },
                max: {
                  value: 12,
                  message: "*유효한 모집 시작월을 입력해주세요",
                },
              })}
            />
            <TextField
              fullWidth
              type="number"
              placeholder="일"
              error={!!errors.startDay}
              helperText={errors.startDay?.message}
              {...register("startDay", {
                required: "모집 시작일을 입력해주세요",
                min: { value: 1, message: "유효한 모집 시작일을 입력해주세요" },
                max: {
                  value: 31,
                  message: "유효한 모집 시작일을 입력해주세요",
                },
              })}
            />
          </Stack>
        </FormControl>
        <FormControl fullWidth>
          <FormLabel required>제목</FormLabel>
          <TextField
            fullWidth
            placeholder="제목 입력"
            error={!!errors.title}
            helperText={errors.title?.message}
            {...register("title", { required: "제목을 입력해주세요" })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>상세 정보</FormLabel>
          <TextField
            fullWidth
            multiline
            minRows={1}
            error={!!errors.description}
            helperText={errors.description?.message}
            placeholder="상세 정보 입력"
            {...register("description", {
              required: "상세 정보를 입력해주세요",
            })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>이미지로 상세 정보 채우기</FormLabel>
          <Stack direction="row" gap={1} sx={{ width: "100%" }}>
            <Box sx={{ width: "40%" }}>
              <ImageUploader
                id="detail-image"
                height={240}
                value={A ? watchCenterDetailImages[0] : watchCenterDetailImages}
                onChange={(value) => setValue("images", value)}
                onDelete={() => setValue("images", "")}
              />
            </Box>
            <Box sx={{ width: "60%" }}>
              <CustomAlert descriptions={["권장 이미지 사이즈: 670x410 (18:11 비율)"]} />
            </Box>
          </Stack>
        </FormControl>
      </Stack>
    </Paper>
  );
};

export default CareerFormPresenter;
