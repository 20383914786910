import {
  Box,
  Card,
  Container,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { GridTitle, Layout } from "components";
import AgreementCenterHeader from "components/AgreeCenter/AgreementCenterHeader";
import Chip from "components/Chip";
import { format } from "date-fns";
import { useDetailAgreeCenter } from "hooks/useAgreeCenter";
import { useParams } from "react-router-dom";
import { colors, textStyles } from "styles";
import { stateStyles } from "../AgreementChipStyle";

interface Props {
  agreementStatus: "AGREE" | "DISAGREE" | "FOLLOW_UP" | "MISSED" | "CLAIM" | "ORGANIC" | "ETC";
  description: string;
  loggedAt: string;
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: colors.bluegray100,
    ...textStyles.web.header4,
  },
  [`&.${tableCellClasses.body}`]: {
    ...textStyles.web.body1,
    borderBottom: colors.bluegray100,
  },
}));

export const AgreementCenterDetail = () => {
  const param = useParams();

  const { data } = useDetailAgreeCenter(param?.id || "");
  const stateTexts = {
    AGREE: "동의",
    DISAGREE: "비동의",
    CLAIM: "클레임",
    MISSED: "부재중",
    FOLLOW_UP: "팔로업",
    ORGANIC: "기타",
    ETC: "기타",
  };
  if (!data) {
    return null;
  }
  return (
    <Layout>
      <AgreementCenterHeader
        serviceTitle="동의 센터 상세"
        onSearch={() => console.log("notSearch")}
        detail={true}
      />
      <Container disableGutters maxWidth="xl" sx={{ padding: "32px 24px" }}>
        <Grid container flexWrap="nowrap">
          <Grid item width={498} sx={{ padding: "0 24px" }}>
            <GridTitle title="센터 정보" {...textStyles.web.header2} />
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                background: "white",
                padding: "24px 20px",
                borderRadius: "24px",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  padding: "16px",
                  backgroundColor: `${colors.bluegray25}`,
                  flexDirection: "column",
                  gap: "4px",
                  borderRadius: "8px",
                }}
              >
                <Box {...textStyles.web.header6} sx={{ color: colors.bluegray400 }}>
                  센터명
                </Box>
                <Box {...textStyles.web.body1}>{data.shopName}</Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  padding: "16px",
                  backgroundColor: `${colors.bluegray25}`,
                  flexDirection: "column",
                  gap: "4px",
                  borderRadius: "8px",
                }}
              >
                <Box {...textStyles.web.header6} sx={{ color: colors.bluegray400 }}>
                  연락처
                </Box>
                <Box {...textStyles.web.body1}>
                  {data.phoneNumber.replace(
                    /(02)(\d{3,4})(\d{4})|(\d{3})(\d{4})(\d{4})|(\d{3})(\d{3})(\d{4})/,
                    (
                      match: any,
                      p1: string,
                      p2: string,
                      p3: string,
                      p4: string,
                      p5: string,
                      p6: string,
                      p7: string,
                      p8: string,
                      p9: string
                    ) => {
                      return p1
                        ? p1 + "-" + p2 + "-" + p3
                        : p4
                        ? p4 + "-" + p5 + "-" + p6
                        : p7
                        ? p7 + "-" + p8 + "-" + p9
                        : "";
                    }
                  )}
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs sx={{ padding: "0 24px" }}>
            <GridTitle title="히스토리" />
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                background: "white",
                padding: "24px 20px",
                borderRadius: "24px",
                gap: "16px",
              }}
            >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead sx={{ borderBottom: `1px solid ${colors.bluegray100}` }}>
                    <TableRow>
                      <StyledTableCell sx={{ width: "17%" }}>일자</StyledTableCell>
                      <StyledTableCell sx={{ width: "13%" }}>상태</StyledTableCell>
                      <StyledTableCell sx={{ width: "75%" }}>비고</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.histories.map((date: Props) => (
                      <TableRow
                        key={date.loggedAt}
                        sx={{ borderBottom: `1px solid ${colors.bluegray100}` }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {format(new Date(date.loggedAt), "yyyy.MM.dd")}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Chip
                            state={data.agreementStatus}
                            content={stateTexts[date.agreementStatus]}
                            textStyle={textStyles.web.subhead5}
                            stateStyle={stateStyles[date.agreementStatus]}
                          />
                        </StyledTableCell>
                        <StyledTableCell>{date.description}</StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};
