import { Backdrop, CircularProgress } from "@mui/material";

interface Props {
  open?: boolean;
}

const CircleLoading = ({ open }: Props) => (
  <Backdrop
    open={open ?? false}
    sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }}
  >
    <CircularProgress color="primary" />
  </Backdrop>
);

export default CircleLoading;
