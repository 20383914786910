import { apiGateway } from "./apiGateway";
import {
  IRecruitPostDetailReq,
  ISetCreateRecruitPostReq,
  ISetPatchRecruitPostReq,
  ISetRecruitPostsReq,
  recruitPostsDefaultReq,
  RejectPostProps,
} from "./recruitPost.type";

// 채용 공고 지원자 목록
export const getRecruitPostResumes = (postId: string) => {
  return apiGateway.get(`/recruit/${postId}/resumes`);
};

// 채용 목록
export const setRecruitPosts = (req?: ISetRecruitPostsReq) => {
  const request: ISetRecruitPostsReq = {
    ...recruitPostsDefaultReq,
    ...req,
  };
  return apiGateway.post("/recruit/posts", request);
};

// 채용 조회 상세
export const getRecruitPostDetail = (req: IRecruitPostDetailReq) => {
  return apiGateway.get(
    `/recruit/posts/${req.postId}?postToken=${req.postToken}&disable-count=true`
  );
};

// 채용 삭제하기
export const deleteRecruitPost = (req: IRecruitPostDetailReq) => {
  return apiGateway.delete(
    `/recruit/posts/${req.postId}?postToken=${req.postToken}`
  );
};

// 채용 수정하기
export const setPatchRecruitPost = (req: ISetPatchRecruitPostReq) => {
  // 수정시 만약 pay의 타입값이 숫자로 올땐 req로 전달
  if (typeof req.pay === "number") {
    return apiGateway.patch(`/recruit/posts/${req.postId}`, req);
  }
  // pay의 문자열로 올시 ,제거후 숫자로 서버에 전달
  else {
    const payChange = parseInt(req.pay.split(",").join(""));
    return apiGateway.patch(`/recruit/posts/${req.postId}`, {
      ...req,
      pay: payChange,
    });
  }
};

// 채용 마감하기
export const setFinishRecruitPost = (req: IRecruitPostDetailReq) => {
  return apiGateway.post(`/recruit/posts/${req.postId}/finish`, {
    postToken: req.postToken,
  });
};

// 채용 생성
export const setCreateRecruitPost = (req: ISetCreateRecruitPostReq) => {
  const payChange = parseInt(req.pay.split(",").join(""));
  return apiGateway.post("/recruit/posts/create", { ...req, pay: payChange });
};

// 채용 검토 승인
export const setReviewRecruitPost = (postId: string) => {
  return apiGateway.patch(`/recruit/review/${postId}`);
};

// 채용 검토 승인(전체, 개별)
export const setTotalFewReviewRecruitPost = (postId: string[]) => {
  return apiGateway.patch("recruit/review", { postIds: postId });
};

// 채용 반려
export const setRejectRecruitPost = (req: RejectPostProps) => {
  return apiGateway.patch("recruit/review:returning", req);
};

// 동의 업체 대량 업로드
export const postBluk = (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  return apiGateway.post("recruit/bulk", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
