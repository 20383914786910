import { ComponentClass } from "react";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableElementProps,
  SortEndHandler,
} from "react-sortable-hoc";
import { Stack } from "@mui/material";

interface Props<T> {
  list: T[];
  gap?: number;
  isSortIcon?: boolean;
  getSortId: (item: T) => string;
  getRenderItem: (item: T) => JSX.Element;
  onSort: (oldIndex: number, newIndex: number) => void;
}

const SortableList = <T,>({
  gap,
  list,
  isSortIcon = false,
  getSortId,
  getRenderItem,
  onSort,
}: Props<T>) => {
  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    return onSort(oldIndex, newIndex);
  };

  const SortableItem: ComponentClass<SortableElementProps & { value: T }> =
    SortableElement(({ value }: any) => getRenderItem(value));

  const SortableListContainer: ComponentClass<
    SortableContainerProps & { items: T[] }
  > = SortableContainer(({ items }: any) => (
    <Stack gap={gap || 1.5}>
      {items.map((value: T, index: number) => {
        const newValue = { ...value, index };
        return (
          <SortableItem key={getSortId(value)} index={index} value={newValue} />
        );
      })}
    </Stack>
  ));

  return (
    <SortableListContainer
      items={list}
      onSortEnd={onSortEnd}
      shouldCancelStart={(e: any) => {
        if (e.target?.id.includes("sort-icon")) return false;
        return isSortIcon;
      }}
    />
  );
};

export default SortableList;
