import cookie from "react-cookies";
import { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useSetRecoilState } from "recoil";

import { setSignIn } from "apis/account";
import { ISetSignInResp } from "apis/account.type";
import { accessTokenSelector } from "store/accountState";
import { useCustomSnackbar } from "hooks";

import LoginPresenter from "./LoginPresenter";
import { useEffect } from "react";

const Login = () => {
  const clientQuery = useQueryClient();
  const snackbar = useCustomSnackbar();
  const setAccessToken = useSetRecoilState(accessTokenSelector);

  useEffect(() => {
    clientQuery.invalidateQueries();
  }, []);

  const { mutate } = useMutation(["@common/setSignIn"], setSignIn, {
    onSuccess: (
      { data: { accessToken, refreshToken } }: AxiosResponse<ISetSignInResp>,
      { email }
    ) => {
      sessionStorage.setItem("USER_EMAIL", email);
      const httpOnly = process.env.NODE_ENV === "production";
      cookie.save("REFRESH_TOKEN", refreshToken, { httpOnly });

      setAccessToken(accessToken);
    },
    onError: (error: any) => {
      const severity = "error";
      const message = error?.response?.data?.message || error?.message;

      if (message === "wrong account") {
        snackbar({
          severity,
          title: "이용할 수 없는 계정이에요.",
          message: "확인 후 다시 시도해 주세요.",
        });
      } else {
        return snackbar({ severity, message });
      }
    },
  });

  return <LoginPresenter onLogin={mutate} />;
};

export default Login;
