import { ISetSignInReq } from "./account.type";
import { apiGateway } from "./apiGateway";

export const setSignIn = (req: ISetSignInReq) => {
  return apiGateway.post("/account/signin", req);
};

export const setRefreshToken = (refreshToken: string) => {
  return apiGateway.post("/account/refresh-token", { refreshToken });
};
