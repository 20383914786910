import { Layout } from "components";

import { useGetBannerList } from "hooks/useBanner";

import BannerForm from "./BannerForm";

const BannerList = () => {
  const { isFetching, data, refetch } = useGetBannerList();

  const DisplayForm = () => {
    if (isFetching || !data) return null;
    return <BannerForm defaultValues={data} refetch={refetch} />;
  };

  return (
    <Layout isLoading={isFetching}>
      <DisplayForm />
    </Layout>
  );
};

export default BannerList;
