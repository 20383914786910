import { useRecoilValue } from "recoil";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";

import Login from "pages/Login";
import { accessTokenSelector } from "store";
import { RefreshToken } from "components";

import { TalentList, TalentForm } from "./Talent";
import { LectureList, LectureForm } from "./Lecture";
import { WorkshopList, WorkshopForm } from "./Workshop";
import { BannerList } from "./Banner";
import { PopupList } from "./Popup";
import { CareerForm, CareerList } from "./Career";
import { CenterCertificationList } from "./CenterCertification";
import { AgreementCenter } from "./AgreementCenter";
import { AgreementCenterDetail } from "./AgreementCenter/Detail";

function PageContainer() {
  const accessToken = useRecoilValue(accessTokenSelector);
  if (!accessToken) return <Login />;

  return (
    <HashRouter>
      <RefreshToken />
      <Routes>
        <Route path="/" element={<Navigate to="/talent" replace />} />
        <Route path="/talent" element={<TalentList />} />
        <Route path="/talent/create" element={<TalentForm isCreate />} />
        <Route path="/talent/:postId/:postToken" element={<TalentForm />} />
        <Route path="/lecture" element={<LectureList />} />
        <Route path="/lecture/create" element={<LectureForm isCreate />} />
        <Route path="/lecture/:postId/:postToken" element={<LectureForm />} />
        <Route path="/workshop" element={<WorkshopList />} />
        <Route path="/workshop/create" element={<WorkshopForm isCreate />} />
        <Route path="/workshop/:postId/:postToken" element={<WorkshopForm />} />
        <Route path="/banner" element={<BannerList />} />
        <Route path="/popup" element={<PopupList />} />
        {/* 라이프 뷰 */}
        <Route path="/career" element={<CareerList />} />
        {/* 라이프 생성 */}
        <Route path="/career/create" element={<CareerForm isCreate />} />
        {/* 라이프 수정 */}
        <Route path="/career/:id" element={<CareerForm />} />
        <Route path="/centerCertification" element={<CenterCertificationList />} />
        {/* 동의 업체 */}
        <Route path="/agreementCenter" element={<AgreementCenter />} />
        <Route path="/agreementCenter/detail/:id" element={<AgreementCenterDetail />} />
      </Routes>
    </HashRouter>
  );
}

export default PageContainer;
