import { filter } from "lodash";
import { Card, CardContent, IconButton } from "@mui/material";
import { Close, Dehaze } from "@mui/icons-material";

import { PostCard } from "components";
import { formatDateKo } from "utils/formatter";

import { IThemaResponseOf } from "apis/banner.type";

interface Props {
  post: IThemaResponseOf;
  onDeleteIndex: () => void;
}

const CarouselItem = ({ post, onDeleteIndex }: Props) => {
  const {
    address,
    createdAt,
    imageUrl,
    detailAddress,
    pay,
    startAt,
    specialCodeName,
    title,
  } = post;

  // 공고 요약
  const summaries = () => {
    const createdLabel = () => {
      const prevFormat = "게시: ";
      return formatDateKo({ date: createdAt, prevFormat });
    };

    return filter([specialCodeName, createdLabel()]).join(" • ");
  };

  const locationLabel = filter([address, detailAddress]).join(" ");

  // 모집시작, 마감
  const dateLabel = formatDateKo({
    date: startAt,
    prevFormat: "시작일: ",
    format: "M/d(eee)",
  });

  return (
    <Card sx={{ borderRadius: 4 }}>
      <CardContent
        id="sort-icon"
        sx={{
          px: 7.5,
          position: "relative",
        }}
      >
        <Dehaze
          id="sort-icon"
          fontSize="small"
          sx={{
            left: 20,
            top: "50%",
            position: "absolute",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: "action.hover",
          }}
        />
        <PostCard
          title={title}
          summaries={summaries()}
          pay={`${pay?.toLocaleString()}원 부터`}
          location={locationLabel}
          datetime={dateLabel}
          centerMainImage={imageUrl}
          sx={{
            backgroundColor: "transparent",
            ".MuiCardContent-root": { p: 0 },
          }}
        />
        <IconButton
          size="small"
          onClick={onDeleteIndex}
          sx={{
            right: 20,
            top: "50%",
            position: "absolute",
            transform: "translateY(-50%)",
            color: "action.hover",
          }}
        >
          <Close />
        </IconButton>
      </CardContent>
    </Card>
  );
};

export default CarouselItem;
