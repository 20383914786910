export const colors = {
  white: '#FFFFFF',
  black: '#000000',

  // red
  red50: '#ffeded',
  red100: '#fedbdb',
  red150: '#fdc9c9',
  red200: '#fdb8b8',
  red300: '#fa9494',
  red400: '#f77070',
  red500: '#f24d4d',
  red600: '#eb2b2b',
  red700: '#de0a0a',
  red800: '#c30000',
  red900: '#8a0000',
  red1000: '#3f0000',

  // orange
  orange50: '#fff4ed',
  orange100: '#fee9db',
  orange150: '#fddec9',
  orange200: '#fdd3b8',
  orange300: '#fabd94',
  orange400: '#f7a670',
  orange500: '#f28f4d',
  orange600: '#eb782b',
  orange700: '#de5f0a',
  orange800: '#c34e00',
  orange900: '#8a3700',
  orange1000: '#3f1900',

  // yellow
  yellow50: '#fffaea',
  yellow100: '#fef6d4',
  yellow150: '#fdf1bf',
  yellow200: '#fdecaa',
  yellow300: '#fae27f',
  yellow400: '#f7d755',
  yellow500: '#f2cb2c',
  yellow600: '#ebbd04',
  yellow700: '#deb100',
  yellow800: '#c39c00',
  yellow900: '#8a6f00',
  yellow1000: '#3f3300',

  // neonyellow
  neonyellow50: '#fffeea',
  neonyellow100: '#fefdd4',
  neonyellow150: '#fdfbbf',
  neonyellow200: '#fdfaaa',
  neonyellow300: '#faf67f',
  neonyellow400: '#f7f255',
  neonyellow500: '#f2ec2c',
  neonyellow600: '#ebe304',
  neonyellow700: '#ded600',
  neonyellow800: '#c3bd00',
  neonyellow900: '#8a8600',
  neonyellow1000: '#3f3d00',

  // neongreen
  neongreen50: '#faffea',
  neongreen100: '#f6fed4',
  neongreen150: '#f1fdbf',
  neongreen200: '#ecfdaa',
  neongreen300: '#e2fa7f',
  neongreen400: '#d7f755',
  neongreen500: '#cbf22c',
  neongreen600: '#bdeb04',
  neongreen700: '#b1de00',
  neongreen800: '#9cc300',
  neongreen900: '#6f8a00',
  neongreen1000: '#333f00',

  // green
  green50: '#f4ffed',
  green100: '#e9fedb',
  green150: '#defdc9',
  green200: '#d3fdb8',
  green300: '#bdfa94',
  green400: '#a6f770',
  green500: '#8ff24d',
  green600: '#78eb2b',
  green700: '#5fde0a',
  green800: '#4ec300',
  green900: '#378a00',
  green1000: '#193f00',

  // turquoise
  turquoise50: '#edfffb',
  turquoise100: '#dbfef7',
  turquoise150: '#c9fdf3',
  turquoise200: '#b8fdef',
  turquoise300: '#94fae6',
  turquoise400: '#70f7dc',
  turquoise500: '#4df2d1',
  turquoise600: '#2bebc4',
  turquoise700: '#0adeb3',
  turquoise800: '#00c39c',
  turquoise900: '#008a6f',
  turquoise1000: '#003f33',

  // lightblue
  lightblue50: '#edf9ff',
  lightblue100: '#dbf2fe',
  lightblue150: '#c9ecfd',
  lightblue200: '#b8e6fd',
  lightblue300: '#94d8fa',
  lightblue400: '#70caf7',
  lightblue500: '#4dbbf2',
  lightblue600: '#2babeb',
  lightblue700: '#0a97de',
  lightblue800: '#0082c3',
  lightblue900: '#005c8a',
  lightblue1000: '#002a3f',

  // blue
  blue50: '#edf4ff',
  blue100: '#dbe9fe',
  blue150: '#c9defd',
  blue200: '#b8d3fd',
  blue300: '#94bdfa',
  blue400: '#70a6f7',
  blue500: '#4d8ff2',
  blue600: '#2b78eb',
  blue700: '#0a5fde',
  blue800: '#004ec3',
  blue900: '#00378a',
  blue1000: '#00193f',

  // indigo
  indigo50: '#edefff',
  indigo100: '#dbe0fe',
  indigo150: '#c9d0fd',
  indigo200: '#b8c1fd',
  indigo300: '#94a2fa',
  indigo400: '#7082f7',
  indigo500: '#4d63f2',
  indigo600: '#2b44eb',
  indigo700: '#0a26de',
  indigo800: '#001ac3',
  indigo900: '#00128a',
  indigo1000: '#00083f',

  // purple
  purple50: '#f5edff',
  purple100: '#ecdbfe',
  purple150: '#e2c9fd',
  purple200: '#d8b8fd',
  purple300: '#c494fa',
  purple400: '#af70f7',
  purple500: '#9a4df2',
  purple600: '#842beb',
  purple700: '#6d0ade',
  purple800: '#5b00c3',
  purple900: '#40008a',
  purple1000: '#1e003f',

  // pink
  pink50: '#ffedfb',
  pink100: '#fedbf7',
  pink150: '#fdc9f3',
  pink200: '#fdb8ef',
  pink300: '#fa94e6',
  pink400: '#f770dc',
  pink500: '#f24dd1',
  pink600: '#eb2bc4',
  pink700: '#de0ab3',
  pink800: '#c3009c',
  pink900: '#8a006f',
  pink1000: '#3f0033',

  // neonpink
  neonpink50: '#ffeaf2',
  neonpink100: '#fed4e5',
  neonpink150: '#fdbfd8',
  neonpink200: '#fdaacb',
  neonpink300: '#fa7fb1',
  neonpink400: '#f75596',
  neonpink500: '#f22c7b',
  neonpink600: '#eb0460',
  neonpink700: '#de0059',
  neonpink800: '#c3004e',
  neonpink900: '#8a0037',
  neonpink1000: '#3f0019',

  // darkyellow
  darkyellow50: '#f3eee8',
  darkyellow100: '#e8ddd3',
  darkyellow150: '#dcccbe',
  darkyellow200: '#d1bcab',
  darkyellow300: '#b99e87',
  darkyellow400: '#a28367',
  darkyellow500: '#8b694c',
  darkyellow600: '#745235',
  darkyellow700: '#5d3d22',
  darkyellow800: '#462b13',
  darkyellow900: '#2e1a08',
  darkyellow1000: '#170c02',

  // darkturquoise
  darkturquoise50: '#e8f3f1',
  darkturquoise100: '#d3e8e4',
  darkturquoise150: '#bedcd6',
  darkturquoise200: '#abd1c9',
  darkturquoise300: '#87b9af',
  darkturquoise400: '#67a296',
  darkturquoise500: '#4c8b7e',
  darkturquoise600: '#357467',
  darkturquoise700: '#225d51',
  darkturquoise800: '#13463b',
  darkturquoise900: '#082e27',
  darkturquoise1000: '#021713',

  // darkpink
  darkpink50: '#f3e8f1',
  darkpink100: '#e8d3e4',
  darkpink150: '#dcbed6',
  darkpink200: '#d1abc9',
  darkpink300: '#b987af',
  darkpink400: '#a26796',
  darkpink500: '#8b4c7e',
  darkpink600: '#743567',
  darkpink700: '#5d2251',
  darkpink800: '#46133b',
  darkpink900: '#2e0827',
  darkpink1000: '#170213',

  // darkblue
  darkblue50: '#e8edf3',
  darkblue100: '#d3dbe8',
  darkblue150: '#becadc',
  darkblue200: '#abbad1',
  darkblue300: '#879bb9',
  darkblue400: '#677fa2',
  darkblue500: '#4c658b',
  darkblue600: '#354e74',
  darkblue700: '#22395d',
  darkblue800: '#132746',
  darkblue900: '#08182e',
  darkblue1000: '#020b17',

  // bluegray
  bluegray25: '#F6F7F8',
  bluegray50: '#f0f1f3',
  bluegray100: '#e0e3e8',
  bluegray150: '#d2d6dc',
  bluegray200: '#c3c9d1',
  bluegray300: '#a8afb9',
  bluegray400: '#8e96a2',
  bluegray500: '#757e8b',
  bluegray600: '#5e6774',
  bluegray700: '#48505d',
  bluegray800: '#343b46',
  bluegray900: '#21262e',
  bluegray1000: '#101317',

  // neutral
  neutral50: '#f3f3f3',
  neutral100: '#e8e8e8',
  neutral150: '#dcdcdc',
  neutral200: '#d1d1d1',
  neutral300: '#b9b9b9',
  neutral400: '#a2a2a2',
  neutral500: '#8b8b8b',
  neutral600: '#747474',
  neutral700: '#5d5d5d',
  neutral800: '#464646',
  neutral900: '#2e2e2e',
  neutral1000: '#171717',
} as const;

export type colorType = keyof typeof colors;