import { ArrowBack } from "@mui/icons-material";
import { IconButton, Paper, Stack, Typography } from "@mui/material";

import { colors, textStyles } from "styles";

interface Props {
  title: string;
  onBack: () => void;
}
const PostFormHeader = ({ title, onBack }: Props) => {
  return (
    <Paper sx={{ px: 3, py: 2 }}>
      <Stack direction="row" spacing={1.5} alignItems="center" mb={0.5}>
        <IconButton color="primary" onClick={onBack}>
          <ArrowBack />
        </IconButton>

        <Typography sx={{ ...textStyles.web.title1 }}>{title}</Typography>

        <Typography sx={{ ...textStyles.web.subhead3, color: colors.red600 }}>
          * 필수 항목
        </Typography>
      </Stack>
    </Paper>
  );
};

export default PostFormHeader;
