import { useState } from "react";
import { AxiosResponse } from "axios";
import { useFieldArray } from "react-hook-form";
import { Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";

import { IBannerResponse, IGetBannerListRespForm } from "apis/banner.type";
import { ISetFilesImageResp } from "apis/files.type";
import { CustomAlert, SortableList } from "components";
import { useImageUploader } from "hooks";

import { createBannerDefaultValue } from "../type";
import BannerListItem from "./BannerListItem";
import DeleteBannerDialog from "./DeleteBannerDialog";

type TBannerSortableList = IBannerResponse & { index?: number };
const BannerSortableList = SortableList<TBannerSortableList>;

interface Props extends IGetBannerListRespForm {
  listName: "companyBannerList" | "workshopBannerList" | "recruitBannerList";
}

const BannerList = ({ listName, control, register }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState("");
  const { fields, prepend, swap, update } = useFieldArray({
    control,
    name: listName,
  });

  const inputId = `${listName}-input`;
  const { isLoading, fetchUploadImage } = useImageUploader();

  const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    fetchUploadImage(file).then(
      ({ data }: AxiosResponse<ISetFilesImageResp>) => {
        const createdAt = String(new Date().getTime());
        prepend({ ...createBannerDefaultValue, ...data, createdAt });
      }
    );
  };

  return (
    <Stack gap={1.5}>
      <DeleteBannerDialog
        isOpen={!!selectedIndex}
        onClose={() => setSelectedIndex("")}
        onDelete={() => {
          const targetBanner = fields[Number(selectedIndex)];
          update(Number(selectedIndex), { ...targetBanner, isDel: true });
          setSelectedIndex("");
        }}
      />

      <CustomAlert
        descriptions={[
          "권장 이미지 사이즈: 1000x500 (2:1 비율)",
          "URL 미입력시 클릭 불가",
        ]}
      />

      <Box
        id={inputId}
        type="file"
        accept="image/*"
        component="input"
        onChange={onUploadImage}
        sx={{ display: "none" }}
      />

      <LoadingButton
        fullWidth
        component="label"
        variant="outlined"
        htmlFor={inputId}
        loading={isLoading}
        startIcon={<Add />}
      >
        이미지 가져오기
      </LoadingButton>

      <BannerSortableList
        isSortIcon
        gap={1.5}
        list={fields}
        onSort={swap}
        getSortId={(item) => item.imageId}
        getRenderItem={(item) => {
          return (
            <BannerListItem
              register={register}
              control={control}
              listName={listName}
              onDelete={setSelectedIndex}
              {...item}
            />
          );
        }}
      />
    </Stack>
  );
};

export default BannerList;
