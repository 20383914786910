import { Fragment } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";

import { colors, textStyles } from "styles";
import { IBannerResponse } from "apis/banner.type";
import { formatDateKo } from "utils/formatter";
import { GridTitle } from "components";

interface Props extends IBannerResponse {
  sx?: SxProps;
}

const PopupHistoryCard = (props: Props) => {
  const {
    isDel,
    isDisplay,
    createdAt,
    deleteAt,
    imageUrl,
    imageId,
    targetUrl,
    displayPositionTypeCodeName,
    exposureTargetTypeCodeName,
    displayCount,
    displayClickCount,
    clickRate,
    sx,
  } = props;

  const StatusLabel = () => {
    if (isDel) {
      return (
        <Typography
          sx={{ ...textStyles.web.subhead3, color: colors.bluegray500 }}
        >
          삭제함
        </Typography>
      );
    }
    if (isDisplay) {
      return (
        <Typography
          sx={{ ...textStyles.web.subhead3, color: colors.lightblue600 }}
        >
          노출 중
        </Typography>
      );
    }
    return (
      <Typography sx={{ ...textStyles.web.subhead3, color: colors.orange600 }}>
        노출 중단
      </Typography>
    );
  };

  const displayDateLabel = () => {
    const createdAtLabel = formatDateKo({
      date: createdAt,
      prevFormat: "노출일: ",
      format: "yyyy/MM/dd(eee) HH:mm",
    });

    if (!deleteAt) return createdAtLabel;

    const deletedAtLabel = formatDateKo({
      date: deleteAt,
      prevFormat: "삭제일: ",
      format: "yyyy/MM/dd(eee) HH:mm",
    });
    return [createdAtLabel, deletedAtLabel].join(" • ");
  };

  const counts = [
    { label: "노출수", count: displayCount },
    { label: "클릭수", count: displayClickCount },
    { label: "클릭률", count: `${clickRate?.toFixed(2)}%` },
  ];

  const URLCut = (url: string) => {
    if (url.length > 25) {
      return url.slice(0, 25) + "...";
    } else {
      return url;
    }
  };

  return (
    <Card sx={sx}>
      <CardContent sx={{ p: 2.5, pb: 0 }}>
        <Stack gap={1}>
          <Stack direction="row" justifyContent={"space-between"}>
            <StatusLabel />
            <Typography
              sx={{ ...textStyles.web.callout1, color: colors.bluegray300 }}
            >
              {displayDateLabel()}
            </Typography>
          </Stack>

          <Stack gap={2} direction="row" alignItems={"center"}>
            <Box
              width={120}
              height={84.5}
              component={"img"}
              src={imageUrl}
              alt={imageId}
              sx={{
                borderRadius: 1.5,
                objectFit: "cover",
                objectPosition: "center",
                display: imageUrl ? "block" : "none",
              }}
            />

            <Stack flex="auto" gap={1.5}>
              <Typography
                display={"inline-flex"}
                color={colors.bluegray700}
                {...textStyles.web.body2}
              >
                <Box component={"span"} minWidth={64}>
                  URL
                </Box>
                <Box component={"span"}>{URLCut(targetUrl)}</Box>
              </Typography>

              <Typography
                display={"inline-flex"}
                color={colors.bluegray700}
                {...textStyles.web.body2}
              >
                <Box component={"span"} minWidth={64}>
                  노출 대상
                </Box>
                <Box component={"span"}>{exposureTargetTypeCodeName}</Box>
              </Typography>

              <Typography
                display={"inline-flex"}
                color={colors.bluegray700}
                {...textStyles.web.body2}
              >
                <Box component={"span"} minWidth={64}>
                  노출 위치
                </Box>
                <Box component={"span"}>{displayPositionTypeCodeName}</Box>
              </Typography>
            </Stack>
          </Stack>

          <Box
            p={0.75}
            borderRadius={1.5}
            alignItems="center"
            border={`solid 1px ${colors.bluegray50}`}
            display={!counts?.length ? "none" : "flex"}
          >
            {counts?.map(({ count, label }, index) => (
              <Fragment key={label}>
                {index ? <Divider orientation="vertical" flexItem /> : null}
                <GridTitle
                  title={label}
                  titleProps={{
                    mb: 0,
                    flex: 1,
                    textAlign: "center",
                    color: colors.bluegray500,
                  }}
                  description={count?.toLocaleString()}
                  descriptionProps={{ color: colors.bluegray700 }}
                  textStyle={textStyles.web.callout2}
                />
              </Fragment>
            ))}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PopupHistoryCard;
