import { css } from "@emotion/react";
import searchCloseIcon from "assets/svg/search-close.svg";

export const globalStyle = css`
  * {
    box-sizing: border-box;
  }
  /* prettier-ignore */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, sub, sup, tt, var,
  u, i, center, dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video , button, input,
  select, textarea {
    -webkit-text-size-adjust: none;
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
      Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo",
      "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", sans-serif;
  }
  html,
  body {
    background-color: #f6f7f8;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  input,
  button {
    border: 0px;
    opacity: 1;
    transition: opacity 0.3s;
    &:focus,
    &:active {
      outline: none;
    }
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    width: 17px;
    height: 16px;
    background: url(${searchCloseIcon}) center center no-repeat;
    cursor: pointer;
  }
`;
