import { colors } from "styles";

export const stateStyles = {
  AGREE: {
    background: colors.lightblue50,
    color: colors.lightblue700,
    border: "",
  },
  DISAGREE: {
    background: colors.bluegray50,
    color: colors.bluegray500,
    border: "",
  },
  CLAIM: {
    background: colors.red50,
    color: colors.red600,
    border: "",
  },
  MISSED: {
    background: colors.yellow100,
    color: colors.yellow800,
    border: "",
  },
  FOLLOW_UP: {
    background: colors.neongreen500,
    color: colors.neongreen1000,
    border: "",
  },
  ORGANIC: {
    background: colors.white,
    color: colors.bluegray700,
    border: `1px solid ${colors.bluegray50}`,
  },
  ETC: {
    background: colors.white,
    color: colors.bluegray700,
    border: `1px solid ${colors.bluegray50}`,
  },
};
