import { format, isValid } from "date-fns";
import { assign, filter, max, omit, pick, split } from "lodash";
import { AxiosResponse } from "axios";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";

import { useCustomSnackbar, useEmitter } from "hooks";
import { RECRUIT_CHANNEL_TYPE_CODE, SEARCH_TYPE_CODE } from "apis/typeCodes";
import {
  getRecruitPostDetail,
  getRecruitPostResumes,
  setCreateRecruitPost,
  setPatchRecruitPost,
  setRecruitPosts,
} from "apis/recruitPost";
import {
  IRecruitPostForm,
  IRecruitPostDetailReq,
  ISetRecruitPostsReq,
  ISetRecruitPostsResp,
  recruitPostsPageSize,
  ISetPatchRecruitPostReq,
  IGetRecruitResumesResponse,
} from "apis/recruitPost.type";
import { ISetFilesImageResp } from "apis/files.type";

interface Props {
  searchTypeCode: SEARCH_TYPE_CODE;
  recruitChannelTypeCode: RECRUIT_CHANNEL_TYPE_CODE;
}

export const useRecruitPostList = (props: Props) => {
  const emitter = useEmitter();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get("page") ?? 1);
  const searchName = searchParams.get("searchName") ?? "";
  const searchTypeCode = searchParams.get("code") ?? props.searchTypeCode;

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["@posts", "setRecruitPosts", page, searchName, searchTypeCode],
    () =>
      setRecruitPosts({
        page,
        searchName,
        searchTypeCode: searchTypeCode as SEARCH_TYPE_CODE,
        recruitChannelTypeCode: props.recruitChannelTypeCode,
      }),
    { select: ({ data }: AxiosResponse<ISetRecruitPostsResp>) => data }
  );

  useEffect(() => {
    emitter.on("RefetchList", refetch);
    return () => {
      emitter.off("RefetchList");
    };
  }, []);

  // pagination
  const maxCount = max([
    data?.reviewPosts?.count,
    data?.posts?.count,
    data?.finishPosts?.count,
  ]);

  const totalPage = maxCount && Math.floor(maxCount / recruitPostsPageSize);

  const onChangePage = (newPage: number) => {
    setSearchParams({ page: String(newPage) });
    window.scrollTo({ top: 0 });
  };

  // search
  const onSearch = (params: ISetRecruitPostsReq) => {
    const searchQuery = `page=1&searchName=${params.searchName}&code=${params.searchTypeCode}`;
    setSearchParams(searchQuery, { replace: !!searchName });
  };

  return {
    data,
    refetch,
    loading: isLoading || isFetching,
    search: {
      isSearched: !!searchName,
      onSearch,
    },
    pagination: {
      page,
      maxCount,
      totalPage: totalPage || 1,
      onChangePage,
    },
  };
};

export const usePostDefaultValues = (defaultValue: object) => {
  const { postId = "", postToken = "" } =
    useParams<Partial<IRecruitPostDetailReq>>();

  const isUpdateValue = Boolean(postId) && Boolean(postToken);
  const stringToDate = (date?: string) =>
    isNaN(Number(date)) ? new Date() : new Date(Number(date));

  const { isLoading, isFetching, data } = useQuery(
    ["@posts", "getRecruitPostDetail", { postId, postToken }],
    () => getRecruitPostDetail({ postId, postToken }),
    {
      enabled: isUpdateValue,
      select: ({ data }) => {
        const dueDateTime = stringToDate(data?.due);
        const startDateTime = stringToDate(data?.startAt);
        const defaultValues = assign({}, defaultValue, data);

        const [startYear, startMonth, startDay] = split(
          format(startDateTime, "yyyy-MM-dd"),
          "-"
        );
        const [dueYear, dueMonth, dueDay] = split(
          format(dueDateTime, "yyyy-MM-dd"),
          "-"
        );

        return {
          ...defaultValues,
          startYear,
          startMonth,
          startDay,
          dueYear,
          dueMonth,
          dueDay,
        };
      },
    }
  );

  return {
    isLoading: isLoading || isFetching,
    data: isUpdateValue ? data : defaultValue,
  };
};

export const useSubmitPost = (
  onSuccessSubmit?: (
    data: AxiosResponse<any, any>,
    variables: ISetPatchRecruitPostReq
  ) => void
) => {
  const snackbar = useCustomSnackbar();
  const [searchParams] = useSearchParams();
  const isNewCompanyAccount = searchParams.get("type") === "create";

  const { postId = "" } = useParams<Partial<IRecruitPostDetailReq>>();
  const imageIds = (imgs?: ISetFilesImageResp[]) =>
    imgs?.map(({ imageId }) => ({ imageId })) ?? [];

  const { mutate, isLoading } = useMutation(
    ["@posts", "submitPost"],
    postId ? setPatchRecruitPost : setCreateRecruitPost,
    { onSuccess: onSuccessSubmit }
  );

  const submitPost = (
    requestParams: IRecruitPostForm,
    defaultValuesKeys: string[]
  ) => {
    const startAtKeys = ["startYear", "startMonth", "startDay"];
    const startAt = pick(requestParams, [
      "startYear",
      "startMonth",
      "startDay",
    ]);
    const startAtDate = new Date(
      startAt.startYear,
      startAt.startMonth - 1,
      startAt.startDay
    );
    const dueKeys = ["dueYear", "dueMonth", "dueDay"];
    const due = pick(requestParams, ["dueYear", "dueMonth", "dueDay"]);
    const dueDate = new Date(due.dueYear, due.dueMonth - 1, due.dueDay);

    if (!isValid(startAtDate)) {
      return snackbar({
        severity: "error",
        message: "유효한 모집 시작일자를 입력해주세요",
      });
    }

    if (!isValid(dueDate)) {
      return snackbar({
        severity: "error",
        message: "유효한 마감일자를 입력해주세요",
      });
    }

    const paramsKeys = filter(
      defaultValuesKeys,
      (key) => ![...startAtKeys, ...dueKeys].includes(key)
    );
    const defaultPrams = pick(requestParams, paramsKeys);

    const request = {
      ...defaultPrams,
      postId,
      isNewCompanyAccount,
      address: requestParams.address,
      centerDetailImages: imageIds(requestParams?.centerDetailImages),
      centerImages: imageIds(requestParams?.centerImages),
      companyImages: imageIds(requestParams?.companyImages),
      detailAddress: requestParams.detailAddress || "",
      due: format(dueDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      startAt: format(startAtDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      // Number에서 string으로 변환 해서 api연결시 숫자 데이터로 변환시켜 전달
      pay: requestParams.pay,
    };

    mutate(
      (postId ? request : omit(request, "postId")) as ISetPatchRecruitPostReq
    );
  };

  return {
    isLoading,
    submitPost,
  };
};

export const useGetRecruitPostResumes = (postId?: string) => {
  const snackbar = useCustomSnackbar();

  return useQuery(
    ["@posts", "getRecruitPostsResumes", postId],
    () => getRecruitPostResumes(postId ?? ""),
    {
      enabled: !!postId,
      select: ({ data }: AxiosResponse<IGetRecruitResumesResponse>) => data,
      onSuccess: (data) => {
        if (!data.resumsCount)
          snackbar({
            severity: "info",
            message: "지원자가 없습니다.",
          });
      },
    }
  );
};
