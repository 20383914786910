import { getCareerDetail, getCareerList, postCareer, putCareer } from "apis/career";
import { ICareerDetail, ICareerPostForm } from "apis/career.type";
import { AxiosResponse } from "axios";
import { format, isValid, max } from "date-fns";
import { useEmitter } from "hooks";
import { assign, omit, pick, split } from "lodash";
import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { useCustomSnackbar } from "./useCustomSnackbar";

export const useCareerLists = (props: any) => {
  const emitter = useEmitter();
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get("page") ?? 1);
  const search = searchParams.get("search") ?? "";
  // const searchTypeCode = searchParams.get("code") ?? props.searchTypeCode;

  const { data, isLoading, isFetching, refetch } = useQuery(
    ["@CarrersPosts", "getCareerList", page, search],
    () =>
      getCareerList({
        page,
        search,
      }),
    { select: ({ data }: AxiosResponse<any>) => data }
  );

  useEffect(() => {
    emitter.on("RefetchList", refetch);
    return () => {
      emitter.off("RefetchList");
    };
  }, []);

  // pagination
  const maxCount = max([data?.count]);

  const recruitPostsPageSize = 10;

  const totalPage = maxCount && Math.ceil(+maxCount / recruitPostsPageSize);

  const onChangePage = (newPage: number) => {
    setSearchParams({ page: String(newPage) });
    window.scrollTo({ top: 0 });
  };

  // search
  const onSearch = (params: any) => {
    const searchQuery = `page=1&search=${params.searchName}`;
    setSearchParams(searchQuery, { replace: !!search });
  };

  return {
    data,
    loading: isLoading || isFetching,
    search: {
      isSearched: !!search,
      onSearch,
    },
    pagination: {
      page,
      maxCount,
      totalPage: totalPage || 1,
      onChangePage,
    },
  };
};

export const useGetCareerList = () => {
  return useQuery(["@CareersBanner", "getCareerList"], getCareerList, {
    select: ({ data }: AxiosResponse) => data,
  });
};

export const usePostDefaultValues = (defaultValue: object) => {
  const { id = "" } = useParams<Partial<ICareerDetail>>();

  const isUpdateValue = Boolean(id);
  const stringToDate = (date?: string) =>
    isNaN(Number(date)) ? new Date() : new Date(Number(date));

  const { isLoading, isFetching, data } = useQuery(
    ["@posts", "getRecruitPostDetail", { id }],
    () => getCareerDetail(id),
    {
      enabled: isUpdateValue,
      select: ({ data }) => {
        const startDateTime = stringToDate(data?.sourceDate);
        const defaultValues = assign({}, defaultValue, data);
        const [startYear, startMonth, startDay] = split(format(startDateTime, "yyyy-MM-dd"), "-");

        return {
          ...defaultValues,
          startYear,
          startMonth,
          startDay,
        };
      },
    }
  );

  return {
    isLoading: isLoading || isFetching,
    data: isUpdateValue ? data : defaultValue,
  };
};

export const useSubmitPost = (
  onSuccessSubmit?: (data: AxiosResponse<any, any>, variables: any) => void
) => {
  const snackbar = useCustomSnackbar();
  const { id = "" } = useParams<Partial<ICareerDetail>>();

  const { mutate, isLoading } = useMutation(
    ["@career", "submitPost"],
    id ? putCareer : postCareer,
    { onSuccess: onSuccessSubmit }
  );

  const submitPost = (requestParams: ICareerPostForm) => {
    const startAt = pick(requestParams, ["startYear", "startMonth", "startDay"]);
    const startAtDate = new Date(startAt.startYear, startAt.startMonth - 1, startAt.startDay);

    if (!isValid(startAtDate)) {
      return snackbar({
        severity: "error",
        message: "유효한 모집 시작일자를 입력해주세요",
      });
    }

    const request = {
      id,
      description: requestParams.description,
      feedTypeCode: requestParams.feedTypeCode,
      hashTag: requestParams.hashTag,
      sourceDate: format(startAtDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
      sourceName: requestParams.sourceName,
      targetUrl: requestParams.targetUrl,
      title: requestParams.title,
      images: requestParams.images,
    };

    mutate((id ? request : omit(request, "id")) as any);
  };

  return {
    isLoading,
    submitPost,
  };
};
