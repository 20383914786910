import { Add, ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonProps,
  Card,
  Divider,
  FormControlLabel,
  IconButton,
  Input,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { colors } from "styles";
import { textStyles } from "styles";
import { RECRUIT_CHANNEL_TYPE_CODE, SEARCH_TYPE_CODE } from "apis/typeCodes";
import CustomDialog from "./CustomDialog";
import { useState } from "react";
import { patchChangeCenter, putChangeCenter } from "apis/ping";
import { useMutation } from "react-query";
import { useCustomSnackbar, useRecruitPostList } from "hooks";
import { useRecoilState } from "recoil";
import { recruitTotalState } from "store/recruitState";
import { postBluk, setTotalFewReviewRecruitPost } from "apis/recruitPost";

interface Props {
  createPostLink: string;
  isOnlyTitle?: boolean;
  isSearched: boolean;
  serviceTitle: string;
  searchDefaultValues: {
    searchTypeCode: SEARCH_TYPE_CODE;
    recruitChannelTypeCode: RECRUIT_CHANNEL_TYPE_CODE;
  };
  totalAccept?: boolean;
  onSearch: (searchParams: any) => void;
}

const PostListHeader = ({
  createPostLink,
  isOnlyTitle,
  isSearched,
  serviceTitle,
  searchDefaultValues,
  totalAccept,
  onSearch,
}: Props) => {
  const navigate = useNavigate();
  const title = isSearched ? "검색 결과" : serviceTitle;

  const { control, register, reset, handleSubmit } = useForm<any>({
    defaultValues: searchDefaultValues,
  });

  // 공고 이관
  const [isOpen, setIsOpen] = useState(false);
  const [escalationEmail, setEscalationEmail] = useState("");
  const [escalationPostId, setEscalationPostId] = useState("");
  // 승인
  const [isTotalOpen, setIsTotalOpen] = useState(false);
  // 강사/센터 변경
  const [isChangeOpen, setIsChangeOpen] = useState(false);
  const [checkedCenter, setCheckedCenter] = useState(true);
  const [centerChangeInputEmail, setCenterChangeInputEmail] = useState("");
  // 대량 업로드
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [excelFile, setExcelFile] = useState<File | null>(null);

  const onClose = () => setIsOpen(false);
  const onTotalClose = () => setIsTotalOpen(false);
  const onChangeClose = () => setIsChangeOpen(false);
  const onBlukClose = () => setIsBulkUpload(false);

  const snackbar = useCustomSnackbar();

  const { refetch } = useRecruitPostList(searchDefaultValues);

  const BackButton = () => {
    const onClick = () => {
      navigate(-1);
      reset(searchDefaultValues);
    };

    if (!isSearched) return null;
    return (
      <IconButton color="primary" onClick={onClick}>
        <ArrowBack />
      </IconButton>
    );
  };

  const payload = {
    adminKey: process.env.REACT_APP_ADMIN_KEY,
    email: escalationEmail,
    postId: escalationPostId,
  };

  const { isLoading, mutate } = useMutation(
    ["@patchChangeCenter", "patchChangeCenter"],
    patchChangeCenter,
    {
      onSuccess: () => {
        snackbar({
          severity: "success",
          message: "공고 이관이 완료되었습니다!",
        });
      },
      onError: () => {
        snackbar({
          severity: "error",
          message: "이메일과 공고ID를 다시 확인해 주세요!",
        });
      },
    }
  );

  const actionButtons: ButtonProps[] = [
    { id: "cancel", children: "취소", color: "secondary", onClick: onClose },
    {
      id: "finish",
      children: "이관",
      onClick: () => {
        mutate(payload);
        setIsOpen(false);
        setEscalationEmail("");
        setEscalationPostId("");
      },
      sx: {
        "&.MuiButton-containedError": {
          color: colors.white,
          backgroundColor: colors.bluegray1000,
        },
      },
    },
  ];

  const inputText = () => (
    <Box sx={{ display: "flex", flexDirection: "column", mt: "20px" }}>
      이메일
      <Input
        type="text"
        placeholder="이메일을 입력하세요"
        onChange={(e) => setEscalationEmail(e.target.value)}
      />
      <br />
      공고 ID
      <Input
        type="text"
        placeholder="공고 ID 입력하세요"
        onChange={(e) => setEscalationPostId(e.target.value)}
      />
    </Box>
  );

  const [checkState, setCheckState] = useRecoilState(recruitTotalState);

  const { isLoading: totalLoding, mutate: totalMutate } = useMutation(
    ["@setTotalFewReviewRecruitPost", "setTotalFewReviewRecruitPost"],
    setTotalFewReviewRecruitPost,
    {
      onSuccess: async () => {
        await refetch();
        snackbar({
          severity: "success",
          message: "공고 게시중으로 처리되었습니다.",
        });
      },
      onError: () => {
        snackbar({
          severity: "error",
          message: "승인 처리가 되지 않았습니다!",
        });
      },
    }
  );

  const TotalAcceptButtons: ButtonProps[] = [
    {
      id: "cancel",
      children: "취소",
      color: "secondary",
      onClick: onTotalClose,
    },
    {
      id: "finish",
      children: "승인",
      onClick: async () => {
        await totalMutate(checkState);
        setCheckState([]);
        setIsTotalOpen(false);
      },
      sx: {
        "&.MuiButton-containedError": {
          color: colors.white,
          backgroundColor: colors.bluegray1000,
        },
      },
    },
  ];

  // 강사/센터 변경
  const onClickChangeCenter = () => {
    setIsChangeOpen(true);
  };

  const { isLoading: putChangeCenterLoading, mutate: putChangeCenterMutate } =
    useMutation(["@putChangeCenter", "putChangeCenter"], putChangeCenter, {
      onSuccess: () => {
        snackbar({
          severity: "success",
          message: "계정 전환이 완료되었습니다!",
        });
      },
      onError: () => {
        snackbar({
          severity: "error",
          message: "이메일을 다시 확인해 주세요!",
        });
      },
    });

  const centerPayload = {
    adminKey: process.env.REACT_APP_ADMIN_KEY,
    email: centerChangeInputEmail,
    channelType: checkedCenter,
  };

  const ChangeButtons: ButtonProps[] = [
    {
      id: "cancel",
      children: "취소",
      color: "secondary",
      onClick: onChangeClose,
    },
    {
      id: "finish",
      children: "변경",
      onClick: async () => {
        await putChangeCenterMutate(centerPayload);
        setCheckedCenter(true);
        setCenterChangeInputEmail("");
        setIsChangeOpen(false);
      },

      sx: {
        "&.MuiButton-containedError": {
          color: colors.white,
          backgroundColor: colors.bluegray1000,
        },
      },
    },
  ];

  const inputRadio = () => (
    <Box sx={{ display: "flex", flexDirection: "column", mt: "20px" }}>
      이메일
      <Input
        type="text"
        placeholder="이메일을 입력하세요"
        onChange={(e) => setCenterChangeInputEmail(e.target.value)}
      />
      <Box sx={{ display: "flex", mt: "20px", gap: "20px" }}>
        <Card onClick={() => setCheckedCenter(true)}>
          센터<Radio checked={true == checkedCenter}></Radio>
        </Card>
        <Card onClick={() => setCheckedCenter(false)}>
          강사<Radio checked={false == checkedCenter}></Radio>
        </Card>
      </Box>
    </Box>
  );

  const { isLoading: postBulkLoading, mutate: postBulkUpload } = useMutation(
    ["@postBulk", "PostBulk"],
    postBluk,
    {
      onSuccess: async () => {
        await refetch();
        snackbar({
          severity: "success",
          message: "업로드 성공 했습니다.",
        });
      },
      onError: () => {
        snackbar({
          severity: "error",
          message: "업로드 실패 했습니다.",
        });
      },
    }
  );

  const BulkButton: ButtonProps[] = [
    {
      id: "cancel",
      children: "취소",
      color: "secondary",
      onClick: onBlukClose,
    },
    {
      id: "finish",
      children: "승인",
      onClick: async () => {
        if (!excelFile) return;
        postBulkUpload(excelFile);
        setExcelFile(null);
        setIsBulkUpload(false);
      },
    },
  ];

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit(onSearch)}
      sx={{ px: 3, py: 2, display: "flex", alignItems: "center" }}
    >
      {/* 공고 이관 */}
      <CustomDialog
        title={"공고 이관하시겠습니까?"}
        isLoading={isLoading}
        open={isOpen}
        onClose={onClose}
        actionButtons={actionButtons}
      >
        <Divider />
        {inputText()}
      </CustomDialog>
      {/* 일괄 승인 */}
      <CustomDialog
        isLoading={totalLoding}
        open={isTotalOpen}
        onClose={onTotalClose}
        actionButtons={TotalAcceptButtons}
        title={`${checkState.length}개 공고 승인하시겠습니까?`}
      ></CustomDialog>
      {/* 강사 센터 변경 */}
      <CustomDialog
        isLoading={putChangeCenterLoading}
        title={"강사 / 센터 변경하시겠습니까?"}
        open={isChangeOpen}
        onClose={onChangeClose}
        actionButtons={ChangeButtons}
      >
        <Divider />
        {inputRadio()}
      </CustomDialog>
      {/* 대량 업로드 */}
      <CustomDialog
        isLoading={postBulkLoading}
        open={isBulkUpload}
        onClose={onBlukClose}
        actionButtons={BulkButton}
        title={`대량 업로드 하시겠습니까?`}
      >
        <Input
          type="file"
          inputProps={{ accept: ".xlsx" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setExcelFile(e.target.files?.[0] ?? null)
          }
        />
      </CustomDialog>
      <BackButton />
      <Typography {...textStyles.web.title1}>{title}</Typography>
      <Controller
        control={control}
        name="searchTypeCode"
        render={({ field }) => (
          <RadioGroup row {...field} sx={{ ml: "auto" }}>
            <FormControlLabel
              label="센터/기관명"
              control={<Radio />}
              value={SEARCH_TYPE_CODE.INSTITUTION_NAME}
              sx={{ display: isOnlyTitle ? "none" : "inline-flex" }}
            />
            <FormControlLabel
              label="제목"
              control={<Radio />}
              value={SEARCH_TYPE_CODE.TITLE}
            />
            <FormControlLabel
              label="연락처"
              control={<Radio />}
              value={SEARCH_TYPE_CODE.PHONE_NUMBER}
              sx={{ display: isOnlyTitle ? "none" : "inline-flex" }}
            />
          </RadioGroup>
        )}
      />
      <TextField
        type="search"
        placeholder="검색어 입력"
        {...register("searchName", { required: true })}
      />
      <Button variant="contained" type="submit" sx={{ ml: 1.25, mr: "auto" }}>
        검색
      </Button>
      {totalAccept && (
        <>
          <Button
            variant="contained"
            type="button"
            sx={{ mr: 1.5, width: "7rem" }}
            onClick={() => setIsBulkUpload(true)}
          >
            대량 업로드
          </Button>
          <Button
            variant="contained"
            type="button"
            sx={{ mr: 1.5, width: "6rem" }}
            onClick={() => setIsOpen(true)}
          >
            공고 이관
          </Button>
          <Button
            variant="contained"
            type="button"
            sx={{ mr: 1.5, width: "6rem" }}
            onClick={() => setIsTotalOpen(true)}
          >
            {`${checkState.length}개 `}승인
          </Button>
          <Button
            variant="contained"
            type="button"
            sx={{ mr: 1.5, width: "8rem" }}
            onClick={onClickChangeCenter}
          >
            강사/센터 변경
          </Button>
        </>
      )}

      <Button
        startIcon={<Add />}
        variant="contained"
        onClick={() => navigate(createPostLink)}
        sx={{
          visibility: isSearched ? "hidden" : "visible",
          width: "8rem",
          height: "37px",
        }}
      >
        공고작성
      </Button>
    </Paper>
  );
};

export default PostListHeader;
