import { Box, Button, ButtonProps, Card, Grid, Stack, styled, Typography } from "@mui/material";

import { patchAuthorizeCenterCertification, patchCenterReturning } from "apis/centerCertification";

import { CENTER_SEARCH_TYPE_CODE } from "apis/typeCodes";
import { CustomDialog, Layout } from "components";
import CenterCertificationHeader from "components/CenterCertificationHeader";
import { useCustomSnackbar } from "hooks";
import { useCenterAuthorization, useCenterDetail } from "hooks/useCenterAuthorization";
import { useState } from "react";
import { colors, textStyles } from "styles";

interface CertifiProps {
  accountRole?: number;
  email: string;
  accountId: number;
  shopName: string;
  phoneNumber?: string;
  businessNumber?: string;
  imageUrl: string;
}

const EllipsisText = styled("div")({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "90%",
  display: "inline-block",

  "&:hover": {
    minWidth: "380px",
    whiteSpace: "normal",
    overflow: "visible",
    textOverflow: "inherit",
  },
});

const CenterCertificationList = () => {
  const snackbar = useCustomSnackbar();

  const searchDefaultValues = {
    searchTypeCode: CENTER_SEARCH_TYPE_CODE.EMAIL,
  };

  const { data, search, refetch } = useCenterAuthorization();

  const centerCertifiData = data?.responses;

  const [reject, setReject] = useState(false);
  const [openContent, setOpenContent] = useState(false);
  const [authorization, setAuthorization] = useState(false);

  const [accoundId, setAccoundId] = useState(0);
  const onRejectClose = () => setReject(false);
  const onOpenContentClose = () => setOpenContent(false);
  const onAuthorizationClose = () => setAuthorization(false);

  // 삭제 버튼
  const RejectButtons: ButtonProps[] = [
    {
      id: "cancel",
      children: "취소",
      color: "secondary",
      size: "large",
      onClick: onRejectClose,
    },
    {
      id: "finish",
      children: "반려",
      color: "error",
      size: "large",
      onClick: async () => {
        setReject(false);
        await patchCenterReturning(accoundId);
        refetch();
        snackbar({ severity: "success", message: "요청이 반려되었어요." });
      },
      sx: {
        "&.MuiButton-containedError": {
          color: colors.white,
          backgroundColor: colors.red600,
        },
      },
    },
  ];

  // 인증 버튼
  const authorizationButtons: ButtonProps[] = [
    {
      id: "cancel",
      children: "취소",
      color: "secondary",
      size: "large",
      onClick: onAuthorizationClose,
    },
    {
      id: "finish",
      children: "인증",
      color: "primary",
      size: "large",
      onClick: async () => {
        setAuthorization(false);
        await patchAuthorizeCenterCertification(accoundId);
        refetch();
        snackbar({ severity: "success", message: "센터 인증이 완료되었어요." });
      },
      sx: {
        "&.MuiButton-containedError": {
          color: colors.white,
          backgroundColor: colors.red600,
        },
      },
    },
  ];

  // 인증 상세 내용
  const DetailList = () => {
    const { data } = useCenterDetail(accoundId);

    const renderInfoBox = (title: string, data: string) => (
      <Box
        sx={{
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          background: colors.bluegray25,
          borderRadius: "8px",
        }}
      >
        <Box {...textStyles.web.header6} sx={{ color: colors.bluegray400 }}>
          {title}
        </Box>
        <Box {...textStyles.web.body1}>{data}</Box>
      </Box>
    );

    return (
      <Box sx={{ display: "flex", gap: "16px" }}>
        <Box
          sx={{
            minWidth: "550px",
            minHeight: "640px",
            objectFit: "contain",
            height: "66.6vh",
            padding: "0 25px",
            background: "#F2F2F2",
            border: "1px solid rgba(0,0,0,0.10)",
            borderRadius: "4px",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
            }}
            src={data?.detail.imageUrl}
            alt=""
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            gap: "16px",
          }}
        >
          {renderInfoBox("센터명", data?.detail.shopName)}
          {renderInfoBox("사업자 등록번호", data?.detail.businessNumber)}
          {renderInfoBox("센터 이메일", data?.detail.email)}
          {renderInfoBox("센터 번호", data?.detail.phoneNumber)}
          <Button
            fullWidth
            variant="contained"
            color={"primary"}
            onClick={() => {
              setOpenContent(false), setAuthorization(true);
            }}
          >
            인증
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Layout>
      <CenterCertificationHeader
        {...search}
        isOnlyTitle
        serviceTitle="센터 인증"
        searchDefaultValues={searchDefaultValues}
      />
      <CustomDialog
        title={"인증 요청을 반려하시겠어요?"}
        // isLoading={isLoading}
        open={reject}
        onClose={onRejectClose}
        closeBtn={true}
        actionButtons={RejectButtons}
        borderRadius="32px"
      ></CustomDialog>
      {/* 인증 뷰 */}
      <CustomDialog
        open={openContent}
        onClose={onOpenContentClose}
        fullWidth
        maxWidth={"md"}
        closeBtn={true}
        borderRadius="16px"
      >
        <DetailList />
      </CustomDialog>
      {/* 센터 인증 */}
      <CustomDialog
        title={"센터를 인증하시겠어요?"}
        // isLoading={isLoading}
        open={authorization}
        onClose={onAuthorizationClose}
        closeBtn={true}
        actionButtons={authorizationButtons}
        borderRadius="32px"
      ></CustomDialog>
      <Card
        sx={{
          display: "flex",
          borderRadius: "16px",
          width: "100%",
          height: "100%",
          flexWrap: "wrap",
          background: "#F6F7F8",
        }}
      >
        <Grid
          container
          sx={{
            gap: "20px",
            padding: "32px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ gap: "8px", display: "flex", padding: "8px 0" }}>
            <Typography variant="body1" {...textStyles.web.header2}>
              요청된 센터 인증
            </Typography>
            <Typography {...textStyles.web.header2} sx={{ color: colors.bluegray300 }}>
              {centerCertifiData?.length || 0}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "32px",
            }}
          >
            {centerCertifiData?.length > 0 &&
              centerCertifiData?.map((certifi: CertifiProps) => (
                <Box
                  key={certifi.accountId}
                  sx={{
                    width: "100%",
                    minWidth: "380px",
                    padding: "20px",
                    borderRadius: "16px",
                    background: colors.white,
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "4px",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "77%",
                      }}
                    >
                      <EllipsisText>
                        <Stack
                          {...textStyles.web.header3}
                          sx={{
                            marginBottom: "10px",
                            width: "65%",
                          }}
                        >
                          {certifi.shopName}
                        </Stack>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px",
                            color: colors.bluegray400,
                          }}
                        >
                          <Stack
                            {...textStyles.web.body2}
                            sx={{ wordBreak: "break-all", width: "65%" }}
                          >
                            {certifi.email}
                          </Stack>

                          <Stack {...textStyles.web.body2}>{certifi.phoneNumber}</Stack>
                          <Stack {...textStyles.web.body2}>{certifi.businessNumber}</Stack>
                        </Box>
                      </EllipsisText>
                    </Box>

                    <img
                      style={{
                        width: "75px",
                        height: "100px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        overflow: "hidden",
                        backgroundClip: "content-box",
                      }}
                      src={certifi.imageUrl}
                      alt="인증이미지"
                      onClick={() => {
                        setAccoundId(certifi.accountId), setOpenContent(true);
                      }}
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: "8px", margin: "0" }}>
                    <Button
                      fullWidth
                      variant="contained"
                      color={"error"}
                      onClick={() => {
                        setReject(true), setAccoundId(certifi.accountId);
                      }}
                    >
                      반려
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      color={"secondary"}
                      onClick={() => {
                        setAccoundId(certifi.accountId), setAuthorization(true);
                      }}
                    >
                      인증
                    </Button>
                  </Box>
                </Box>
              ))}
          </Box>
        </Grid>
      </Card>
    </Layout>
  );
};

export default CenterCertificationList;
