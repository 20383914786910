import { ButtonProps } from "@mui/material";
import { useEffect, useState } from "react";

import { CustomDialog } from "components";
import { useEmitter } from "hooks";

interface Props {
  onBack: () => void;
}

const CreatePostDialog = ({ onBack }: Props) => {
  const emitter = useEmitter();

  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  useEffect(() => {
    emitter.on("CreatePost", onOpen);
    return () => {
      emitter.off("CreatePost");
    };
  }, []);

  const actionButtons: ButtonProps[] = [
    {
      id: "create",
      children: "추가작성",
      color: "secondary",
      onClick: onClose,
    },
    {
      id: "confirm",
      children: "확인",
      color: "primary",
      onClick: onBack,
    },
  ];

  return (
    <CustomDialog
      open={isOpen}
      title="등록 완료"
      onClose={onClose}
      actionButtons={actionButtons}
    />
  );
};

export default CreatePostDialog;
