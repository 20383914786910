import { filter } from "lodash";
import { ChipProps } from "@mui/material";

import { PostCard } from "components";
import { formatDateKo } from "utils/formatter";
import { IRecruitPostSummary } from "apis/recruitPost.type";

import PostActionButtons from "./PostActionButtons";

interface Props {
  post: IRecruitPostSummary;
}

const TalentPost = ({ post }: Props) => {
  const {
    id,
    address,
    beginnerOk,
    createdAt,
    centerMainImage,
    detailAddress,
    due,
    dutyTypeCodeName,
    employTypeCodeName,
    finished,
    inReview,
    isMan,
    isBound,
    pay,
    payTypeCodeName,
    pulldragAt,
    shopName,
    supportCount,
    title,
    viewCount,
  } = post;

  const chips: ChipProps[] = [
    { color: "success", label: "초보가능", defaultChecked: beginnerOk },
    { color: "info", label: "남자가능", defaultChecked: isMan },
    { color: "secondary", label: "바운드 ONLY", defaultChecked: isBound },
  ];

  // 공고 요약
  const summaries = () => {
    const createdPrevFormat = () => {
      if (finished) return "마감: ";
      if (inReview) return "게시: ";
      return "검토 등록: ";
    };

    const pulldragLabel = pulldragAt && formatDateKo({ date: pulldragAt, prevFormat: "끌올: " });

    const labels = [
      employTypeCodeName,
      dutyTypeCodeName,
      formatDateKo({ date: createdAt, prevFormat: createdPrevFormat() }),
      pulldragLabel,
    ];
    return filter(labels)?.join(" • ");
  };

  const payLabel = () => {
    const payStr = pay.toLocaleString() + "원";
    return [payStr, payTypeCodeName].join("/");
  };

  const locationLabel = () => {
    const sumAddress = filter([address, detailAddress]).join(" ");
    return [shopName, sumAddress].join(" | ");
  };

  // 마감날짜
  const dateLabel = formatDateKo({
    date: due,
    format: "M/d(eee)",
    nextFormat: "까지",
  });

  const counts = inReview
    ? []
    : [
        { label: "지원자", count: supportCount },
        { label: "조회수", count: viewCount },
      ];

  // const isPreview = !inReview || finished;
  const onOpenPreview = () => {
    return window.open(
      `${process.env.REACT_APP_CLIENT_URL}/post/?id=${id}`,
      "_blank",
      "left=100,top=100,width=480,height=968"
    );
  };

  return (
    <PostCard
      chips={chips}
      title={title}
      summaries={summaries()}
      pay={payLabel()}
      location={locationLabel()}
      datetime={dateLabel}
      shopName={shopName}
      centerMainImage={centerMainImage}
      counts={counts}
      inReview={inReview}
      id={id}
      actionButtons={<PostActionButtons {...post} />}
      onOpenPreview={onOpenPreview}
    />
  );
};

export default TalentPost;
