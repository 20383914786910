import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { CENTER_SEARCH_TYPE_CODE } from "apis/typeCodes";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { textStyles } from "styles";

interface Props {
  isOnlyTitle?: boolean;
  isSearched: boolean;
  serviceTitle: string;
  searchDefaultValues: any;
  onSearch: (searchParams: any) => void;
}

const CenterCertificationHeader = ({
  isSearched,
  serviceTitle,
  searchDefaultValues,
  onSearch,
}: Props) => {
  const navigate = useNavigate();
  const title = isSearched ? "검색 결과" : serviceTitle;

  const { control, register, reset, handleSubmit } = useForm<any>({
    defaultValues: searchDefaultValues,
  });
  const BackButton = () => {
    const onClick = () => {
      navigate(-1);
      reset(searchDefaultValues);
    };

    if (!isSearched) return null;
    return (
      <IconButton color="primary" onClick={onClick}>
        <ArrowBack />
      </IconButton>
    );
  };

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit(onSearch)}
      sx={{
        px: 3,
        py: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <BackButton />
        <Typography {...textStyles.web.title1}>{title}</Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Controller
          control={control}
          name="searchTypeCode"
          render={({ field }) => (
            <RadioGroup row {...field} sx={{ ml: "auto" }}>
              <FormControlLabel
                label="이메일"
                control={<Radio />}
                value={CENTER_SEARCH_TYPE_CODE.EMAIL}
              />
              <FormControlLabel
                label="이름"
                control={<Radio />}
                value={CENTER_SEARCH_TYPE_CODE.NAME}
              />
              <FormControlLabel
                label="연락처"
                control={<Radio />}
                value={CENTER_SEARCH_TYPE_CODE.PHONE_NUMBER}
              />
            </RadioGroup>
          )}
        />
        <TextField
          type="search"
          placeholder="검색어 입력"
          {...register("searchName", { required: true })}
        />
        <Button variant="contained" type="submit" sx={{ ml: 1.25, mr: "auto" }}>
          검색
        </Button>
      </Box>
    </Paper>
  );
};

export default CenterCertificationHeader;
