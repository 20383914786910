import { Box, Checkbox, Grid, Stack } from "@mui/material";

import { useRecruitPostList } from "hooks";
import { RECRUIT_CHANNEL_TYPE_CODE, SEARCH_TYPE_CODE } from "apis/typeCodes";
import {
  Layout,
  GridTitle,
  ListPagination,
  PostDialogs,
  PostListHeader,
} from "components";

import { ReviewPostDialog } from "../components";
import TalentPost from "./TalentPost";
import { useRecoilState } from "recoil";
import { recruitTotalState } from "store/recruitState";
import RejectPostDialog from "../components/RejectPostDialog";

const TalentList = () => {
  const searchDefaultValues = {
    searchTypeCode: SEARCH_TYPE_CODE.INSTITUTION_NAME,
    recruitChannelTypeCode: RECRUIT_CHANNEL_TYPE_CODE.ALL,
  };

  const { data, loading, search, pagination } =
    useRecruitPostList(searchDefaultValues);

  const [checkState, setCheckState] = useRecoilState(recruitTotalState);

  const onClickAll = (item: any) => {
    if (item == undefined) return;
    checkState.length != 0 ? setCheckState([]) : setCheckState([...item]);
  };
  const postList = [
    { title: "검토 중", ...data?.reviewPosts },
    { title: "게시 중", ...data?.posts },
    { title: "마감", ...data?.finishPosts },
  ];

  return (
    <Layout
      isLoading={loading}
      footer={<ListPagination loading={loading} {...pagination} />}
    >
      <PostDialogs.Delete />
      <PostDialogs.Finish />
      <PostDialogs.Resume />
      <ReviewPostDialog />
      <RejectPostDialog />

      <PostListHeader
        {...search}
        serviceTitle="전체 공고"
        searchDefaultValues={searchDefaultValues}
        createPostLink="/talent/create?type=update"
        totalAccept={true}
      />

      <Box my={5} mx={6}>
        <Grid container spacing={6}>
          {postList.map(({ title, count, posts }) => (
            <Grid item xs={4} key={title}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "top",
                  justifyContent: "space-between",
                }}
              >
                <GridTitle
                  title={title}
                  description={count?.toLocaleString()}
                />
                {title == "검토 중" && (
                  <Grid>
                    전체 선택
                    <Checkbox
                      checked={
                        checkState.length == posts?.length && posts?.length > 0
                      }
                      onChange={() => onClickAll(posts?.map((item) => item.id))}
                    />
                  </Grid>
                )}
              </Box>

              <Stack mt={2.5} gap={1.5}>
                {posts?.map((post) => (
                  <TalentPost key={post.id} post={post} />
                ))}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Layout>
  );
};

export default TalentList;
