import { Box, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { textStyles } from "styles";

export const drawerWidth = 240;

const LayoutMenu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const menuList = [
    { label: "✏️ 전체 공고", path: "/talent" },
    { label: "🏢 기업 강의", path: "/lecture" },
    { label: "🏋️ 워크샵/특강", path: "/workshop" },
    { label: "🖼 배너/캐러셀", path: "/banner" },
    { label: "⌛️ 팝업", path: "/popup" },
    // 라이프 우측 라벨 추가
    { label: "⛳️️ 라이프", path: "/career" },
    { label: "🔐 센터 인증", path: "/centerCertification" },
    { label: "☑️ 동의 센터", path: "/agreementCenter" },
  ];

  return (
    <Drawer
      open
      variant="permanent"
      sx={{
        width: drawerWidth,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflowY: "auto" }}>
        <List disablePadding>
          {menuList.map(({ label, path }) => (
            <ListItem key={path} disablePadding>
              <ListItemButton selected={pathname.includes(path)} onClick={() => navigate(path)}>
                <ListItemText
                  primary={label}
                  primaryTypographyProps={{ sx: textStyles.web.header4 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default LayoutMenu;
