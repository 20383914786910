import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ButtonProps } from "@mui/material";

import { CustomDialog } from "components";
import { useEmitter, useCustomSnackbar } from "hooks";
import { setFinishRecruitPost } from "apis/recruitPost";
import { IRecruitPostDetailReq } from "apis/recruitPost.type";

const FinishPostDialog = () => {
  const emitter = useEmitter();
  const snackbar = useCustomSnackbar();

  const [params, setParams] = useState<IRecruitPostDetailReq | null>(null);
  const onClose = () => setParams(null);

  useEffect(() => {
    emitter.on("FinishPost", setParams);
    return () => {
      emitter.off("FinishPost");
    };
  }, []);

  const { isLoading, mutate } = useMutation(
    ["@posts", "setFinishRecruitPost"],
    setFinishRecruitPost,
    {
      onSuccess: () => {
        setParams(null);
        emitter.emit("RefetchList");
        snackbar({ severity: "success", message: "공고를 마감했어요." });
      },
    }
  );

  const isOpen = Boolean(params?.postId);
  const actionButtons: ButtonProps[] = [
    { id: "cancel", children: "취소", color: "secondary", onClick: onClose },
    {
      id: "finish",
      children: "확인",
      onClick: () => params && mutate(params),
    },
  ];

  return (
    <CustomDialog
      isLoading={isLoading}
      title="마감 하시겠어요?"
      open={isOpen}
      onClose={onClose}
      actionButtons={actionButtons}
    />
  );
};

export default FinishPostDialog;
