import { ArrowBack } from "@mui/icons-material";
import { IconButton, Paper, Stack, Tab, Tabs, Typography } from "@mui/material";

import { colors, textStyles } from "styles";

interface Props {
  type?: string;
  title?: string;
  onChangeType?: (type: string) => void;
  onBack: () => void;
}
const TalentFormHeader = ({ title, type, onChangeType, onBack }: Props) => {
  const TabButtons = () => {
    if (!type) return null;
    return (
      <Tabs
        value={type}
        onChange={(_, value) => onChangeType && onChangeType(value)}
      >
        <Tab value="create" label="신규 회원 생성" />
        <Tab value="update" label="기존 회원 생성" />
      </Tabs>
    );
  };

  return (
    <Paper sx={{ px: 3, pt: 2, pb: type ? 0 : 2 }}>
      <Stack direction="row" spacing={1.5} alignItems="center" mb={0.5}>
        <IconButton color="primary" onClick={onBack}>
          <ArrowBack />
        </IconButton>

        <Typography sx={{ ...textStyles.web.title1 }}>{title}</Typography>

        <Typography sx={{ ...textStyles.web.subhead3, color: colors.red600 }}>
          * 필수 항목
        </Typography>
      </Stack>

      <TabButtons />
    </Paper>
  );
};

export default TalentFormHeader;
