import { apiGateway } from "./apiGateway";
import { ICareerOnOff } from "./career.type";

// 라이프 리스트 조회
export const getCareerList = (data?: any) => {
  if (data.search == "") {
    return apiGateway.get(`/career?size=12&page=${data.page}`);
  } else {
    return apiGateway.get(`/career?size=12&page=${data.page}&search=${data.search}`);
  }
};

// 라이프 등록
export const postCareer = (req: any) => {
  return apiGateway.post("/career", req);
};

// 라이프 상세 조회
export const getCareerDetail = (id: string) => {
  return apiGateway.get(`/career/${id}`);
};

// 라이프 수정
export const putCareer = (data: any) => {
  if (!data.images[0]) {
    return apiGateway.put(`/career/${data.id}`, data);
  } else {
    return apiGateway.put(`/career/${data.id}`, {
      ...data,
      images: data.images[0],
    });
  }
};

// 라이프 삭제
export const delCareer = (id: string) => {
  return apiGateway.patch(`/career/del/${id}`);
};

// 라이프 공개 여부
export const useCareer = (data: ICareerOnOff) => {
  return apiGateway.patch(`/career/use/${data.id}`, { isUse: data.isUse });
};
