import { Box, Button, Container, Grid } from "@mui/material";
import { keys, pick } from "lodash";
import { forwardRef } from "react";
import { useForm } from "react-hook-form";

import { IGroupCodes } from "apis/commonCode.type";
import { IRecruitPostForm, ISetPatchRecruitPostReq } from "apis/recruitPost.type";
import { CircleLoading, GridTitle } from "components";
import { useCustomSnackbar, useSubmitPost } from "hooks";

import TalentFormAccount from "./TalentFormAccount";
import TalentFormContentPresenter from "./TalentFormContentPresenter";

interface Props {
  type?: string;
  defaultValues: object;
  groupCodes: IGroupCodes[];
  onSuccessSubmit?: (data?: any, variables?: ISetPatchRecruitPostReq) => void;
}

const TalentFormContent = (
  { type, defaultValues, groupCodes, onSuccessSubmit }: Props,
  ref: React.Ref<HTMLButtonElement>
) => {
  const snackbar = useCustomSnackbar();
  const { reset, handleSubmit, ...formProps } = useForm<IRecruitPostForm>({
    defaultValues,
  });

  const { isLoading, submitPost } = useSubmitPost((data, variables) => {
    reset(defaultValues);
    if (onSuccessSubmit) onSuccessSubmit(data, variables);
  });

  const onSubmit = (data: IRecruitPostForm) => {
    const due = pick(data, ["dueYear", "dueMonth", "dueDay"]);
    const dueDate = new Date(due.dueYear, due.dueMonth - 1, due.dueDay);
    const today = new Date();
    // const maxDueDate = addMonths(new Date(), 1);

    if (dueDate < today) {
      if (dueDate.getDate() < today.getDate())
        return snackbar({
          severity: "error",
          title: "유효한 마감일자를 입력해주세요!",
          message: "",
        });
    }

    const defaultValuesKeys = keys(defaultValues);
    submitPost(data, defaultValuesKeys);
  };

  // 기존 회원 생성 계정 정보 부분 임시 제거
  const PostCreateAccount = () => {
    if (!type) return null;
    return (
      <>
        {type == "create" ? (
          <Grid item width={498} sx={{ display: type ? "block" : "none" }}>
            <GridTitle title="계정 정보" />
            <TalentFormAccount type={type} {...formProps} />
          </Grid>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <Box my={5} mx={6} component="form" onSubmit={handleSubmit(onSubmit)}>
      <CircleLoading open={isLoading} />

      <Container disableGutters maxWidth="xl">
        <Grid container flexWrap="nowrap" gap={6}>
          <PostCreateAccount />
          <Grid item xs>
            <GridTitle title="공고 내용" />
            <TalentFormContentPresenter groupCodes={groupCodes} {...formProps} />
          </Grid>
        </Grid>
      </Container>

      <Button ref={ref} type="submit" sx={{ display: "none" }} />
    </Box>
  );
};

export default forwardRef(TalentFormContent);
