import { Typography, TypographyProps } from "@mui/material";
import { colors, textStyles } from "styles";

interface Props {
  title: string;
  titleProps?: TypographyProps;
  description?: string;
  descriptionProps?: TypographyProps;
  textStyle?: TypographyProps;
}

const GridTitle = ({
  title,
  titleProps,
  description,
  descriptionProps,
  textStyle,
}: Props) => (
  <Typography
    mb={"20px"}
    color={colors.bluegray700}
    {...titleProps}
    {...(textStyle ? textStyle : textStyles.web.header2)}
  >
    {title}
    <Typography
      ml="8px"
      component={"span"}
      color={"text.secondary"}
      {...descriptionProps}
      {...(textStyle ? textStyle : textStyles.web.header2)}
      sx={{ display: description ? "inline-block" : "none" }}
    >
      {description}
    </Typography>
  </Typography>
);

export default GridTitle;
