import { FormControlLabel, Stack, Switch } from "@mui/material";

import { GridTitle } from "components";
import { IGetPopupListRespForm } from "apis/banner.type";

interface Props extends IGetPopupListRespForm {
  title: string;
  description?: string;
  defaultChecked?: boolean;
}

const PopupGridTitle = ({
  title,
  description,
  defaultChecked = false,
  register,
}: Props) => (
  <Stack direction={"row"} alignItems="center" mb={2.5}>
    <GridTitle
      title={title}
      titleProps={{ mb: 0, mr: "auto" }}
      description={description}
    />
    <FormControlLabel
      label="앱 노출"
      labelPlacement="start"
      control={<Switch size="small" defaultChecked={defaultChecked} />}
      {...register("isPopup")}
    />
  </Stack>
);

export default PopupGridTitle;
