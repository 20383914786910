import { useForm } from "react-hook-form";
import { Button, Container, Paper, TextField, Typography } from "@mui/material";

import Assets from "assets/Assets";
import { Layout } from "components";
import { textStyles } from "styles";
import { ISetSignInReq } from "apis/account.type";

interface Props {
  onLogin: (req: ISetSignInReq) => void;
}

const LoginPresenter = ({ onLogin }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISetSignInReq>();

  return (
    <Layout
      mainSx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="sm">
        <Paper
          component={"form"}
          onSubmit={handleSubmit(onLogin)}
          sx={{
            px: "75px",
            py: "64px",
            mb: "67px",
            textAlign: "center",
            borderRadius: "36px",
          }}
        >
          <Assets assetName="logo-dark" width={160} height={40} />
          <Typography mt="14px" mb="36px" sx={{ ...textStyles.web.header2 }}>
            관리자 센터
          </Typography>
          <TextField
            fullWidth
            type="email"
            autoComplete="username"
            placeholder="이메일 주소 입력"
            error={!!errors.email}
            helperText={errors.email?.message}
            {...register("email", {
              required: "이메일 주소를 입력해주세요",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                message: "유효한 이베일 주소를 입력해주세요",
              },
            })}
            sx={{ mb: "12px" }}
          />
          <TextField
            fullWidth
            type="password"
            autoComplete="current-password"
            placeholder="비밀번호 입력"
            error={!!errors.password}
            helperText={errors.password?.message}
            {...register("password", { required: "비밀번호를 입력해주세요" })}
            sx={{ mb: "36px" }}
          />
          <Button fullWidth size="large" variant="contained" type="submit">
            로그인
          </Button>
        </Paper>
      </Container>
    </Layout>
  );
};

export default LoginPresenter;
