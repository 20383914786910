export const textStyles = {
  web: {
    title1: {
      fontSize: "26px",
      letterSpacing: "0px",
      lineHeight: "34px",
      fontWeight: "800",
    },
    display1: {
      fontSize: "36px",
      letterSpacing: "-0.3px",
      lineHeight: "50px",
      fontWeight: "800",
    },
    display2: {
      fontSize: "32px",
      letterSpacing: "-0.3px",
      lineHeight: "44px",
      fontWeight: "800",
    },
    display3: {
      fontSize: "28px",
      letterSpacing: "-0.3px",
      lineHeight: "38px",
      fontWeight: "800",
    },
    header1: {
      fontSize: "24px",
      letterSpacing: "0px",
      lineHeight: "34px",
      fontWeight: "700",
    },
    header2: {
      fontSize: "20px",
      letterSpacing: "0px",
      lineHeight: "28px",
      fontWeight: "700",
    },
    header3: {
      fontSize: "18px",
      letterSpacing: "0px",
      lineHeight: "25px",
      fontWeight: "700",
    },
    header4: {
      fontSize: "16px",
      letterSpacing: "0px",
      lineHeight: "22px",
      fontWeight: "700",
    },
    header5: {
      fontSize: "14px",
      letterSpacing: "0px",
      lineHeight: "19px",
      fontWeight: "700",
    },
    header6: {
      fontSize: "12px",
      letterSpacing: "0px",
      lineHeight: "17px",
      fontWeight: "700",
    },
    subhead1: {
      fontSize: "20px",
      letterSpacing: "0px",
      lineHeight: "28px",
      fontWeight: "600",
    },
    subhead2: {
      fontSize: "18px",
      letterSpacing: "0px",
      lineHeight: "25px",
      fontWeight: "600",
    },
    subhead3: {
      fontSize: "16px",
      letterSpacing: "0px",
      lineHeight: "22px",
      fontWeight: "600",
    },
    subhead4: {
      fontSize: "14px",
      letterSpacing: "0px",
      lineHeight: "19px",
      fontWeight: "600",
    },
    subhead5: {
      fontSize: "12px",
      letterSpacing: "0px",
      lineHeight: "17px",
      fontWeight: "600",
    },
    body1: {
      fontSize: "16px",
      letterSpacing: "0px",
      lineHeight: "24px",
      fontWeight: "500",
    },
    body2: {
      fontSize: "14px",
      letterSpacing: "0px",
      lineHeight: "21px",
      fontWeight: "500",
    },
    body3: {
      fontSize: "12px",
      letterSpacing: "0px",
      lineHeight: "18px",
      fontWeight: "500",
    },
    input1: {
      fontSize: "16px",
      letterSpacing: "0px",
      lineHeight: "22px",
      fontWeight: "500",
    },
    input2: {
      fontSize: "14px",
      letterSpacing: "0px",
      lineHeight: "19px",
      fontWeight: "500",
    },
    caption1: {
      fontSize: "14px",
      letterSpacing: "0px",
      lineHeight: "19px",
      fontWeight: "500",
    },
    caption2: {
      fontSize: "12px",
      letterSpacing: "0px",
      lineHeight: "17px",
      fontWeight: "500",
    },
    callout1: {
      fontSize: "14px",
      letterSpacing: "0px",
      lineHeight: "19px",
      fontWeight: "500",
    },
    callout2: {
      fontSize: "12px",
      letterSpacing: "0px",
      lineHeight: "17px",
      fontWeight: "500",
    },
    footnote: {
      fontSize: "12px",
      letterSpacing: "0px",
      lineHeight: "17px",
      fontWeight: "400",
    },
  },
  print: {
    display1: {
      fontSize: "40px",
      letterSpacing: "-0.3px",
      lineHeight: "normal",
      fontWeight: "700",
    },
    header1: {
      fontSize: "26px",
      letterSpacing: "0px",
      lineHeight: "normal",
      fontWeight: "700",
    },
    header2: {
      fontSize: "22px",
      letterSpacing: "0px",
      lineHeight: "normal",
      fontWeight: "700",
    },
    body1: {
      fontSize: "22px",
      letterSpacing: "0px",
      lineHeight: "40px",
      fontWeight: "500",
    },
    body2: {
      fontSize: "20px",
      letterSpacing: "0px",
      lineHeight: "30px",
      fontWeight: "500",
    },
    link: {
      fontSize: "16px",
      letterSpacing: "0px",
      lineHeight: "24px",
      fontWeight: "500",
    },
    footnote: {
      fontSize: "16px",
      letterSpacing: "0px",
      lineHeight: "24px",
      fontWeight: "500",
    },
  },
};
