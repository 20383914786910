import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { IRecruitPostSummary } from "apis/recruitPost.type";
import { useEmitter } from "hooks";

const PostActionButtons = (props: IRecruitPostSummary) => {
  const { id, postToken, inReview, finished } = props;

  const emitter = useEmitter();
  const navigate = useNavigate();

  const params = { postId: id, postToken };
  const onDeletePost = () => emitter.emit("DeletePost", params);
  const onFinishPost = () => emitter.emit("FinishPost", params);
  const onPatchPost = () => navigate(`/workshop/${id}/${postToken}`);

  const buttons = () => {
    if (finished) {
      return [
        { label: "삭제", onClick: onDeletePost, error: true },
        { label: "다시 게시하기", onClick: onPatchPost },
      ];
    }
    if (inReview) {
      return [
        { label: "수정", onClick: onPatchPost },
        { label: "삭제", onClick: onDeletePost, error: true },
      ];
    }
    return [
      { label: "수정", onClick: onPatchPost },
      { label: "마감", onClick: onFinishPost },
    ];
  };

  return (
    <>
      {buttons().map(({ label, error, onClick }) => (
        <Button
          key={label}
          fullWidth
          size="small"
          variant="contained"
          color={error ? "error" : "secondary"}
          onClick={onClick}
        >
          {label}
        </Button>
      ))}
    </>
  );
};

export default PostActionButtons;
