import { ButtonProps } from "@mui/material";

import { CustomDialog } from "components";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onLogout: () => void;
}

const LogoutDialog = ({ isOpen, onClose, onLogout }: Props) => {
  const actionButtons: ButtonProps[] = [
    {
      id: "cancel",
      children: "취소",
      color: "secondary",
      onClick: onClose,
    },
    {
      id: "confirm",
      children: "로그아웃",
      color: "primary",
      onClick: onLogout,
    },
  ];

  return (
    <CustomDialog
      open={isOpen}
      title="로그아웃 하시겠습니까?"
      onClose={onClose}
      actionButtons={actionButtons}
    />
  );
};

export default LogoutDialog;
