import { filter } from "lodash";
import { ChipProps } from "@mui/material";

import { IRecruitPostSummary } from "apis/recruitPost.type";
import { PostCard } from "components";
import { formatDateKo } from "utils/formatter";

import PostActionButtons from "./PostActionButtons";
import { differenceInDays } from "date-fns";

interface Props {
  post: IRecruitPostSummary;
}

const LecturePost = ({ post }: Props) => {
  const {
    id,
    address,
    beginnerOk,
    createdAt,
    centerMainImage,
    detailAddress,
    due,
    dutyTypeCodeName,
    finished,
    isMan,
    pay,
    pulldragAt,
    reservationAlarmCount,
    shopName,
    startAt,
    supportCount,
    title,
    viewCount,
  } = post;

  const chips: ChipProps[] = [
    { color: "success", label: "초보가능", defaultChecked: beginnerOk },
    { color: "info", label: "남자가능", defaultChecked: isMan },
  ];

  const startCount = differenceInDays(new Date(Number(startAt)), new Date());
  const dDay = startCount > 0 ? `D-${startCount}` : "";

  // 공고 요약
  const summaries = () => {
    const createdLabel = () => {
      const prevFormat = finished ? "마감: " : "게시: ";
      return formatDateKo({ date: createdAt, prevFormat });
    };

    const pulldragLabel =
      pulldragAt && formatDateKo({ date: pulldragAt, prevFormat: "끌올: " });

    return filter([dutyTypeCodeName, createdLabel(), pulldragLabel]).join(
      " • "
    );
  };

  const locationLabel = filter([address, detailAddress]).join(" ");

  // 모집시작, 마감
  const dateLabel = [
    formatDateKo({
      date: startAt,
      prevFormat: "모집 시작: ",
      format: "M/d(eee)",
    }),
    formatDateKo({
      date: due,
      prevFormat: "지원 마감: ",
      format: "M/d(eee)",
    }),
  ].join(" | ");

  const counts =
    startCount > 0
      ? [
          { label: "알림 신청자", count: reservationAlarmCount },
          { label: "조회수", count: viewCount },
        ]
      : [
          { label: "알림 신청자", count: reservationAlarmCount },
          { label: "지원자", count: supportCount },
          { label: "조회수", count: viewCount },
        ];

  const onOpenPreview = () => {
    return window.open(
      `${process.env.REACT_APP_CLIENT_URL}/lecture/post/?id=${id}`,
      "_blank",
      "left=100,top=100,width=480,height=968"
    );
  };

  return (
    <PostCard
      chips={chips}
      title={title}
      strongTitle={dDay}
      summaries={summaries()}
      pay={`최대 ${pay?.toLocaleString()}원`}
      location={locationLabel}
      datetime={dateLabel}
      shopName={shopName}
      centerMainImage={centerMainImage}
      counts={counts}
      actionButtons={<PostActionButtons {...post} inReview={startCount <= 0} />}
      onOpenPreview={id ? onOpenPreview : undefined}
    />
  );
};

export default LecturePost;
