import { Pagination, Skeleton } from "@mui/material";

interface Props {
  loading: boolean;
  totalPage: number;
  page?: number;
  onChangePage: (newPage: number) => void;
}

const ListPagination = ({ loading, totalPage, page, onChangePage }: Props) =>
  loading ? (
    <Skeleton width={340} height={32} />
  ) : (
    <Pagination
      color="primary"
      count={totalPage}
      defaultPage={page}
      onChange={(_, page) => onChangePage(page)}
    />
  );

export default ListPagination;
