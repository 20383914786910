import { useRecoilValue } from "recoil";
import { Box, CssBaseline } from "@mui/material";

import { CircleLoading } from "components";
import { accessTokenSelector } from "store/accountState";

import LayoutHeader from "./LayoutHeader";
import LayoutMenu from "./LayoutMenu";
import LayoutMain, { ILayoutMainProps } from "./LayoutMain";

interface Props extends ILayoutMainProps {
  isLoading?: boolean;
}

const Layout = ({ isLoading, children, ...mainProps }: Props) => {
  const isLogin = Boolean(useRecoilValue(accessTokenSelector));

  return (
    <Box sx={{ display: "flex", minWidth: 1440, maxWidth: "100vw" }}>
      <CssBaseline />
      <CircleLoading open={isLoading} />
      <LayoutHeader isLogin={isLogin} />
      {isLogin && <LayoutMenu />}
      <LayoutMain {...mainProps}>{children}</LayoutMain>
    </Box>
  );
};

export default Layout;
