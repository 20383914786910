import { Box } from "@mui/material";

interface Props {
  state: string;
  sx?: object;
  content: string;
  textStyle: object; // textStyle.web.header4 이런 형식으로
  stateStyle: object; // colors.bluegray100 이런 형식으로
}

const Chip = ({ sx, content, textStyle, stateStyle }: Props) => {
  return (
    <Box sx={{ display: "flex", ...sx }}>
      <Box
        sx={{
          padding: "3px 7px",
          borderRadius: "4px",
          ...stateStyle,
          ...textStyle,
        }}
      >
        {content}
      </Box>
    </Box>
  );
};

export default Chip;
