import { find } from "lodash";
import { useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { Controller } from "react-hook-form";
import { Add } from "@mui/icons-material";
import {
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  TextField,
  Stack,
  RadioGroup,
  Divider,
  Button,
  Checkbox,
  Grid,
  Box,
  Typography,
} from "@mui/material";

import { GROUP_CODE } from "apis/typeCodes";
import { ISetFilesImageResp } from "apis/files.type";
import { CustomDialog, ImageUploader } from "components";
import { IRecruitPostUseForm } from "apis/recruitPost.type";

const LectureFormPresenter = ({
  groupCodes,
  watch,
  control,
  register,
  setError,
  setValue,
  getValues,
  formState: { errors },
}: IRecruitPostUseForm) => {
  const [isOpenPostCode, setIsOpenPostCode] = useState(false);

  // codes
  const dutyCodes = find(groupCodes, { code: GROUP_CODE.DUTY });

  // states
  const watchAddress = watch("address");
  const watchCompanyImages = watch("companyImages");
  const watchCenterDetailImages = watch("centerDetailImages");
  const centerImages = Array.from({ length: 6 });
  const watchCenterImages = watch("centerImages");

  if (!dutyCodes) return null;
  return (
    <Paper
      sx={{
        py: 3,
        px: 2.5,
        borderRadius: 6,
      }}
    >
      <CustomDialog
        scroll="body"
        title="주소 입력"
        open={isOpenPostCode}
        onClose={() => setIsOpenPostCode(false)}
        sx={{ ".MuiDialogContent-root": { padding: 0 } }}
      >
        <DaumPostcode
          onClose={() => setIsOpenPostCode(false)}
          onComplete={({ address }) => {
            setValue("address", address);
            setError("address", { message: "" });
          }}
        />
      </CustomDialog>

      <Stack spacing="32px">
        <FormControl fullWidth>
          <FormLabel required>강의 종류</FormLabel>
          <Controller
            name="dutyTypeCode"
            control={control}
            render={({ field }) => (
              <RadioGroup row {...field}>
                {dutyCodes?.groupCodeList?.map(({ code, codeName }) => (
                  <FormControlLabel
                    key={code}
                    value={code}
                    control={<Radio />}
                    label={codeName}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>

        <Divider />

        <FormControl fullWidth>
          <FormLabel required>강의 제목</FormLabel>
          <TextField
            fullWidth
            placeholder="예. 월,수 - 11시,12시,13시"
            error={!!errors.title}
            helperText={errors.title?.message}
            {...register("title", { required: "강의 제목을 입력해주세요" })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>기업 로고</FormLabel>
          <Box width={110} height={110}>
            <ImageUploader
              height={"100%"}
              id="company-image"
              value={watchCompanyImages?.[0]}
              onChange={(value) => setValue("companyImages", [value])}
              onDelete={() => setValue("companyImages", [])}
            />
          </Box>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>기업명</FormLabel>
          <TextField
            fullWidth
            placeholder="기업명 입력"
            error={!!errors.shopName}
            helperText={errors.shopName?.message}
            {...register("shopName", { required: "근무지명을 입력해주세요" })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>근무지 주소</FormLabel>
          <TextField
            fullWidth
            placeholder="주소"
            inputProps={{ readOnly: true }}
            error={!!errors.address?.message}
            helperText={errors.address?.message}
            {...register("address", { required: "주소를 입력해주세요" })}
            onClick={() => setIsOpenPostCode(true)}
          />
          <TextField
            fullWidth
            disabled={!watchAddress}
            placeholder="상세 주소 입력"
            {...register("detailAddress")}
          />
          <Button
            fullWidth
            size="small"
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setIsOpenPostCode(true)}
          >
            주소 {watchAddress ? "변경하기" : "입력하기"}
          </Button>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>급여</FormLabel>
          <Stack direction={"row"} spacing={1} alignItems="center">
            <Typography whiteSpace={"nowrap"}>최대</Typography>
            <TextField
              fullWidth
              type="number"
              placeholder="급여액 입력"
              InputProps={{ endAdornment: "원" }}
              error={!!errors.pay}
              helperText={errors.pay?.message}
              {...register("pay", {
                required: "급여액를 입력해주세요",
                min: { value: 0, message: "유효한 급여액을 입력해주세요" },
              })}
            />
          </Stack>

          <FormControlLabel
            label="협의 가능"
            control={
              <Checkbox defaultChecked={getValues("negotiationAvailable")} />
            }
            {...register("negotiationAvailable")}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>모집 시작 일자 (년/월/일)</FormLabel>
          <Stack direction={"row"} gap={1}>
            <TextField
              fullWidth
              type="number"
              placeholder="년 (4자)"
              error={!!errors.startYear}
              helperText={errors.startYear?.message}
              {...register("startYear", {
                required: "모집 시작년도를 입력해주세요",
                min: {
                  value: 0,
                  message: "유효한 모집 시작년도를 입력해주세요",
                },
              })}
            />
            <TextField
              fullWidth
              type="number"
              placeholder="월"
              error={!!errors.startMonth}
              helperText={errors.startMonth?.message}
              {...register("startMonth", {
                required: "모집 시작월을 입력해주세요",
                min: {
                  value: 1,
                  message: "*유효한 모집 시작월을 입력해주세요",
                },
                max: {
                  value: 12,
                  message: "*유효한 모집 시작월을 입력해주세요",
                },
              })}
            />
            <TextField
              fullWidth
              type="number"
              placeholder="일"
              error={!!errors.startDay}
              helperText={errors.startDay?.message}
              {...register("startDay", {
                required: "모집 시작일을 입력해주세요",
                min: { value: 1, message: "유효한 모집 시작일을 입력해주세요" },
                max: {
                  value: 31,
                  message: "유효한 모집 시작일을 입력해주세요",
                },
              })}
            />
          </Stack>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>마감 일자 (년/월/일)</FormLabel>
          <Stack direction={"row"} gap={1}>
            <TextField
              fullWidth
              type="number"
              placeholder="년 (4자)"
              error={!!errors.dueYear}
              helperText={errors.dueYear?.message}
              {...register("dueYear", {
                required: "마감년도를 입력해주세요",
                min: { value: 0, message: "유효한 마감년도를 입력해주세요" },
              })}
            />
            <TextField
              fullWidth
              type="number"
              placeholder="월"
              error={!!errors.dueMonth}
              helperText={errors.dueMonth?.message}
              {...register("dueMonth", {
                required: "마감월을 입력해주세요",
                min: { value: 1, message: "*유효한 마감월을 입력해주세요" },
                max: { value: 12, message: "*유효한 마감월을 입력해주세요" },
              })}
            />
            <TextField
              fullWidth
              type="number"
              placeholder="일"
              error={!!errors.dueDay}
              helperText={errors.dueDay?.message}
              {...register("dueDay", {
                required: "마감일을 입력해주세요",
                min: { value: 1, message: "유효한 마감일을 입력해주세요" },
                max: { value: 31, message: "유효한 마감일을 입력해주세요" },
              })}
            />
          </Stack>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel required>상세 정보</FormLabel>
          <TextField
            fullWidth
            multiline
            minRows={3}
            error={!!errors.detail}
            helperText={errors.detail?.message}
            placeholder="상세 정보 입력"
            {...register("detail", { required: "상세 정보를 입력해주세요" })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel>이미지로 상세 정보 채우기 (선택)</FormLabel>
          <ImageUploader
            id="detail-image"
            height={160}
            value={watchCenterDetailImages?.[0]}
            onChange={(value) => setValue("centerDetailImages", [value])}
            onDelete={() => setValue("centerDetailImages", [])}
            isDetailImg
          />

          <Stack direction={"row"} spacing={1}>
            <FormControlLabel
              label="초보가능"
              control={<Checkbox defaultChecked={getValues("beginnerOk")} />}
              {...register("beginnerOk")}
            />
            <FormControlLabel
              label="남자강사 가능"
              control={<Checkbox defaultChecked={getValues("isMan")} />}
              {...register("isMan")}
            />
          </Stack>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel>기업 사진</FormLabel>
          <Grid container spacing={1}>
            {centerImages.map((_, i) => {
              const targetImg = watchCenterImages?.[i];
              const id = targetImg?.imageId ?? `center-image-${i}`;

              const onChange = (value: ISetFilesImageResp) =>
                watchCenterImages
                  ? setValue("centerImages", [...watchCenterImages, value])
                  : setValue("centerImages", [value]);

              const onDelete = () => {
                setValue(
                  "centerImages",
                  watchCenterImages?.filter(
                    ({ imageId }) => targetImg?.imageId !== imageId
                  )
                );
              };

              return (
                <Grid item xs={6} key={id}>
                  <ImageUploader
                    id={id}
                    height={110}
                    value={watchCenterImages?.[i]}
                    onChange={onChange}
                    onDelete={onDelete}
                  />
                </Grid>
              );
            })}
          </Grid>
        </FormControl>
      </Stack>
    </Paper>
  );
};

export default LectureFormPresenter;
