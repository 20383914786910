import imageCompression, { Options } from "browser-image-compression";

export async function compressionImage(imgFile: File, options?: Options) {
  try {
    const compressedBlob = await imageCompression(imgFile, {
      maxSizeMB: 1,
      useWebWorker: true,
      ...options,
    });

    const compressedFile = new File([compressedBlob], imgFile.name, {
      type: imgFile.type,
    });
    return compressedFile;
  } catch (error) {
    console.error(error);
  }
}
