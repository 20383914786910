import { DUTY_TYPE_CODE } from "apis/typeCodes";
import { format } from "date-fns";

export const createDefaultValues = {
  description: "",
  feedTypeCode: DUTY_TYPE_CODE.PILATES,
  hashTag: "",
  sourceName: "",
  targetUrl: "",
  title: "",
  images: "",
  startYear: Number(format(new Date(), "yyyy")),
  startMonth: Number(format(new Date(), "MM")),
  startDay: Number(format(new Date(), "dd")),
};
