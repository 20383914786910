import { Box, Button, Container } from "@mui/material";
import { CircleLoading, GridTitle } from "components";
import { useSubmitPost } from "hooks/useCareer";
import { forwardRef } from "react";
import { useForm } from "react-hook-form";
import CareerFormPresenter from "./CareerFormContentPresenter";

interface Props {
  defaultValues: object;
  groupCodes: any;
  onSuccessSubmit?: () => void;
}

const CareerFormContent = (
  { defaultValues, groupCodes, onSuccessSubmit }: Props,
  ref: React.Ref<HTMLButtonElement>
) => {
  const { reset, handleSubmit, ...formProps } = useForm<any>({
    defaultValues,
  });

  const { isLoading, submitPost } = useSubmitPost(() => {
    reset(defaultValues);
    if (onSuccessSubmit) onSuccessSubmit();
  });

  const onSubmit = (data: any) => {
    submitPost(data);
  };

  return (
    <Box my={5} mx={6} component="form" onSubmit={handleSubmit(onSubmit)}>
      <CircleLoading open={isLoading} />

      <Container disableGutters maxWidth="xl">
        <GridTitle title="피드 내용" />
        <CareerFormPresenter groupCodes={groupCodes} {...formProps} />
      </Container>

      <Button ref={ref} type="submit" sx={{ display: "none" }} />
    </Box>
  );
};

export default forwardRef(CareerFormContent);
