import { keys } from "lodash";
import { forwardRef } from "react";
import { useForm } from "react-hook-form";
import { differenceInDays } from "date-fns";
import { Container, Box, Button } from "@mui/material";

import { IGroupCodes } from "apis/commonCode.type";
import { IRecruitPostForm } from "apis/recruitPost.type";
import { CircleLoading, GridTitle } from "components";
import { useCustomSnackbar, useSubmitPost } from "hooks";

import LectureFormContentPresenter from "./LectureFormContentPresenter";

interface Props {
  defaultValues: object;
  groupCodes: IGroupCodes[];
  onSuccessSubmit?: () => void;
}

const LectureFormContent = (
  { defaultValues, groupCodes, onSuccessSubmit }: Props,
  ref: React.Ref<HTMLButtonElement>
) => {
  const snackbar = useCustomSnackbar();
  const { reset, handleSubmit, ...formProps } = useForm<IRecruitPostForm>({
    defaultValues,
  });

  const { isLoading, submitPost } = useSubmitPost(() => {
    reset(defaultValues);
    if (onSuccessSubmit) onSuccessSubmit();
  });

  const onSubmit = (data: IRecruitPostForm) => {
    const {
      companyImages,
      dueYear,
      dueMonth,
      dueDay,
      startYear,
      startMonth,
      startDay,
    } = data;
    if (!companyImages?.length) {
      return snackbar({
        severity: "error",
        message: "기업 로고를 추가해주세요.",
      });
    }

    const diffDay = differenceInDays(
      new Date(dueYear, dueMonth, dueDay),
      new Date(startYear, startMonth, startDay)
    );
    if (diffDay < 0) {
      return snackbar({
        severity: "error",
        title: "모집 마감일자를 확인해주세요",
        message: "모집 마감일자는 모집 시작일자보다 빠를 수 없습니다.",
      });
    }

    const defaultValuesKeys = keys(defaultValues);
    submitPost(data, defaultValuesKeys);
  };

  return (
    <Box my={5} mx={6} component="form" onSubmit={handleSubmit(onSubmit)}>
      <CircleLoading open={isLoading} />

      <Container disableGutters maxWidth="xl">
        <GridTitle title="공고 내용" />
        <LectureFormContentPresenter groupCodes={groupCodes} {...formProps} />
      </Container>

      <Button ref={ref} type="submit" sx={{ display: "none" }} />
    </Box>
  );
};

export default forwardRef(LectureFormContent);
