import { Info } from "@mui/icons-material";
import { Alert, AlertTitle, Typography } from "@mui/material";

import { colors, textStyles } from "styles";

interface Props {
  descriptions?: string[];
}
const CustomAlert = ({ descriptions }: Props) => {
  return (
    <Alert
      icon={<Info fontSize="inherit" sx={{ color: colors.yellow800 }} />}
      sx={{
        color: colors.yellow800,
        backgroundColor: colors.yellow50,
      }}
    >
      <AlertTitle sx={{ mb: 0, mt: 0.05, ...textStyles.web.header5 }}>
        유의사항
      </AlertTitle>
      {descriptions?.map((description, index) => (
        <Typography
          key={index}
          color="inherit"
          sx={{ ...textStyles.web.callout1 }}
        >
          ‧ {description}
        </Typography>
      ))}
    </Alert>
  );
};

export default CustomAlert;
