import { ButtonProps } from "@mui/material";

import { CustomDialog } from "components";

interface Props {
  isOpen?: boolean;
  onClose: () => void;
  onPatch: () => void;
}

const PatchBannerDialog = ({ isOpen = false, onClose, onPatch }: Props) => {
  const onClickPatch = () => {
    onPatch();
    onClose();
  };

  const actionButtons: ButtonProps[] = [
    {
      id: "cancel",
      children: "적용 안함",
      color: "secondary",
      onClick: onClose,
    },
    {
      id: "patch",
      children: "적용 후 나감",
      onClick: onClickPatch,
    },
  ];

  return (
    <CustomDialog
      open={isOpen}
      onClose={onClose}
      title="변경사항을 적용하시겠습니까?"
      actionButtons={actionButtons}
    />
  );
};

export default PatchBannerDialog;
