import { useForm } from "react-hook-form";
import { Box, Grid, Stack } from "@mui/material";

import {
  IBannerResponse,
  IGetPopupHistoryResp,
  IGetPopupListResp,
} from "apis/banner.type";
import { GROUP_CODE } from "apis/typeCodes";
import { CircleLoading, GridTitle } from "components";
import { useGroupCode, usePatchPopup } from "hooks";
import { formatLinkToTargetTypeCode } from "utils/formatter";

import {
  PopupGridTitle,
  PopupHeader,
  PopupHistoryCard,
  PopupList,
} from "../components";
import newData, { serverSentData } from "pages/NewDate";

interface Props {
  defaultValues: IGetPopupListResp;
  popupHistoryList?: IGetPopupHistoryResp;
}

const PopupForm = ({ defaultValues, popupHistoryList }: Props) => {
  const { isLoading, mutateAsync } = usePatchPopup();
  const { handleSubmit, ...formProps } = useForm({ defaultValues });

  const groupCode = useGroupCode([
    GROUP_CODE.DISPLAY_POSITION,
    GROUP_CODE.DISPLAY_TARGET,
  ]);

  const popupList = defaultValues?.popupList ?? [];

  const description = (list: any[]) => `${list.length.toLocaleString()}개 노출`;

  const convertBannerList = (list?: IBannerResponse[]) => {
    if (!list) return [];
    return list?.map(
      ({
        imageId,
        targetUrl,
        isDisplay,
        isDel,
        bannerDetailId,
        displayPositionTypeCode,
        exposureTargetTypeCode,
        displayStartAt,
        displayEndAt,
        priority,
      }) => ({
        images: { imageId },
        targetTypeCode: formatLinkToTargetTypeCode(targetUrl),
        isDisplay,
        targetUrl,
        isDel,
        bannerDetailId,
        displayPositionTypeCode,
        exposureTargetTypeCode,
        displayStartAt:
          typeof displayStartAt == "number"
            ? newData(displayStartAt)
            : serverSentData(displayStartAt),
        displayEndAt:
          typeof displayEndAt == "number"
            ? newData(displayEndAt)
            : serverSentData(displayEndAt),
        priority,
      })
    );
  };

  const onSubmit = (data: IGetPopupListResp) => {
    mutateAsync({
      isDisplayPopup: data.isPopup,
      popupList: convertBannerList(data.popupList),
    });
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
      {/* loading */}
      <CircleLoading open={isLoading || groupCode.isLoading} />

      {/* renders */}
      <PopupHeader lastUpdateDate={defaultValues?.lastUpdateDate} />

      <Box my={5} mx={6}>
        <Grid container gap={6}>
          <Grid item xs>
            <PopupGridTitle
              {...formProps}
              title={"팝업"}
              description={description(popupList)}
              defaultChecked={defaultValues?.isPopup}
            />
            <PopupList {...formProps} groupCodes={groupCode.data} />
          </Grid>
          <Grid item xs>
            <GridTitle title="팝업 현황" />
            <Stack gap={2}>
              {popupHistoryList?.popupList.map((item) => (
                <PopupHistoryCard key={item.bannerDetailId} {...item} />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PopupForm;
