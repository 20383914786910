import { filter } from "lodash";
import { ChipProps } from "@mui/material";

import { IRecruitPostSummary } from "apis/recruitPost.type";
import { PostCard } from "components";
import { formatDateKo } from "utils/formatter";

import PostActionButtons from "./PostActionButtons";

interface Props {
  isShorts?: boolean;
  post: IRecruitPostSummary;
}

const WorkshopPost = ({ isShorts, post }: Props) => {
  const {
    id,
    address,
    beginnerOk,
    createdAt,
    centerMainImage,
    detailAddress,
    finished,
    isMan,
    pay,
    pulldragAt,
    shopName,
    startAt,
    specialLectureTypeCodeName,
    title,
    viewCount,
  } = post;

  const chips: ChipProps[] = [
    { color: "success", label: "초보가능", defaultChecked: beginnerOk },
    { color: "info", label: "남자가능", defaultChecked: isMan },
  ];

  // 공고 요약
  const summaries = () => {
    const createdLabel = () => {
      const prevFormat = finished ? "마감: " : "게시: ";
      return formatDateKo({ date: createdAt, prevFormat });
    };

    const pulldragLabel =
      pulldragAt && formatDateKo({ date: pulldragAt, prevFormat: "끌올: " });

    return filter([
      specialLectureTypeCodeName,
      createdLabel(),
      pulldragLabel,
    ]).join(" • ");
  };

  const locationLabel = filter([address, detailAddress]).join(" ");

  // 모집시작, 마감
  const dateLabel = formatDateKo({
    date: startAt,
    prevFormat: "시작일: ",
    format: "M/d(eee)",
  });

  const counts = isShorts ? [] : [{ label: "조회수", count: viewCount }];
  const sx = isShorts
    ? {
        backgroundColor: "transparent",
        ".MuiCardContent-root": { p: 0 },
      }
    : undefined;

  const onOpenPreview = () => {
    return window.open(
      `${process.env.REACT_APP_CLIENT_URL}/workshop/post/?id=${id}`,
      "_blank",
      "left=100,top=100,width=480,height=968"
    );
  };

  return (
    <PostCard
      chips={chips}
      title={title}
      summaries={summaries()}
      pay={`${pay?.toLocaleString()}원 부터`}
      location={locationLabel}
      datetime={dateLabel}
      shopName={shopName}
      centerMainImage={centerMainImage}
      counts={counts}
      actionButtons={isShorts ? undefined : <PostActionButtons {...post} />}
      onOpenPreview={id ? onOpenPreview : undefined}
      sx={sx}
    />
  );
};

export default WorkshopPost;
