import { useSnackbar } from "notistack";

import CustomSnackbar, { CustomSnackbarProps } from "components/CustomSnackbar";

export const useCustomSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const onSnackbar = (props: Omit<CustomSnackbarProps, "id">) => {
    enqueueSnackbar("", {
      content: (key) => (
        <CustomSnackbar
          id={String(key)}
          severity={props.severity}
          title={props.title}
          message={props.message}
        />
      ),
    });
  };

  return onSnackbar;
};
