import { Box, Button, Card, CardContent, Paper, Stack, Typography } from "@mui/material";
import { differenceInYears } from "date-fns";
import { useEffect, useState } from "react";

import { IRecruitResumesManage } from "apis/recruitPost.type";
import { CustomDialog } from "components";
import { useEmitter, useGetRecruitPostResumes } from "hooks";
import { colors, textStyles } from "styles";
import { formatDateKo } from "utils/formatter";

const ResumesPostDialog = () => {
  const emitter = useEmitter();
  const [params, setParams] = useState<{ postId: string } | null>(null);
  const { data, isFetching } = useGetRecruitPostResumes(params?.postId);
  const isOpen = !!data?.resumsCount && !!params?.postId;
  const onClose = () => setParams(null);

  useEffect(() => {
    emitter.on("ResumesPost", setParams);
    return () => {
      emitter.off("ResumesPost");
    };
  }, []);

  const title = `지원자 목록 ${data?.resumsCount.toLocaleString()}`;

  return (
    <CustomDialog open={isOpen} title={title} onClose={onClose} isLoading={isFetching}>
      <Stack gap={1.5}>
        {data?.resums.map((resume) => (
          <Resume key={resume.resumeId} postId={params?.postId} {...resume} />
        ))}
      </Stack>
    </CustomDialog>
  );
};

export default ResumesPostDialog;

const Resume = (resume: IRecruitResumesManage) => {
  const birthday = formatDateKo({
    date: resume.birthDate,
    format: "yyyy/MM/dd",
  });
  const age = differenceInYears(new Date(), new Date(resume.birthDate));
  const applicationAt = formatDateKo({
    date: resume.applicationAt,
    format: "M/d(eee)",
  });

  const onOpenResume = () => {
    if (resume.resumeType == "PDF") {
      return window.open(
        `${process.env.REACT_APP_CLIENT_URL}/resume/pdf/?postId=${resume.postId}&applicationId=${resume.applicationId}&title=${resume.name}&resumeId=${resume.resumeId}`,
        "_blank",
        "left=100,top=100,width=480,height=968"
      );
    }
    return window.open(
      `${process.env.REACT_APP_CLIENT_URL}/resume/?id=${resume.resumeId}`,
      "_blank",
      "left=100,top=100,width=480,height=968"
    );
  };

  return (
    <Card variant="outlined" sx={{ borderRadius: 4 }}>
      {/* <CardActionArea> */}
      <CardContent sx={{ pb: "16px !important" }}>
        <Stack direction={"row"} alignItems="center" gap={1.5}>
          <Box
            width={56}
            height={56}
            component={"img"}
            src={resume.profileImageUrl}
            alt="profile"
            borderRadius={"50%"}
            onError={(e) => {
              e.currentTarget.src = "https://www.bounders.info/images/img_profile_placeholder.png";
            }}
            sx={{ objectFit: "cover" }}
          />
          <Typography
            sx={{ ...textStyles.web.subhead3, color: colors.bluegray700 }}
            component="div"
            style={{
              userSelect: "text",
            }}
          >
            {resume.name}

            <Typography mt={0.5} sx={{ ...textStyles.web.callout1, color: colors.bluegray400 }}>
              <Typography component={"span"} width={52} mr={1}>
                생년월일
              </Typography>
              <Typography component={"span"}>
                {birthday} ({age}세)
              </Typography>
            </Typography>

            <Typography sx={{ ...textStyles.web.callout1, color: colors.bluegray400 }}>
              <Typography component={"span"} width={52} mr={1}>
                지원일
              </Typography>
              <Typography component={"span"}>{applicationAt}</Typography>
            </Typography>
          </Typography>
        </Stack>

        <Paper
          variant="outlined"
          sx={{
            mt: 1.5,
            py: 1,
            px: 1.75,
            borderRadius: 2,
            display: resume.comment ? "block" : "none",
          }}
          style={{ userSelect: "text" }}
        >
          <Typography mb={0.5} sx={{ ...textStyles.web.callout2, color: colors.bluegray300 }}>
            지원자 전달사항
          </Typography>
          <Typography
            whiteSpace={"pre-line"}
            sx={{ ...textStyles.web.body2, color: colors.bluegray400 }}
          >
            {resume.comment}
          </Typography>
        </Paper>
      </CardContent>

      <Button
        key={resume.resumeId}
        onClick={() => onOpenResume()}
        variant="contained"
        style={{ width: "90%", margin: "0 16px 16px" }}
      >
        이력서 보기
      </Button>
      {/* </CardActionArea> */}
    </Card>
  );
};
