import { IBannerResponse } from "apis/banner.type";

export const createBannerDefaultValue: IBannerResponse = {
  displayClickCount: 0,
  displayCount: 0,
  imageId: "",
  imageUrl: "",
  isDel: false,
  isDisplay: true,
  targetUrl: "",
  displayStartAt: "",
  displayEndAt: "",
  priority: 0,
};
