import { Box, styled } from "@mui/material";
import { colors } from "styles";

export const StyledImageUploaderLabel = styled("label")`
  width: 100%;
  height: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

export const StyledImageUploaderInput = styled("input")`
  display: none;
`;

export const StyledImagePreview = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`;

export const StyledImageUploaderBox = styled(Box)`
  position: relative;
  border: solid 1px ${colors.bluegray100};
  border-radius: 12px;
  overflow: hidden;
`;
