import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ButtonProps } from "@mui/material";

import { colors } from "styles";
import { CustomDialog } from "components";
import { useEmitter, useCustomSnackbar } from "hooks";
import { deleteRecruitPost } from "apis/recruitPost";
import { IRecruitPostDetailReq } from "apis/recruitPost.type";

const DeletePostDialog = () => {
  const emitter = useEmitter();
  const snackbar = useCustomSnackbar();

  const [params, setParams] = useState<IRecruitPostDetailReq | null>(null);
  const onClose = () => setParams(null);

  useEffect(() => {
    emitter.on("DeletePost", setParams);
    return () => {
      emitter.off("DeletePost");
    };
  }, []);

  const { isLoading, mutate } = useMutation(
    ["@posts", "deleteRecruitPost"],
    deleteRecruitPost,
    {
      onSuccess: () => {
        setParams(null);
        emitter.emit("RefetchList");
        snackbar({ severity: "success", message: "삭제 완료되었어요." });
      },
    }
  );

  const isOpen = Boolean(params?.postId);
  const actionButtons: ButtonProps[] = [
    { id: "cancel", children: "취소", color: "secondary", onClick: onClose },
    {
      id: "delete",
      children: "삭제",
      color: "error",
      onClick: () => params && mutate(params),
      sx: {
        "&.MuiButton-containedError": {
          color: colors.white,
          backgroundColor: colors.red800,
        },
      },
    },
  ];

  return (
    <CustomDialog
      isLoading={isLoading}
      title="삭제하시겠어요?"
      open={isOpen}
      onClose={onClose}
      actionButtons={actionButtons}
    />
  );
};

export default DeletePostDialog;
