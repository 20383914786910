import { ButtonProps } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { colors, textStyles } from "styles";
import { CustomDialog, GridTitle } from "components";
import { ISetCreateRecruitPostResp } from "apis/recruitPost.type";
import { useEmitter } from "hooks";

const CreatePostDialog = () => {
  const emitter = useEmitter();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isNewCompanyAccount = searchParams.get("type") === "create";

  const [params, setParams] = useState<ISetCreateRecruitPostResp | null>(null);
  const onClose = () => setParams(null);
  const onBack = () => navigate("/talent");

  useEffect(() => {
    emitter.on("CreatePost", setParams);
    return () => {
      emitter.off("CreatePost");
    };
  }, []);

  const isOpen = Boolean(params?.id);
  const actionButtons: ButtonProps[] = [
    {
      id: "create",
      children: "추가작성",
      color: "secondary",
      onClick: onClose,
    },
    {
      id: "confirm",
      children: "확인",
      color: "primary",
      onClick: onBack,
    },
  ];

  return (
    <CustomDialog open={isOpen} scroll="body" title="등록 완료" actionButtons={actionButtons}>
      <GridTitle
        title="아이디"
        description={params?.email ? params?.email : "contact@boundcrew.com"}
        textStyle={{
          ...textStyles.web.body1,
          color: colors.bluegray500,
          minWidth: 120,
          mb: "12px",
        }}
      />

      {isNewCompanyAccount && (
        <GridTitle
          title="비번 초기화 URL"
          description={`https://bounders.info/change-password/?token=${params?.token}`}
          textStyle={{
            ...textStyles.web.body1,
            color: colors.bluegray500,
            minWidth: 120,
          }}
        />
      )}
    </CustomDialog>
  );
};

export default CreatePostDialog;
